import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Header from '../../Header';
import Footer from '../../Footer';
import PublicRelationsForm from './PublicRelationsForm';
import PublicRelations from './PublicRelations';
import EventsLog from './EventsLog';
import Details from './Details';

import { getIncident, getEventsLog } from '../../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	heading: {
		textAlign: 'center',
		wordBreak: 'break-word',
	},
	container: {
		maxWidth: 700,
		marginTop: theme.spacing(8),
	}
}));

const IncidentDetailsPage = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	let params = useParams();
	let navigate = useNavigate();
	let location = useLocation();

	const {
		incident,
		userRole,
		eventsLog,
		getIncidentConnect,
		getEventsLogConnect,
		lang,
	} = props;

	const isPrPage = location.pathname.indexOf('add-statement');

	useEffect(() => {
		getIncidentConnect({
			id: params.incidentId,
			redirectCallback: navigate,
		});

		getEventsLogConnect({
			id: params.incidentId
		});
	}, [lang]);

	if (!incident.size) {
		return null;
	}

	return (
		<>
			<Header>
				<Button
					variant="outlined"
					color="primary"
					size="large"
					startIcon={<ArrowBackIcon />}
					href="/incidents"
				>
					{t('incident.backToAllIncidents')}
				</Button>
			</Header>
			<Container className={classes.container} maxWidth={false}>
				<Typography className={classes.heading} component="h1" variant="h4" color="primary">
					<b>{incident.get('title') ? incident.get('title') : incident.get('externalTicketId')}</b>
				</Typography>
				{isPrPage > 0 ? (
					<PublicRelationsForm
						itemId={incident.get('id')}
						prStatement={incident.get('prStatement')}
					/>
				) : null}
				<Details
					incident={incident}
					userRole={userRole}
				/>
				{isPrPage < 0 ? (
					<PublicRelations
						prStatement={incident.get('prStatement')}
					/>
				) : null}
				<EventsLog 
					eventsLog={eventsLog} 
					scheduledNotificationTime={incident.get('scheduledNotificationTime')}
					percentageResolved={incident.get('percentageResolved')}
					escalationType={incident.get('escalationType')}
				/>
			</Container>
			<Footer />
		</>
	);
};

export default connect(
	(state) => ({
		lang: state.get('lang'),
		incident: state.get('incident'),
		eventsLog: state.get('eventsLog'),
		userRole: state.get('userRole'),
	}), {
		getIncidentConnect: getIncident,
		getEventsLogConnect: getEventsLog,
	},
)(IncidentDetailsPage);