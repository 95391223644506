import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { List, Map } from 'immutable';
import  { useTranslation } from 'react-i18next';
import {useParams, useNavigate, useLocation} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import WarningIcon from '@material-ui/icons/Warning';
import DateRangeIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
	DateTimePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import daLocale from 'date-fns/locale/da';
import enLocale from 'date-fns/locale/en-US';

import Header from '../../Header';
import Footer from '../../Footer';
import FormSelectField from '../../Common/FormSelectField';
import LocationSelect from './LocationSelect/LocationSelect';
import ConfirmationPopup from './ConfirmationPopup'

import {
	DELETED,
	getIncident,
	getSelectsData,
	submitCreateEditIncidentForm,
	getMainServiceIri,
	getItemByIri,
	getItemByIriArrayCities,
	getSubServicesByMainService,
	setMainServiceValueHandler,
	getFilteredMainServiceAffected,
	getSubServiceAffected,
	getAffectedUsersNumberString,
	EN, DA,
	setErrorDetails,
	getIncidentPreview,
	requestDateDecorator,
} from '../../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(6),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: '70vh',
	},
	input: {
		backgroundColor: '#fff',
		'& > textarea': {
			resize: 'vertical',
		}
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	radio: {
		marginTop: theme.spacing(2),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		paddingLeft: theme.spacing(8),
		paddingRight: theme.spacing(8),
	},
	error: {
		marginTop: theme.spacing(1),
		color: theme.palette.error.main,
		textAlign: 'center',
	},
	errorDuplicate: {
		color: theme.palette.error.main,
	},
	errorLink: {
		color: theme.palette.error.main,
		textDecoration: 'underline',
		paddingLeft: 24,

		'&:hover': {
			textDecoration: 'none',
		},
	},
	warning: {
		fontSize: 20,
		verticalAlign: 'sub'
	},
	deleteItem: {
		position: 'absolute',
		right: -44,
		top: 4,
	},
	relative: {
		position: 'relative'
	},
	errorTimePicker: {
		'& label': {
			color: '#f44336',
		},
		'& input': {
			color: '#f44336',
		}
	},
	updateHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	label: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		right: 40,
	},
	labelShrink: {
		right: 'unset',
	},
	outLinedBox: {
		padding: '10px',
    	border: '1px solid #b6b6b6',
	    borderRadius: '4px'
	},
	disabledDate: {
		opacity: '0.5',
    	pointerEvents: 'none'
	},
	maxChar: {
		fontSize: '0.75em',
		float: 'right',
		color: 'rgba(0, 0, 0, 0.54)'
	}
}));

const deleteServiceHandler = (index, setServicesArray) => {
	setServicesArray((arr) => {
		const tempArr = [...arr];

		tempArr[index] = DELETED;

		return tempArr;
	});
};

const CreateEditIncident = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	let params = useParams();
	let navigate = useNavigate();
	let location = useLocation();

	//const isUpdate = location.pathname.indexOf('update');

	const {
		handleSubmit,
		control,
		formState: { errors },
		setValue,
		getValues,
		trigger,
		register,
	} = useForm({
		shouldUnregister: true,
	});

	const {
		lang,
		incident,
		selectsData,
		getSelectsDataConnect,
		getIncidentConnect,
		getIncidentPreviewConnect,
		submitCreateEditIncidentFormConnect,
		errorMessage,
		setErrorDetailsConnect,
		userRole,
	} = props;

	const scrollToCommentRef = useRef(null);
	const scrollToDuplicateErrorRef = useRef(null);

	const [firsTrigger, setFirsTrigger] = useState(true);
	const [serviceCounter, setServiceCounter] = useState(1);
	const [servicesArray, setServicesArray] = useState(Array.from({length: serviceCounter}, (v, i) => ({
		key: i,
		isCustomServices: false,
	})));

	const [defaultCountries, setDefaultCountries] = useState('');
	const [defaultProvinces, setDefaultProvinces] = useState('');
	const [defaultMunicipalities, setDefaultMunicipalities] = useState('');
	const [defaultCities, setDefaultCities] = useState('');

	const [mainServiceValue, setMainServiceValue] = useState([]);
	const [externalTicketId, setExternalTicketId] = useState('');
	const [locationStr, setLocationStr] = useState('');
	const [type, setType] = useState();
	const [priority, setPriority] = useState(); 
	const [impactedStakeholders, setImpactedStakeholders] = useState();
	const [status, setStatus] = useState('');
	const [escalationType, setEscalationType] = useState();
	const [updateFlag, setUpdateFlag] = useState(false);

	const [isCustomAffectedUsersNumber, setIsCustomAffectedUsersNumber] = useState(false);
	const [isCustomCustomerAffectedByName, setIsCustomCustomerAffectedByName] = useState(false);

	const [commentIsOpen, setCommentIsOpen] = useState(false);
	const [isCustomComment, setIsCustomComment] = useState(false);
	const [confirmPreview, setConfirmPreview] = useState(false);
	const [incidentPreview, setIncidentPreview] = useState('');

	const [investigationCharLength, setInvestigationCharLength] = useState(0);
	const [mitigationCharLength, setMitigationCharLength] = useState(0);
	const [outageCharLength, setOutageCharLength] = useState(0);
	const [actionTakenCharLength, setActionTakenCharLength] = useState(0);

	const mainServices = incident.size ? incident.get('subServices')
		.map((item) => item.getIn(['mainService', 'name']))
		.toSet().toList() : List();

	const subServices = incident.get('subServices');
	const customers = incident.get('customers');

	let customerAffectedByName = selectsData.get('customerAffectedByName');
	let allSubService = selectsData.get('subServiceAffected');
	const incidentOwner = selectsData.get('incidentOwner');
	const incidentVendor = selectsData.get('incidentVendor');
	const mainServiceAffected = selectsData.get('mainServiceAffected');
	const affectedUsersNumbers = selectsData.get('affectedUsersNumbers');
	const continents = selectsData.get('continents');
	const rootCause = selectsData.get('rootCause');
	const incidentSeverity = selectsData.get('incidentSeverity');
	const incidentStatus = selectsData.get('incidentStatus');
	const eventComments = selectsData.get('eventComments');

	useEffect(() => {
		if(status === "5") {
			setValue('timeResolved', incident?.get('timeResolved') ? new Date(Date.parse(incident.get('timeResolved').slice(0, -6))) : null);
			setValue('mitigationInitiatives', incident.get('mitigationInitiatives') || '');
			setValue('reasonForOutage', incident.get('reasonForOutage') || '');
			setValue('percentageResolved', 100);
			setValue('scheduledNotificationTime', null);
		} else if(status === "7") {
			setValue('percentageResolved', 100);
			setValue('scheduledNotificationTime', null);
			setValue('actionsTaken', " ");
			setValue('description', " ");
		} else if(status === "2" || status === "3") {
			setValue('timeStart', incident?.get('timeStart') ? new Date(Date.parse(incident.get('timeStart').slice(0, -6))) : null);
			setValue('startOfTechnicalBridge', incident?.get('startOfTechnicalBridge') ? new Date(Date.parse(incident.get('startOfTechnicalBridge').slice(0, -6))) : null);
			setValue('startOfCommunicationBridge', incident?.get('startOfCommunicationBridge') ? new Date(Date.parse(incident.get('startOfCommunicationBridge').slice(0, -6))) : null);
			setValue('departmentAndTeamName', incident.get('departmentAndTeamName') || '');
			setValue('investigation', incident.get('investigation') || '');
			setValue('impactedStakeholdersInformed', incident.get('impactedStakeholdersInformed') || '');
		}
	},[status]);

	useEffect(() => {
		setValue('location', locationStr);

		if (locationStr) {
			trigger('location');
		}
	}, [locationStr]);

	useEffect(() => {
		setConfirmPreview(false);
	}, [errorMessage]);

	const { incidentId } = params;

	useEffect(() => {
		if (incidentId) {
			getIncidentConnect({
				id: incidentId,
				redirectCallback: navigate,
			});
		}
		return () => {
			setErrorDetailsConnect('')
		};
	}, []);

	/*useEffect(() => {
		if (isUpdate !== -1) {
			setCommentIsOpen(true);
			if (eventComments) {
				scrollToCommentRef.current.scrollIntoView();
			}
		}
	}, [eventComments]);*/

	useEffect(() => {
		getSelectsDataConnect();

		if (!isCustomComment && getValues().commentTitle) {
			eventComments.forEach((item) => {
				if (item.get('@id') === getValues().commentTitle) {
					setValue('commentDescription', item.getIn(['incidentEventCommentGroupDescriptions', 0, 'translations'])?.get(0)?.get('value'));
				}
			});
		}

		if (Object.keys(errors).length > 0) {
			trigger();
		}
	}, [lang]);

	useEffect(() => {
		if (incident.getIn(['affectedUsersNumber', 'isOther']) === 'yes') {
			setIsCustomAffectedUsersNumber(true);
		}

		//console.log("incidentSeverity --> " , incident.getIn(['incidentSeverity', 'id']));

		if(incident.getIn(['incidentSeverity', 'id'])) {
			let id = incident.getIn(['incidentSeverity', 'id']).toString();
			setPriority(id);
		}

		if(incident.get('investigation')) {
			setInvestigationCharLength(incident.get('investigation').length);
		}

		if(incident.get('mitigationInitiatives')) {
			setMitigationCharLength(incident.get('mitigationInitiatives').length);
		}

		if(incident.get('reasonForOutage')) {
			setOutageCharLength(incident.get('reasonForOutage').length);
		}

		if(incident.getIn(['incidentStatus', 'id'])) {
			let val = incident.getIn(['incidentStatus', 'id']).toString();
			setStatus(val);
		}

	}, [incident]);

	if (subServices) {
		subServices.forEach((item) => {
			allSubService = allSubService.push(Map({
				'@id': item.get('@id'),
				'@type': item.get('@type'),
				mainService: item.getIn(['mainService', '@id']),
				name: item.get('name'),
			}))
		})
	}

	if (customers) {
		customers.forEach((item) => {
			if (!customerAffectedByName.find((value) => value.get('@id') === item.get('@id'))) {
				customerAffectedByName = customerAffectedByName.push(item)
			}
		})
	}

	useEffect(() => {
		if (errorMessage?.indexOf('Item has a duplicate') > -1) {
			scrollToDuplicateErrorRef.current.scrollIntoView();
		}
	}, [errorMessage && confirmPreview]);

	if (incidentId && !incident.size) {
		return null;
	}

	if (incidentId && incident.size && firsTrigger) {
		if (incident.get('subServices').size) {
			setServiceCounter(mainServices.size);
			const tempArr = Array.from({length: mainServices.size}, (v, i) => ({
				key: i,
				isCustomServices: false,
			}));
			setServicesArray(tempArr);
			mainServices.forEach((v, i) => setMainServiceValueHandler(getMainServiceIri(v, incident), i, setValue, setMainServiceValue, firsTrigger));
		}

		const countries = incident.get('countries').map((i) => i.get('@id')).join('|');
		setDefaultCountries(countries);

		const provinces = incident.get('provinces').map((i) => i.get('@id')).join('|');
		setDefaultProvinces(provinces);

		const municipalities = incident.get('municipalities').map((i) => i.get('@id')).join('|');
		setDefaultMunicipalities(municipalities);

		const cities = incident.get('cities').map((i) => i.get('@id')).join('|');
		setDefaultCities(cities);

		setFirsTrigger(false);
		setType(incident.get('type'));
	}

	const createEditIncidentDataDecorator = (payload) => {
		const {
			data,
			confirmPreview,
		} = payload;

		let populateData = {};
		let otherSubServices = [];
		let otherCustomers = [];
		let item = incident.toJS();
		if(incident.size != 0) {
			populateData = {
				timeStart : new Date(item.timeStart),
				timeResolved : (item.timeResolved) ? new Date(item.timeResolved) : null,
				startOfTechnicalBridge : new Date(item.startOfTechnicalBridge),
				startOfCommunicationBridge : new Date(item.startOfCommunicationBridge),
				departmentAndTeamName : item.departmentAndTeamName,
				investigation : item.investigation,
				impactedStakeholdersInformed : item.impactedStakeholdersInformed,
				mitigationInitiatives : item.mitigationInitiatives || '',
				reasonForOutage : item.reasonForOutage || ''
			}
		}

		const result = {
			...populateData,
			...data,
			countries: [],
			provinces: [],
			municipalities: [],
			cities: [],
			customers: [],
			subServices: data.subServiceAffected ? [...data.subServiceAffected.flat()] : [],
			otherSubServices: otherSubServices,
			otherCustomers: otherCustomers
		};

		if (result.customServices?.length) {
			data.mainServiceAffected.forEach((e, i) => {
				data.customServices[i]?.split(/\s*,\s*/).filter(item => item).forEach((item) => {
					result.otherSubServices.push(
						{
							name: item,
							isOther: 'yes',
							mainService: getItemByIri(e, 'mainServiceAffected', selectsData).get('@id'),
							translations: [
								{
									locale: lang === EN ? DA : EN,
									value: item,
									field: 'name',
								}
							]
						}
					);
				});
			});
		}

		delete result.location;
		delete result.mainServiceAffected;
		delete result.subServiceAffected;
		delete result.affectedUsersNumbers;
		delete result.customerAffectedByName;

		getItemByIriArrayCities(locationStr.split('|'), continents).forEach((item) => {
			if (item.get('@type') === 'Continent' || item.get('@type') === 'Country') {
				result.countries.push(item.get('@id'));
			} else if (item.get('@type') === 'Province') {
				result.provinces.push(item.get('@id'));
			} else if (item.get('@type') === 'Municipality') {
				result.municipalities.push(item.get('@id'));
			} else {
				result.cities.push(item.get('@id'));
			}
		});

		if (!result.incidentVendor.length) {
			result.incidentVendor = null;
		}

		if (!result.scheduledNotificationTime) {
			delete result.scheduledNotificationTime;
		} else {
			result.scheduledNotificationTime = requestDateDecorator(result.scheduledNotificationTime);
		}

		if (result.timeStart) {
			result.timeStart = requestDateDecorator(result.timeStart);
		} else {
			delete result.timeStart;
		}

		if (result.timeResolved) {
			result.timeResolved = requestDateDecorator(result.timeResolved);
		}

		if (result.timeEnd) {
			result.timeEnd = requestDateDecorator(result.timeEnd);
		}

		if (result.startOfTechnicalBridge) {
			result.startOfTechnicalBridge = requestDateDecorator(result.startOfTechnicalBridge);
		} else {
			delete result.startOfTechnicalBridge;
		}

		if (result.startOfCommunicationBridge) {
			result.startOfCommunicationBridge = requestDateDecorator(result.startOfCommunicationBridge);
		} else {
			delete result.startOfCommunicationBridge;
		}

		if(result.percentageResolved == "") {
			delete result.percentageResolved;
		}

		if(result.escalationType == "") {
			delete result.escalationType;
		}

		const titleLocation = [...result.countries, ...result.provinces, ...result.municipalities, ...result.cities];

		if (!result.titleEn) {
			let citiesList = getItemByIriArrayCities(titleLocation, continents);
			result.titleEn = getItemByIri(data.rootCause, 'rootCauseEn', selectsData).get('name') + ' in ' + citiesList[0].get('name') + `${citiesList.length > 1 ? ', ...' : ''}`;
		}

		if (!result.titleDa) {
			let citiesList = getItemByIriArrayCities(titleLocation, continents);
			result.titleDa = getItemByIri(data.rootCause, 'rootCauseDa', selectsData).get('name') + ' i ' + citiesList[0].get('name') + `${citiesList.length > 1 ? ', ...' : ''}`;
		}

		setExternalTicketId(result.externalTicketId);

		if (isCustomAffectedUsersNumber) {
			result.otherAffectedUsersNumber = {
				from: +result.customAffectedUsersNumber,
				to: +result.customAffectedUsersNumber,
				isOther: 'yes'
			};
		}

		if (isCustomCustomerAffectedByName) {
			result.customCustomerAffectedByName.split(/\s*,\s*/).filter(item => item).forEach((item) => {
				result.otherCustomers.push({
					name: item,
					translations: [
						{
							locale: lang === EN ? DA : EN,
							value: item,
							field: 'name',
						}
					]
				});
			});
		}

		if (result.commentTitle ==	undefined) {

			result.commentTitle = "";
			delete result.actionsTaken;
			delete result.description;

		} else {

			if (isCustomComment) {
				result.incidentEventCommentGroupDescription = {
					description: result.description,
					actionsTaken : result.actionsTaken,
					translations: [
						{
							locale: lang === EN ? DA : EN,
							value: "",
							field: 'description',
						}
					]
				};
			} else {
				eventComments.forEach((item) => {
					if (item.get('@id') === result.commentTitle) {
						let data = item.toJS();
						let description = "";
						if(data.incidentEventCommentGroupDescriptions.length == 0) {
							description = (result.description == undefined)? " " : result.description;
						} else {
							description = data.incidentEventCommentGroupDescriptions[0].description
						}
						result.incidentEventCommentGroupDescription = {
							description: description,
							actionsTaken : result.actionsTaken,
							translations: [
								{
									locale: lang === EN ? DA : EN,
									value: "",
									field: 'description',
								}
							]
						};
					}
				});
			}
			delete result.actionsTaken;
			delete result.description;
		}

		

		console.log("formData ------> " , result);

		if (confirmPreview) {
			submitCreateEditIncidentFormConnect({
				formData: result,
				id: incidentId,
				redirectCallback: navigate,
			});
		} else {
			getIncidentPreviewConnect({
				formData: result,
				setIncidentPreview: setIncidentPreview,
				setConfirmPreview: setConfirmPreview,
			});
		}
	};

	const onSubmit = (data) => {
		createEditIncidentDataDecorator({
			data: data,
			confirmPreview: confirmPreview,
		})
	};

	const handleSetPriority = (e) => {
		let id = e.split("/").reverse()[0];
		setPriority(id);
		// if(id !== '4') {
		// 	setValue('startOfCommunicationBridge', null);
		// }
	}

	return (
		<>
			<Header>
				<Button
					variant="outlined"
					color="primary"
					size="large"
					startIcon={<ArrowBackIcon />}
					href="/incidents"
				>
					{t('incident.backToAllIncidents')}
				</Button>
			</Header>
			<Container maxWidth="sm">
				<div className={classes.paper}>
					<Typography className={classes.heading} component="h1" variant="h4" color="primary">
						<b>{incidentId ? t('incident.editIncident') : t('incident.createIncident')}</b>
					</Typography>
					<br/>
					<form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Box mt={2}>
									<Controller
										name="titleDa"
										control={control}
										defaultValue={incident.get('titleDa') || ''}
										render={(
											{
												field,
												fieldState: {
													error
												},
											}
										) => (
											<TextField
												{...field}
												variant="outlined"
												fullWidth
												error={!!error}
												label={t('incident.title') + ' (Danish)'}
												helperText={error?.message}
												InputProps={{
													className: classes.input
												}}
											/>
										)}
										rules={{
											maxLength: {
												message: t('validationErrors.maxLength255'),
												value: 255,
											},
										}}
									/>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box mt={2}>
									<Controller
										name="titleEn"
										control={control}
										defaultValue={incident.get('titleEn') || ''}
										render={(
											{
												field,
												fieldState: {
													error
												},
											}
										) => (
											<TextField
												{...field}
												variant="outlined"
												fullWidth
												error={!!error}
												label={t('incident.title') + ' (English)'}
												helperText={error?.message}
												InputProps={{
													className: classes.input
												}}
											/>
										)}
										rules={{
											maxLength: {
												message: t('validationErrors.maxLength255'),
												value: 255,
											},
										}}
									/>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box mt={1}>
									<FormSelectField
										name="incidentOwner"
										label={t('incident.incidentOwner') + ' *'}
										control={control}
										variant="outlined"
										fullWidth
										defaultValue={incident.getIn(['incidentOwner', '@id']) || ''}
										required
										selectClassName={classes.input}
										errorMessage={t('validationErrors.required')}
									>
										{incidentOwner.map((item) => {
											const name = item.get ? item.get('name') : item;
											return(<MenuItem key={name} value={item.get('@id')}>{name}</MenuItem>);
										})}
									</FormSelectField>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box mt={1}>
									<FormSelectField
										name="incidentVendor"
										label={t('incident.incidentVendor')}
										control={control}
										variant="outlined"
										fullWidth
										defaultValue={incident.getIn(['incidentVendor', '@id']) || ''}
										selectClassName={classes.input}
										errorMessage={t('validationErrors.required')}
									>
										<MenuItem value={''}>&nbsp;</MenuItem>
										{incidentVendor.map((item) => {
											const name = item.get ? item.get('name') : item;
											return <MenuItem key={name} value={item.get('@id')}>{name}</MenuItem>;
										})}
									</FormSelectField>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box ref={scrollToDuplicateErrorRef} mt={1}>
									<Controller
										name="externalTicketId"
										control={control}
										defaultValue={incident.get('externalTicketId') || ''}
										render={(
											{
												field,
												fieldState: {
													error
												},
											}
										) => (
											<TextField
												{...field}
												variant="outlined"
												fullWidth
												error={!!error}
												label={t('incident.troubleTicketID') + ' *'}
												helperText={error?.message}
												InputProps={{
													className: classes.input
												}}
											/>
										)}
										rules={{
											required: {
												value: true,
												message: t('validationErrors.required'),
											},
											maxLength: {
												message: t('validationErrors.maxLength45'),
												value: 45,
											},
										}}
									/>
								</Box>
							</Grid>
							{errorMessage?.indexOf('Item has a duplicate') > -1 ? (
								<Grid item xs={12}>
									<Box className={classes.errorDuplicate}>
										<div><WarningIcon className={classes.warning} /> {t('incident.duplicatedTicket')}</div>
										<div><b><Link className={classes.errorLink} target="_blank" href={`/view-incident/${errorMessage.replace('Item has a duplicate. See link "/api/incidents/', '').replace('Item has a duplicate. See link "/app/api/incidents/', '').replace('"', '')}`}>{t('incident.viewIncident')} #{externalTicketId}</Link></b></div>
									</Box>
								</Grid>
							) : null}
							{servicesArray.filter((value) => value !== DELETED).map((v, i, arr) => {
								return (
									<Fragment key={v.key}>
										<>
											<Grid item xs={12} sm={6}>
												<Box mt={1}>
													<FormSelectField
														name={`mainServiceAffected[${v.key}]`}
														label={t('incident.mainServiceAffected') + ' *'}
														control={control}
														variant="outlined"
														fullWidth
														defaultValue={getMainServiceIri((mainServices.get(v.key) || ''), incident)}
														required
														errorMessage={t('validationErrors.required')}
														onChangeHandler={(e) => setMainServiceValueHandler(e, v.key, setValue, setMainServiceValue)}
														selectClassName={classes.input}
													>
														{getFilteredMainServiceAffected(v.key, mainServiceAffected, mainServiceValue, servicesArray).map((item) => {
															const name = item.get ? item.get('name') : item;
															return(<MenuItem key={name} value={item.get('@id')}>{name}</MenuItem>);
														})}
													</FormSelectField>
													{i === arr.length - 1 ? (
														<Box mt={1}>
															<Button
																style={{marginRight: 8}}
																variant="outlined"
																color="primary"
																size="large"
																disabled={servicesArray.filter((i) => i !== DELETED).length === mainServiceAffected.size}
																startIcon={<AddIcon />}
																onClick={() => {
																	setServiceCounter(serviceCounter + 1);

																	setServicesArray((arr) => {
																		const tempArr = [...arr];
																		tempArr.push({
																			key: arr.length,
																		});

																		return tempArr;
																	});
																}}
															>
																{t('incident.addService')}
															</Button>
														</Box>
													) : null}
												</Box>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Box className={classes.relative} mt={1}>
													<Controller
														control={control}
														name={`subServiceAffected[${v.key}]`}
														defaultValue={getSubServicesByMainService(mainServices.get(v.key), incident) || []}
														rules={{
															required: {
																value: !v.isCustomServices && getSubServiceAffected(mainServiceValue[v.key], allSubService).size > 0,
																message: t('validationErrors.required'),
															},
														}}
														render={(
															{
																field: {
																	value,
																	onChange,
																},
																fieldState: {
																	error
																},
															}
														) => {
															return (
																<TextField
																	classes={{ root: classes.root }}
																	select
																	fullWidth
																	variant="outlined"
																	label={t('incident.subServiceAffected') + ' *'}
																	disabled={getSubServiceAffected(mainServiceValue[v.key], allSubService).size === 0}
																	error={!!error}
																	helperText={error?.message}
																	SelectProps={{
																		className: classes.input,
																		multiple: true,
																		value: value,
																		onChange: onChange,
																		renderValue: (selected) => {
																			return selected.map((id) => getSubServiceAffected(mainServiceValue[v.key], allSubService).find((item) => (
																				item.get('@id') === id
																			))).map((item) => item?.get('name')).join(", ");
																		}
																	}}
																>
																	{getSubServiceAffected(mainServiceValue[v.key], allSubService)
																		.groupBy((el) => el.get('@id'))
																		.map((el2) => el2.first())
																		.map((item) => {
																		return (
																			<MenuItem key={item.get('name')} value={item.get('@id')}>
																				<Checkbox checked={value.includes(item.get('@id'))} />
																				<ListItemText primary={item.get('name')} />
																			</MenuItem>
																		)
																	})}
																</TextField>
															)
														}}
													/>
													{v.isCustomServices ? (
														<Box mt={1}>
															<Controller
																name={`customServices[${v.key}]`}
																control={control}
																render={(
																	{
																		field,
																		fieldState: {
																			error
																		},
																	}
																) => (
																	<TextField
																		{...field}
																		variant="outlined"
																		fullWidth
																		error={!!error}
																		label={t('incident.customServices') + ' *'}
																		helperText={error?.message}
																		InputProps={{
																			className: classes.input,
																			endAdornment: <IconButton onClick={() => {
																				setServicesArray((arr) => {
																					const tempArr = [...arr];
																					tempArr[v.key].isCustomServices = false;

																					return tempArr;
																				});
																			}}><CloseIcon color={'primary'} /></IconButton>
																		}}
																	/>
																)}
																rules={{
																	required: {
																		value: true,
																		message: t('validationErrors.required'),
																	},
																	minLength: {
																		message: t('validationErrors.minLength2'),
																		value: 2,
																	},
																	maxLength: {
																		message: t('validationErrors.maxLength45'),
																		value: 45,
																	},
																}}
															/>
														</Box>
													) : (
														<Box mt={1}>
															<Button
																variant="outlined"
																color="primary"
																size="large"
																startIcon={<AddIcon />}
																onClick={() => {
																	setServicesArray((arr) => {
																		const tempArr = [...arr];
																		tempArr[v.key].isCustomServices = true;

																		return tempArr;
																	});
																}}
															>
																{t('incident.customServices')}
															</Button>
														</Box>
													)}
													{servicesArray.filter((i) => i !== DELETED).length > 1
														? <IconButton className={classes.deleteItem} onClick={() => deleteServiceHandler(v.key, setServicesArray)}><DeleteOutlineIcon color={'primary'} /></IconButton>
														: null}
												</Box>
											</Grid>
										</>
									</Fragment>
								)
							})}
							<Grid item xs={12} sm={6}>
								<Box mt={1}>
									<FormSelectField
										name="incidentSeverity"
										label={t('incident.severityPriority') + ' *'}
										control={control}
										variant="outlined"
										fullWidth
										defaultValue={incident.getIn(['incidentSeverity', '@id']) || ''}
										required
										errorMessage={t('validationErrors.required')}
										selectClassName={classes.input}
										onChangeHandler={(e) => handleSetPriority(e)}
									>
										{incidentSeverity.map((item) => {
											const value = item.get('@id');

											return(<MenuItem key={value} value={value}>{item.get('name')}</MenuItem>);
										})}
									</FormSelectField>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box mt={1}>
									<FormSelectField
										name="rootCause"
										label={t('incident.rootCause') + ' *'}
										control={control}
										variant="outlined"
										fullWidth
										defaultValue={incident.getIn(['rootCause', '@id']) || ''}
										required
										errorMessage={t('validationErrors.required')}
										selectClassName={classes.input}
									>
										{rootCause.map((item) => {
											const name = item.get ? item.get('name') : item;
											return(<MenuItem key={name} value={item.get('@id')}>{name}</MenuItem>);
										})}
									</FormSelectField>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box mt={1}>
									<FormSelectField
										name="affectedUsersNumber"
										label={t('incident.numberOfCustomersAffected') + ' *'}
										control={control}
										variant="outlined"
										fullWidth
										disabled={isCustomAffectedUsersNumber}
										defaultValue={incident.getIn(['affectedUsersNumber', 'isOther']) === 'yes' ? '' : incident.getIn(['affectedUsersNumber', '@id']) || ''}
										required={!isCustomAffectedUsersNumber}
										errorMessage={t('validationErrors.required')}
										selectClassName={classes.input}
										labelClasses={{
											root: classes.label,
											shrink: classes.labelShrink,
										}}
									>
										{affectedUsersNumbers.map((item) => (
											<MenuItem key={item.get('id')} value={item.get('@id')}>
												{getAffectedUsersNumberString(item)}
											</MenuItem>
										))}
									</FormSelectField>
								</Box>
								{isCustomAffectedUsersNumber ? (
									<Box mt={1}>
										<Controller
											name="customAffectedUsersNumber"
											control={control}
											defaultValue={incident.getIn(['affectedUsersNumber', 'isOther']) === 'yes' ? incident.getIn(['affectedUsersNumber', 'to']) : ''}
											render={(
												{
													field,
													fieldState: {
														error
													},
												}
											) => (
												<TextField
													{...field}
													variant="outlined"
													fullWidth
													error={!!error}
													label={t('incident.customValue') + ' *'}
													helperText={error?.message}
													InputProps={{
														className: classes.input,
														endAdornment: <IconButton onClick={() => setIsCustomAffectedUsersNumber(false)}><CloseIcon color={'primary'} /></IconButton>
													}}
												/>
											)}
											rules={{
												required: {
													value: true,
													message: t('validationErrors.required'),
												},
												pattern: {
													value: /^[0-9]*$/,
													message: t('validationErrors.numbers'),
												},
												maxLength: {
													message: t('validationErrors.maxLength7'),
													value: 7,
												},
											}}
										/>
									</Box>
								) : (
									<Box mt={1}>
										<Button
											variant="outlined"
											color="primary"
											size="large"
											onClick={() => setIsCustomAffectedUsersNumber(true)}
											startIcon={<AddIcon />}
										>
											{t('incident.customValue')}
										</Button>
									</Box>
								)}
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box mt={1}>
									{/*<Controller
										control={control}
										name="customerAffectedByName"
										defaultValue={incident.get('customers')?.map((item) => item.get('@id')).toJS() || []}
										rules={{
											required: {
												value: !isCustomCustomerAffectedByName,
												message: t('validationErrors.required'),
											},
										}}
										render={(
											{
												field: {
													value,
													onChange,
												},
												fieldState: {
													error
												},
											}
										) => {
											return (
												<TextField
													classes={{ root: classes.root }}
													select
													fullWidth
													variant="outlined"
													label={t('incident.customersAffectedByName') + ' *'}
													error={!!error}
													helperText={error?.message}
													InputLabelProps={{
														classes: {
															root: classes.label,
															shrink: classes.labelShrink,
														}
													}}
													SelectProps={{
														className: classes.input,
														multiple: true,
														value: value,
														onChange: onChange,
														renderValue: (selected) => {
															return selected.map((id) => customerAffectedByName.find((item) => (
																item.get('@id') === id
															))).map((item) => item?.get('name')).join(", ");
														}
													}}
												>
													{customerAffectedByName.map((item) => {
														return (
															<MenuItem key={item.get('name')} value={item.get('@id')}>
																<Checkbox checked={value.includes(item.get('@id'))} />
																<ListItemText primary={item.get('name')} />
															</MenuItem>
														)
													})}
												</TextField>
											)
										}}
									/>*/}
								</Box>
								{/*{isCustomCustomerAffectedByName ? (*/}
									<Box mt={1}/>
										{/*<Controller
											name="customCustomerAffectedByName"
											control={control}
											render={(
												{
													field,
													fieldState: {
														error
													},
												}
											) => (
												<TextField
													{...field}
													variant="outlined"
													fullWidth
													error={!!error}
													label={t('incident.customValue') + ' *'}
													helperText={error?.message}
													InputProps={{
														className: classes.input,
														endAdornment: <IconButton onClick={() => setIsCustomCustomerAffectedByName(false)}><CloseIcon color={'primary'} /></IconButton>
													}}
												/>
											)}
											rules={{
												required: {
													value: true,
													message: t('validationErrors.required'),
												},
												maxLength: {
													message: t('validationErrors.maxLength45'),
													value: 45,
												},
											}}
										/>
									</Box>
								) : (
									<Box mt={1}>
										<Button
											variant="outlined"
											color="primary"
											size="large"
											onClick={() => setIsCustomCustomerAffectedByName(true)}
											startIcon={<AddIcon />}
										>
											{t('incident.customValue')}
										</Button>
									</Box>
								)}*/}
							</Grid>
							<Grid item xs={12}>
								<Box mt={1}>
									<div className={errors.location ? 'mdl-error-wrap' : ''}>
										
										<LocationSelect
											setLocationStr={setLocationStr}
											continents={continents}
											defaultCountries={defaultCountries}
											defaultProvinces={defaultProvinces}
											defaultMunicipalities={defaultMunicipalities}
											defaultCities={defaultCities}
										/>
									</div>
									<input
										type="hidden"
										name="location"
										{...register("location",{ required: true })}
									/>
									{errors.location ? (
										<div className="mdl-error-message">{t('validationErrors.required')}</div>
									) : null}
								</Box>
							</Grid>
						</Grid>
						<Box mt={1}>
							<FormControl
								mb={1}
								className={classes.radio}
								fullWidth
								component="fieldset"
							>
								<Box mb={1}>
									<FormLabel component="legend">{t('incident.type')}</FormLabel>
								</Box>
								<Controller
									rules={{ required: true }}
									control={control}
									name="type"
									defaultValue={incident.get('type') || ''}
									render={(
										{
											field,
											fieldState: {
												error
											},
										}
									) => (
										<>
											<RadioGroup
												{...field}
												row
												aria-label="pr"
												style={error ? { color: '#f44336' } : {}}
											>
												<FormControlLabel
													onChange={(e) => setType(e.target.value)}
													value="Incident"
													control={<Radio color="primary" />}
													label={t('incident.incident')}
												/>
												<FormControlLabel
													onChange={(e) => setType(e.target.value)}
													value="Planned Work"
													control={<Radio color="primary" />}
													label={t('incident.plannedWork')}
												/>
												<FormControlLabel
													onChange={(e) => setType(e.target.value)}
													value="External Error"
													control={<Radio color="primary" />}
													label={t('incident.externalError')}
												/>
											</RadioGroup>
											{error ? <div className="mdl-error-message">{t('validationErrors.required')}</div> : null}
										</>
									)}
								/>
							</FormControl>
						</Box>

						{/*
						  P2 requirement starts 
						*/}
						<Box mt={2} className={classes.outLinedBox}>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={6}>
									<Box mt={1} mb={1}>
										
										<FormSelectField
											name="incidentStatus"
											label={t('incident.status')}
											control={control}
											variant="outlined"
											fullWidth
											defaultValue={incident.getIn(['incidentStatus', '@id']) || `${window.location.host.indexOf('opinfo') !== -1 ? '/app' : ''}/api/incident_statuses/2`}
											required
											errorMessage={t('validationErrors.required')}
											selectClassName={classes.input}
											onChangeHandler={(v) => {
												let val = v.split("/").reverse()[0];

											
												
												setStatus(val);
											}}
										>
											{incidentStatus.map((item) => <MenuItem key={item.get('@id')} value={item.get('@id')}>{item.get('name')}</MenuItem>)}
										</FormSelectField>
									</Box>
								</Grid>
							</Grid>
							<hr/>
							{
								(status === "5") ? (
									<>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={6}>
												<Box mt={1}>
													<Controller
														name="timeResolved"
														rules={{
															required: {
																value: true,
																message: t('validationErrors.required'),
															},
														}}
														render={({ field: { onChange, value }, fieldState: {error}}) => (
															<MuiPickersUtilsProvider utils={DateFnsUtils} locale={lang === EN ? enLocale : daLocale}>
																<DateTimePicker
																	okLabel={t('ok')}
																	cancelLabel={t('cancel')}
																	clearLabel={t('clear')}
																	clearable
																	fullWidth
																	className={error ? classes.errorTimePicker : ''}
																	inputVariant="outlined"
																	InputProps={{
																		className: classes.input,
																		endAdornment: <DateRangeIcon style={{color: '#555'}} />
																	}}
																	InputLabelProps={{
																		shrink: true,
																	}}
																	autoOk
																	ampm={false}
																	value={value}
																	onChange={onChange}
																	label={t('incident.resolvedDate') + "*"}
																	format="dd.MM.yyyy HH:mm"
																	maxDate={Date.now()}
																/>
															</MuiPickersUtilsProvider>
														)}
														control={control}
														defaultValue={incident?.get('timeResolved') ? new Date(Date.parse(incident.get('timeResolved').slice(0, -6))) : null}
													/>
												</Box>
											</Grid>
										</Grid>
										<Grid container spacing={1}>
											<Grid item xs={12}>
												<Box mt={2}>
													<Controller
														name="mitigationInitiatives"
														control={control}
														defaultValue={incident.get('mitigationInitiatives') || ''}
														render={(
															{
																field: {
																	value,
																	onChange,
																},
																fieldState: {
																	error
																},
															}
														) => (
															<TextField
																variant="outlined"
																fullWidth
																multiline
																rows={3}
																error={!!error}
																label={`${t('incident.mitigate')}  *`}
																helperText={error?.message}
																InputProps={{
																	className: classes.input
																}}
																InputLabelProps={{
																	shrink: true,
																}}
																value={value}
																onChange={(e) => {
																	setMitigationCharLength(e.target.value.length);
																	onChange(e);
																}}
															/>
														)}
														rules={{
															required: {
																value: true,
																message: t('validationErrors.required'),
															},
															minLength: {
																message: t('validationErrors.minLength10'),
																value: 10,
															},
															maxLength: {
																message: t('validationErrors.maxLength350'),
																value: 350,
															},
														}}
													/>
												</Box>
												<Box>
													<span className={classes.maxChar}>{mitigationCharLength} / 350 {t("characters")}</span>
												</Box>
											</Grid>
										</Grid>
										<Grid container spacing={1}>
											<Grid item xs={12}>
												<Box mt={2}>
													<Controller
														name="reasonForOutage"
														defaultValue={incident.get('reasonForOutage') || ''}
														control={control}
														render={(
															{
																field: {
																	value,
																	onChange,
																},
																fieldState: {
																	error
																},
															}
														) => (
															<TextField
																variant="outlined"
																fullWidth
																multiline
																rows={3}
																error={!!error}
																label={`${t('incident.rfo')}  *`}
																helperText={error?.message}
																InputProps={{
																	className: classes.input
																}}
																InputLabelProps={{
																	shrink: true,
																}}
																value={value}
																onChange={(e) => {
																	setOutageCharLength(e.target.value.length);
																	onChange(e);
																}}
															/>
														)}
														rules={{
															required: {
																value: true,
																message: t('validationErrors.required'),
															},
															minLength: {
																message: t('validationErrors.minLength10'),
																value: 10,
															},
															maxLength: {
																message: t('validationErrors.maxLength250'),
																value: 250,
															},
														}}
													/>
												</Box>
												<Box>
													<span className={classes.maxChar}>{outageCharLength} / 250 {t("characters")}</span>
												</Box>
											</Grid>
										</Grid>
									</>
								) : (status === "7") ? (
									<Grid container spacing={1}>
										<Grid item xs={12} sm={6}>
											<Box mt={1}>
												<Controller
													name="timeEnd"
													rules={{
														required: {
															value: true,
															message: t('validationErrors.required'),
														},
													}}
													render={({ field: { onChange, value }, fieldState: {error}}) => (
														<MuiPickersUtilsProvider utils={DateFnsUtils} locale={lang === EN ? enLocale : daLocale}>
															<DateTimePicker
																okLabel={t('ok')}
																cancelLabel={t('cancel')}
																clearLabel={t('clear')}
																clearable
																fullWidth
																className={error ? classes.errorTimePicker : ''}
																inputVariant="outlined"
																InputProps={{
																	className: classes.input,
																	endAdornment: <DateRangeIcon style={{color: '#555'}} />
																}}
																InputLabelProps={{
																	shrink: true,
																}}
																autoOk
																ampm={false}
																value={value}
																onChange={onChange}
																label={t('incident.closedDate') + "*"}
																format="dd.MM.yyyy HH:mm"
																maxDate={Date.now()}
															/>
														</MuiPickersUtilsProvider>
													)}
													control={control}
													defaultValue={incident?.get('timeEnd') ? new Date(Date.parse(incident.get('timeEnd').slice(0, -6))) : null}
												/>
											</Box>
										</Grid>
									</Grid>
								) : (
									<>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={6}>
												<Box mt={2}>
													<Controller
														name="timeStart"
														rules={{
															required: {
																value: true,
																message: t('validationErrors.required'),
															}
														}}
														render={(
															{ 
																field: { 
																	onChange, 
																	value 
																},
																fieldState: {
																	error
																}, 
															}
														) => (
															<MuiPickersUtilsProvider utils={DateFnsUtils} locale={lang === EN ? enLocale : daLocale}>
																<DateTimePicker
																	okLabel={t('ok')}
																	cancelLabel={t('cancel')}
																	fullWidth
																	inputVariant="outlined"
																	className={error ? classes.errorTimePicker : ''}
																	InputProps={{
																		className: classes.input,
																		endAdornment: <DateRangeIcon style={{color: '#555'}} />
																	}}
																	InputLabelProps={{
																		shrink: true,
																	}}
																	autoOk
																	ampm={false}
																	value={value}
																	onChange={onChange}
																	label={t('incident.timeOccured') + ' *'}
																	format="dd.MM.yyyy HH:mm"
																	maxDate={Date.now()}
																/>
															</MuiPickersUtilsProvider>
														)}
														control={control}
														defaultValue={incident?.get('timeStart') ? new Date(Date.parse(incident.get('timeStart').slice(0, -6))) : null}
													/>
												</Box>
											</Grid>
										</Grid>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={6}>
												<Box mt={2}>
													<Controller
														name="startOfTechnicalBridge"
														render={
															(
																{ 
																	field: { 
																		onChange, 
																		value 
																	}
																}
															) => (
															<MuiPickersUtilsProvider utils={DateFnsUtils} locale={lang === EN ? enLocale : daLocale}>
																<DateTimePicker
																	okLabel={t('ok')}
																	cancelLabel={t('cancel')}
																	fullWidth
																	inputVariant="outlined"
																	InputProps={{
																		className: classes.input,
																		endAdornment: <DateRangeIcon style={{color: '#555'}} />
																	}}
																	InputLabelProps={{
																		shrink: true,
																	}}
																	autoOk
																	ampm={false}
																	value={value}
																	onChange={onChange}
																	label={t('incident.startOfTechnicalBridge')}
																	format="dd.MM.yyyy HH:mm"
																/>
															</MuiPickersUtilsProvider>
														)}
														control={control}
														defaultValue={incident?.get('startOfTechnicalBridge') ? new Date(Date.parse(incident.get('startOfTechnicalBridge').slice(0, -6))) : null}
													/>
												</Box>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Box mt={2} className={priority != '4' ? classes.disabledDate : null}>
													<Controller
														name="startOfCommunicationBridge"
														render={({ field: { onChange, value } }) => (
															<MuiPickersUtilsProvider utils={DateFnsUtils} locale={lang === EN ? enLocale : daLocale}>
																<DateTimePicker
																	okLabel={t('ok')}
																	cancelLabel={t('cancel')}
																	fullWidth
																	inputVariant="outlined"
																	InputProps={{
																		className: classes.input,
																		endAdornment: <DateRangeIcon style={{color: '#555'}} />
																	}}
																	InputLabelProps={{
																		shrink: true,
																	}}
																	autoOk
																	ampm={false}
																	value={value}
																	onChange={onChange}
																	label={t('incident.startOfCommunicationBridge')}
																	format="dd.MM.yyyy HH:mm"
																/>
															</MuiPickersUtilsProvider>
														)}
														control={control}
														defaultValue={incident?.get('startOfCommunicationBridge') ? new Date(Date.parse(incident.get('startOfCommunicationBridge').slice(0, -6))) : null}
													/>
												</Box>
											</Grid>
										</Grid>
										<Grid container spacing={1}>
											<Grid item xs={12}>
												<Box ref={scrollToDuplicateErrorRef} mt={2}>
													<Controller
														name="departmentAndTeamName"
														control={control}
														defaultValue={incident.get('departmentAndTeamName') || ''}
														render={(
															{
																field,
																fieldState: {
																	error
																},
															}
														) => (
															<TextField
																{...field}
																variant="outlined"
																fullWidth
																error={!!error}
																label={t('incident.nameTeamDepartment')}
																helperText={error?.message}
																InputProps={{
																	className: classes.input
																}}
															/>
														)}
													/>
												</Box>
											</Grid>
										</Grid>
										<Grid container spacing={1}>
											<Grid item xs={12}>
												<Box mt={2}>
													<Controller
														name="investigation"
														control={control}
														defaultValue={incident.get('investigation') || ''}
														render={(
															{
																field: {
																	value,
																	onChange,
																},
																fieldState: {
																	error
																},
															}
														) => (
															<TextField
																variant="outlined"
																fullWidth
																multiline
																rows={3}
																error={!!error}
																label={`${t('incident.investigation')}`}
																helperText={error?.message}
																InputProps={{
																	className: classes.input
																}}
																InputLabelProps={{
																	shrink: true,
																}}
																value={value}
																onChange={(e) => {
																	setInvestigationCharLength(e.target.value.length);
																	onChange(e);
																}}
															/>
														)}
														rules={{
															minLength: {
																message: t('validationErrors.minLength10'),
																value: 10,
															},
															maxLength: {
																message: t('validationErrors.maxLength350'),
																value: 350,
															},
														}}
													/>
												</Box>
												<Box>
													<span className={classes.maxChar}>{investigationCharLength} / 350 {t("characters")}</span>
												</Box>
											</Grid>
										</Grid>
										<Grid container spacing={1}>
											<Grid item xs={12}>
												<Box mt={1}>
													<FormControl
														mb={1}
														className={classes.radio}
														fullWidth
														component="fieldset"
													>
														<Box mb={1}>
															<FormLabel component="legend">{t('incident.impactedStakeholders') + " *"}</FormLabel>
														</Box>
														<Controller
															rules={{ required: true }}
															control={control}
															name="impactedStakeholdersInformed"
															defaultValue={incident.get('impactedStakeholdersInformed') || ''}
															render={(
																{
																	field,
																	fieldState: {
																		error
																	},
																}
															) => (
																<>
																	<RadioGroup
																		{...field}
																		row
																		aria-label="pr"
																		style={error ? { color: '#f44336' } : {}}
																	>
																		<FormControlLabel
																			onChange={(e) => setImpactedStakeholders(e.target.value)}
																			value="yes"
																			control={<Radio color="primary" />}
																			label={t('incident.Yes')}
																		/>
																		<FormControlLabel
																			onChange={(e) => setImpactedStakeholders(e.target.value)}
																			value="no"
																			control={<Radio color="primary" />}
																			label={t('incident.No')}
																		/>
																		<FormControlLabel
																			onChange={(e) => setImpactedStakeholders(e.target.value)}
																			value="partly"
																			control={<Radio color="primary" />}
																			label={t('incident.Partly')}
																		/>
																	</RadioGroup>
																	{error ? <div className="mdl-error-message">{t('validationErrors.required')}</div> : null}
																</>
															)}
														/>
													</FormControl>
												</Box>
											</Grid>
										</Grid>
									</>
								)
							}
							
						</Box>		
						{errorMessage?.indexOf('Item has a duplicate') !== -1 ? (
								<Grid item xs={12}>
									<Box className={classes.error}>
										<b>{errorMessage}</b>
									</Box>
								</Grid>
						) : null}
						{status !== '7' ? <>
						<Box mt={2} >
							<Box ref={scrollToCommentRef} >
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Box className={classes.updateHeader}>
											<Typography variant="h6"><b>{t('update')}</b></Typography>
										</Box>
									</Grid>
									<Grid item xs={12}>
										<Box mt={3}>
											<FormSelectField
												name="commentTitle"
												label={t('incident.updateTitle')}
												control={control}
												variant="outlined"
												fullWidth
												required
												selectClassName={classes.input}
												errorMessage={t('validationErrors.required')}
												status={status}
												onChangeHandler={(v) => {
													if (v === 'other') {
														setIsCustomComment(true);
														setValue('description', " ");
													} else {
														setIsCustomComment(false);
														eventComments?.forEach((item) => {
															if (item.get('@id') === v) {
																setValue('description', item.getIn(['incidentEventCommentGroupDescriptions', 0, 'description']));
															}
														});
														//trigger();
													}
													setUpdateFlag(true);
												}}
											>
												{eventComments?.map((item) => {
													const name = item.get ? item.get('title') : item;
													return(<MenuItem key={name} value={item.get('@id')}>{name}</MenuItem>);
												})}
												{userRole === 'ROLE_SUPER_ADMIN' ? (
													<MenuItem value={'other'}>{t('other')}</MenuItem>
												) : null}
											</FormSelectField>
										</Box>
									</Grid>
								</Grid>
								<Grid container spacing={1} className={(!updateFlag) ? classes.disabledDate : null}>
									<Grid item xs={4} style={{ display: 'flex' }}>
										<Box mt={2} style={{ width: '100%' }}>
											<Controller
												name="percentageResolved"
												control={control}
												defaultValue={''}
												render={(
													{
														field: {
															value,
															onChange,
														},
														fieldState: {
															error
														},
													}
												) => (
													<TextField
														variant="outlined"
														type="number"
														fullWidth
														error={!!error}
														label={t('incident.percentResolved') + " *"}
														helperText={error?.message}
														InputProps={{
															className: classes.input,
															inputProps: { min: 0, max: 100 }
														}}
														value={(status === '5' || status === '7') ? '100' : value || '' }
														onChange={onChange}
													/>
												)}
												rules={{
													required: {
														value: (!updateFlag) ? false : true,
														message: t('validationErrors.required'),
													},
													maxLength: {
														message: t('validationErrors.maxLength100'),
														value: 100,
													},
												}}
											/>
										</Box>
										<span  style={{ 'marginLeft': '10px', 'marginTop': '34px' }}> %</span>
									</Grid>
									<Grid item xs={8}>
										<FormControl
											mb={1}
											className={classes.radio}
											fullWidth
											component="fieldset"
											style={{ 'marginLeft': '55px'}}
										>
											<Box mb={1}>
												<FormLabel component="legend">{t('incident.escalationType') + "*"}</FormLabel>
											</Box>
											<Controller
												rules={{ required: (!updateFlag) ? false : true }}
												control={control}
												name="escalationType"
												defaultValue={''}
												render={(
													{
														field: {
															value,
															onChange,
														},
														fieldState: {
															error
														},
													}
												) => (
													<>
														<RadioGroup
															value={value}
															onChange={onChange}
															row
															aria-label="pr"
															style={error ? { color: '#f44336' } : {}}
														>
															<FormControlLabel
																onChange={(e) => setEscalationType(e.target.value)}
																value="Technical"
																control={<Radio color="primary" />}
																label={t('incident.technical')}
															/>
															<FormControlLabel
																onChange={(e) => setEscalationType(e.target.value)}
																value="Process"
																control={<Radio color="primary" />}
																label={t('incident.process')}
															/>
															<FormControlLabel
																onChange={(e) => setEscalationType(e.target.value)}
																value="None"
																control={<Radio color="primary" />}
																label={t('incident.none')}
															/>
														</RadioGroup>
														{error ? <div className="mdl-error-message">{t('validationErrors.required')}</div> : null}
													</>
												)}
											/>
										</FormControl>
									</Grid>
								</Grid>
								
								<Grid container spacing={1} className={(!updateFlag) ? classes.disabledDate : null}>
									<Grid item xs={12}>
										<Box mt={2}>
											<Controller
												name="actionsTaken"
												control={control}
												defaultValue={incident.get('actionsTaken') || ''}
												render={(
													{
														field: {
															value,
															onChange,
														},
														fieldState: {
															error
														},
													}
												) => (
													<TextField
														variant="outlined"
														fullWidth
														multiline
														rows={3}
														error={!!error}
														label={`${t('incident.actionTaken')}  *`}
														helperText={error?.message}
														InputProps={{
															className: classes.input
														}}
														InputLabelProps={{
															shrink: true,
														}}
														value={value}
														onChange={(e) => {
															setActionTakenCharLength(e.target.value.length);
															onChange(e);
														}}
													/>
												)}
												rules={{
													required: {
														value: (!updateFlag) ? false : true,
														message: t('validationErrors.required'),
													},
													minLength: {
														message: t('validationErrors.minLength10'),
														value: 10,
													},
													maxLength: {
														message: t('validationErrors.maxLength350'),
														value: 350,
													},
												}}
											/>
										</Box>
										<Box>
											<span className={classes.maxChar}>{actionTakenCharLength} / 350 {t("characters")}</span>
										</Box>
									</Grid>
								</Grid>
								<Grid container spacing={1} className={(!updateFlag) ? classes.disabledDate : null}>
									<Grid item xs={12}>
										<Box mt={2}>
											<Controller
												name="description"
												control={control}
												defaultValue={''}
												render={(
													{
														field: {
															value,
															onChange,
														},
														fieldState: {
															error
														},
													}
												) => (
													<TextField
														variant="outlined"
														fullWidth
														multiline
														rows={6}
														error={!!error}
														label={`${t('incident.description')} *`}
														helperText={error?.message}
														InputProps={{
															className: classes.input
														}}
														InputLabelProps={{
															shrink: true,
														}}
														value={value}
														onChange={onChange}
													/>
												)}
												rules={{
													required: {
														value: (!updateFlag) ? false : true,
														message: t('validationErrors.required'),
													},
													minLength: {
														message: t('validationErrors.minLength10'),
														value: 10,
													},
													maxLength: {
														message: t('validationErrors.maxLength1000'),
														value: 1000,
													},
												}}
											/>
										</Box>
									</Grid>
								</Grid>
								
							</Box>
						</Box>
						</> : null}
						{type != 'Planned Work'  ? (
							<Grid item xs={12} sm={12} style={{padding: '2px 140px'}} >
								<>
								{
									(status === '2' || status === '3') ? <>
									<Box mt={2}>
									<Controller
										name="scheduledNotificationTime"
										rules={{
											required: {
												
												message: t('validationErrors.required'),
											},
										}}
										render={(
											{
												field: {
													value,
													onChange,
												},
												fieldState: {
													error
												},
											}
										) => (
											<MuiPickersUtilsProvider utils={DateFnsUtils} locale={lang === EN ? enLocale : daLocale}>
												<DateTimePicker
													disablePast
													minDate={new Date()}
													okLabel={t('ok')}
													cancelLabel={t('cancel')}
													clearLabel={t('clear')}
													clearable
													fullWidth
													className={error ? classes.errorTimePicker : ''}
													inputVariant="outlined"
													InputProps={{
														className: classes.input,
														endAdornment: <DateRangeIcon style={{color: '#555'}} />
													}}
													InputLabelProps={{
														shrink: true,
													}}
													autoOk
													ampm={false}
													value={value}
													onChange={onChange}
													label={t('incident.nextNotificationWithStatusUpdate')}
													format="dd.MM.yyyy HH:mm"
												/>
											</MuiPickersUtilsProvider>
										)}
										control={control}
										defaultValue={incident?.get('scheduledNotificationTime') ? new Date(Date.parse(incident.get('scheduledNotificationTime').slice(0, -6))) : null}
									/>
								</Box></> : null
								}
								</>
							</Grid>
						) : null}									
						{/*
						  P2 requirement ends 
						*/}
						<Box textAlign='center'>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								size="large"
								className={classes.submit}
							>
								{incidentId ? t('save') : t('create')}
							</Button>
						</Box>
					</form>
				</div>
			</Container>
			<Footer />

			<ConfirmationPopup
				confirmPreview={confirmPreview}
				setConfirmPreview={setConfirmPreview}
				callbackSubmit={handleSubmit(onSubmit)}
				incident={incidentPreview}
			/>
		</>
	);
};

export default connect(
	(state) => ({
		lang: state.get('lang'),
		incident: state.get('incident'),
		selectsData: state.get('selectsData'),
		errorMessage: state.get('errorMessage'),
		userRole: state.get('userRole'),
	}),
	{
		getIncidentConnect: getIncident,
		getSelectsDataConnect: getSelectsData,
		getIncidentPreviewConnect: getIncidentPreview,
		submitCreateEditIncidentFormConnect: submitCreateEditIncidentForm,
		setErrorDetailsConnect: setErrorDetails,
	},
)(CreateEditIncident);
