const translation = {
	translation: {
		"auth": {
			"email": "E-mail",
			"password": "Adgangskode",
			"enterNewPassword": "Indtast nyt adgangskode",
			"repeatNewPassword": "Gentag nyt adgangskode",
			"updatePassword": "Opdater nyt adgangskode",
			"logIn": "Log ind",
			"logOut": "Log ud",
			"continue": "Blive ved",
			"update": "Opdater",
			"forgotPassword": "Glemt adgangskode?",
			"backToLogIn": "Tilbage til log ind",
			"resetYourPassword": "Nulstil din adgangskode",
			"enterEmailAddress": "Indtast e-mail-adressen til din OI App-konto. Hvis vi har dig i systemet sender vi et link hurtigst muligt.",
			"instructionHeading": "Vejledning til nulstilling er blevet sendt til",
			"instructionTitle": "Intet nulstllings link?",
			"instruction1": "1. Det findes muligvis i mappen Spam.",
			"instruction2": "2. Kontroller din stavning.",
			"instruction3": "3. Vent et øjeblik og prøv igen om 15 minutter.",
			"resetSuccess": "Adgangskoden er opdateret",
			"resetError": "Der opstod en fejl under opdatering af adgangskoden. Kontakt venligst din systemadministrator.",
		},
		"validationErrors": {
			"required": "Feltet er obligatorisk",
			"email": "Dette er ikke en gyldig e-mail-adresse",
			"numbers": "Feltet skal være et tal",
			"passwordsNotMatch": "Adgangskoderne stemmer ikke overens",
			"endDate": "Forkerte slutdato",
			"minLength2": "Dette felt må ikke være kortere end 2 tegn",
			"minLength3": "Dette felt må ikke være kortere end 3 tegn",
			"minLength10": "Dette felt må ikke være kortere end 10 tegn",
			"maxLength7": "Dette felt må ikke være længere end 7 tegn",
			"maxLength20": "Dette felt må ikke være længere end 20 tegn",
			"maxLength45": "Dette felt må ikke være længere end 45 tegn",
			"maxLength50": "Dette felt må ikke være længere end 50 tegn",
			"maxLength100": "Dette felt må ikke være længere end 100 tegn",
			"maxLength255": "Dette felt må ikke være længere end 255 tegn",
			"maxLength1000": "Dette felt må ikke være længere end 1000 tegn",
		},
		"filter": {
			"affectedUsers": "Antal berørte kunder",
			"apply": "Anvend",
			"back": "Tilbage",
			"clear": "Slet",
			"details": "Detaljer",
			"eventsLog": "Status opdateringer",
			"externalError": "Ekstern fejl",
			"filters": "Filtre",
			"incident": "Incident",
			"incidentOwner": "Incident ejer",
			"infoText": "Filteropdateringer påvirker ikke push-meddelelsesindstillinger",
			"location": "Område",
			"nuuday": "Nuuday",
			"plannedWork": "Planlagt arbejde",
			"quickFilters": "Hurtige Filtre",
			"rootCause": "Formodet incident årsag",
			"serviceType": "Service",
			"severity": "Severity",
			"sortBy": "Sortér efter",
			"status": "Status",
			"tdcNet": "TDC Net",
			"type": "Type",
			"sortName": {
				"startDate": "Start (dato)",
				"numberOfAffectedUsers": "Antal berørte kunder",
				"severity": "Severity"
			},
			"search" : "Search Page"
		},
		"incidentsList": {
			"title": "Titel",
			"startDate": "Start (dato)",
			"resolvedDate": "Løst (dato)",
			"closedDate": "Lukket (dato)",
			"incidentOwner": "Incident ejer",
			"incidentVendor": "Leverandør",
			"ticketID": "Ticket-ID",
			"serviceType": "Service",
			"severity": "Severity",
			"affectedUsers": "Antal berørte kunder",
			"status": "Status",
			"location": "Område",
			"type": "Type",
			"statement": "Erklæring",
			"rootCause": "Formodet incident årsag",
			"customersAffectedByName": "Påvirkede zero tolerance customers",
			"noRecordsFound": "Intet resultat",
		},
		"incidentActions": {
			"viewDetails": "Se detaljer",
			"addStatement": "Tilføj User Impact",
			"edit": "Rediger",
			"addUpdate": "Tilføj opdatering",
		},
		"filters": {
			"filter": "Filter",
			"filters": "Filtre",
			"selectAll": "Vælg alle",
			"clearAll": "Slet alle",
			"all": "Alle",
			"year": "År",
			"month": "Måned",
			"week": "Uge",
			"customRange": "Brugerdefinerede datoer",
			"searchByTicketID": "Søg efter Ticket-ID eller Navn",
		},
		"incident": {
			"incident": "Incident",
			"eventsLog": "Hændelseslog Log",
			"statusUpdates": "Status opdateringer",
			"statement": "Erklæring",
			"externalStatementContract": "Ekstern Erklæring kontakt",
			"backToAllIncidents": "Tilbage til alle incidents",
			"createIncident": "Opret incident",
			"editIncident": "Rediger Incident",
			"incidentOwner": "Incident ejer",
			"incidentVendor": "Leverandør",
			"title": "Titel",
			"troubleTicketID": "Problemer Ticket-ID",
			"mainServiceAffected": "Primær Service",
			"newMainService": "Nyt Primær Service",
			"subServiceAffected": "Sekundær Service",
			"newSubService": "Nyt Sekundær Service",
			"update": "Opdatering",
			"servicesAffected": "Berørte services",
			"addService": "Tilføj Service",
			"addSubService": "Tilføj Sekundær Service",
			"severityPriority": "Severity / Prioritet",
			"rootCause": "Formodet incident årsag",
			"customNumberOfCustomerAffected": "Brugerdefinerede antal berørte kunder",
			"numberOfCustomersAffected": "Antal berørte kunder",
			"customersAffectedByName": "Påvirkede zero tolerance customers",
			"status": "Status",
			"location": "Område",
			"startDate": "Start (dato)",
			"endDate": "Slutdato",
			"resolvedDate": "Løst (dato)",
			"closedDate": "Lukket (dato)",
			"plannedWork": "Planlagt arbejde",
			"externalError": "Ekstern fejl",
			"nextNotificationWithStatusUpdate": "Næste notifikation med statusopdatering",
			"type": "Type",
			"viewIncident": "Se incident",
			"updateTitle": "Opdatering titel*",
			"description": "Beskrivelse",
			"statementSuccessfullyChanged": "Erklæring er opdateret",
			'customValue': 'Brugerdefinerede værdi',
			'otherLanguage': 'Andet sprog',
			'customServices': 'Brugerdefinerede services',
			"timeOccured": 'Start (Dato)',
			"Yes": "Ja",
			"No": "Nej",
			"Partly": "Delvist",
			"impactedStakeholders": "De berørte interessenter er blevet informeret?",
			"nameTeamDepartment": "Navn på Team og Afdeling",
			"startOfTechnicalBridge": 'Start på Technical Bridge',
			"investigation": "Undersøgelse",
			"percentResolved": "% Løst",
			"percentResolved2": "Percentage Løst",
			"technical": "Teknisk",
			"process": "Proces",
			"none": "Ingen",
			"mitigate": "Hvad er der blevet gjort for at mitigere?",
			"rfo": "RFO - Årsag til udfald (Reason for outage)",
			"actionTaken": "Udførte Handlinger",
			"escalationType": "Eskaleringstype",
			"startOfCommunicationBridge": 'Start på Communication Bridge',
			"duplicatedTicket": "Incident med det valgte Ticked ID eksisterer allerede",
			"confirmationText": "Læs venligst nedenstående igennem og bekræft at oplysningerne er korrekte. Efter bekræftelse bliver oplysningerne tilføjet systemet, og det ansvarlige personale underrettes.",
		},
		"user": {
			"backToAllUsers": "Tilbage til alle brugere",
			"createUser": "Opret bruger",
			"editUser": "Rediger bruger",
			"userRole": "Rolle",
			"email": "E-mail",
			"password": "Adgangskode",
			"name": "Fornavn",
			"surname": "Efternavn",
			"companyDivision": "Virksomhed / Afdeling",
			"phoneNumber": "Telefonnummer",
			"deleteUser": "Slet bruger",
			"deleteThisUser": "Slet denne bruger?",
		},
		"service": {
			"backToAllServices": "Tilbage til alle services",
			"createService": "Opret Service",
			"editService": "Rediger Service",
			"mainService": "Primær Service",
			"subService": "Sekundær Service",
			"subServices": "Sekundær services",
			"deleteService": "Slet Service",
			"confirmMessage": "All affected incidents will be updated according to the changes made. Are you sure?",
		},
		"customer": {
			"customerName": "Påvirkede zero tolerance customers",
			"confirmMessage": "All affected incidents will be updated according to the changes made. Are you sure?",
		},
		"roles": {
			"readOnly": "Læseadgang",
			"tdcManagement": "TDC NET management",
			"nsocAgent": "NSOC agent",
			"statementAgent": "Erklæringer agent",
			"superAdministrator": "Super admin",
			"administrator": "Admin",
			"viewIncidents": "Se incidents",
			"viewStatements": "Se erklæringer",
			"createEditIncidents": "Opret/Rediger incidents",
			"createEditStatements": "Opret/Rediger erklæringer",
			"createEditViewIncidents": "Opret/Rediger/Se incidents",
			"createEditViewStatements": "Opret/Rediger/Se erklæringer",
			"createEditViewDeleteUsers": "Opret/Rediger/Se/Slet kunder",
			"assignUserRole": "Tildel en bruger til den eksisterende rolle",
			"ewiiUser" : "EWII"
		},
		"yes": "Ja",
		"no": "Nej",
		"ok": "Okay",
		"clear": "Slet",
		"incidents": "Incidents",
		"create": "Opret",
		"edit": "Rediger",
		"save": "Gem",
		"cancel": "Annuller",
		"confirm": "Bekræft",
		"details": "Detaljer",
		"back": "Tilbage",
		"main": "Primær",
		"sub": "Sekundær",
		"more": "Mere",
		"less": "Mindre",
		"apply": "Anvend",
		"delete": "Slet",
		"customizeView": "Tilpas visning",
		"fields": "Feltet",
		"open": "Åbne",
		"ongoing": "I gang",
		"critical": "Kritisk",
		"inProgress": "I gang",
		"noIncidents": "Ingen incidents",
		"areYouSure": "Er du sikker på, at du vil ændre status?",
		"viewMap": "Se kort",
		"other": "Andet",
		"update": "Opdatering",
		"dashboard": "Dashboard",
		"users": "Brugeradministration",
		"services": "Service administration",
		"customers": "Zero tolerance customers administration",
		"statusUpdates": "Opdatering administration",
		"totalIncident": "Totale Incidents",
		"characters": "Karakterer"
	}
};

export default translation;