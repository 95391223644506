import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Filter from './Filter/Filter';
import CustomizeView from './CustomizeView';
import DateFilter from './DateFilter';
import Search from './Search';
import IncidentCount from './IncidentCount';

const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: '100%',

		[theme.breakpoints.up('lg')]: {
			maxWidth: '95%',
		},
	},
	inner: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between',

		[theme.breakpoints.down('xs')]: {
			display: 'block',
		},
	},
	leftBlock: {
		alignItems: 'center',
		display: 'flex',

		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	rightBlock: {
		alignItems: 'center',
		display: 'flex',

		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	incidentsCounter: {
		marginRight: theme.spacing(3.5),
		textAlign: 'right',

		[theme.breakpoints.down('md')]: {
			marginRight: 0,
			marginBottom: theme.spacing(1),
			padding: '11px 0',
		},

		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(1),
			marginBottom: 0,
			textAlign: 'left',
		},
	},
}));

const IncidentsControls = (/* props */) => {
	const classes = useStyles();

	/* const { allIncidentCounts } = props; */

	return (
		<Container className={classes.container} maxWidth={false}>
			<div className={classes.inner}>
				<Box className={classes.leftBlock}>
					<DateFilter />
					<Search />
					<IncidentCount/>
				</Box>
				<Box className={classes.rightBlock}>
					{/*<div className={classes.incidentsCounter}>{allIncidentCounts.get('open')} Open &nbsp;|&nbsp; {allIncidentCounts.get('ongoing')} Ongoing &nbsp;|&nbsp; {allIncidentCounts.get('closed')} Closed</div>*/}
					<Filter />
					<CustomizeView />
				</Box>
			</div>
		</Container>
	);
};

export default IncidentsControls;
