import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route,	Routes,	Navigate} from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './translations/resources';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';

import Login from './components/Auth/Login';
import PasswordRecovery from './components/Auth/PasswordRecovery';
import PasswordReset from './components/Auth/PasswordReset';
import IncidentsPage from './components/Incidents/IncidentsPage';
import CreateEditIncident from './components/Incidents/CreateEditIncident/CreateEditIncident';
import IncidentDetailsPage from './components/Incidents/IncidentDetails/IncidentDetailsPage';
import UsersPage from './components/Users/UsersPage';
import CreateEditUser from './components/Users/CreateEditUser/CreateEditUser';
import ServicesPage from './components/Services/ServicesPage';
import CreateEditService from './components/Services/CreateEditService/CreateEditService';
import CustomersPage from './components/Customers/CustomersPage';
import CreateEditCustomer from './components/Customers/CreateEditCustomer/CreateEditCustomer';
import StatusUpdatesPage from './components/StatusUpdates/StatusUpdatesPage';
import CreateEditStatusUpdate from './components/StatusUpdates/CreateEditStatusUpdates/CreateEditStatusUpdate';

import { initAuth, EN } from './utils/ducks.js';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const App = (props) => {
	const classes = useStyles();

	const {
		init,
		token,
		userRole,
		lang,
		isLoading,
		initAuthSagaConnect,
	} = props;

	useEffect(() => {
		initAuthSagaConnect();
	}, []);

	useEffect(() => {
		i18n.use(initReactI18next).init({
			resources,
			lng: lang,
			fallbackLng: EN,

			interpolation: {
				escapeValue: false
			}
		});
	}, [lang]);

	if (!init) {
		return null;
	}

	const userRolesChecker = () => {
		return (
			<Routes>
				{userRole === 'ROLE_SUPER_ADMIN' || userRole !== 'ROLE_ADMINISTRATOR' ? <Route path="/incidents" element={<IncidentsPage />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' || userRole !== 'ROLE_ADMINISTRATOR' ? <Route path="/view-incident/:incidentId" element={<IncidentDetailsPage />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_INCIDENT_MANAGER' ? <Route path="/create-incident" element={<CreateEditIncident />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_INCIDENT_MANAGER' ? <Route path="/edit-incident/:incidentId" element={<CreateEditIncident />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_INCIDENT_MANAGER' ? <Route path="/edit-incident/update/:incidentId" element={<CreateEditIncident />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_PR' ? <Route path="/add-statement/:incidentId" element={<IncidentDetailsPage />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_ADMINISTRATOR' ? <Route path="/users" element={<UsersPage />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_ADMINISTRATOR' ? <Route path="/create-user" element={<CreateEditUser />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_ADMINISTRATOR' ? <Route path="/edit-user/:userId" element={<CreateEditUser />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' ? <Route path="/services" element={<ServicesPage />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' ? <Route path="/create-service" element={<CreateEditService />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' ? <Route path="/edit-service/:serviceId" element={<CreateEditService />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' ? <Route path="/customers" element={<CustomersPage />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' ? <Route path="/create-customer" element={<CreateEditCustomer />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' ? <Route path="/edit-customer/:customerId" element={<CreateEditCustomer />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' ? <Route path="/status-updates" element={<StatusUpdatesPage />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' ? <Route path="/create-status-update" element={<CreateEditStatusUpdate />} /> : null}
				{userRole === 'ROLE_SUPER_ADMIN' ? <Route path="/edit-status-update/:statusUpdateId" element={<CreateEditStatusUpdate />} /> : null}
				<Route path="*" element={<Navigate replace to={userRole === 'ROLE_ADMINISTRATOR' ? "/users" : "/incidents"} />} />
			</Routes>
		)
	};

	return (
		<div className="app">
			<CssBaseline />
			{token ? (
				userRolesChecker()
			) :	(
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/password-recovery" element={<PasswordRecovery />} />
					<Route path="/password-reset" element={<PasswordReset />} />
					<Route path="*" element={<Navigate replace to="/login" />} />
				</Routes>
			)}
			<Backdrop className={classes.backdrop} open={isLoading}>
				<CircularProgress color="primary" />
			</Backdrop>
		</div>
	);
};

export default connect(
	(state) => ({
		init: state.get('init'),
		token: state.get('token'),
		isLoading: state.get('isLoading'),
		userRole: state.get('userRole'),
		lang: state.get('lang'),
	}), {
		initAuthSagaConnect: initAuth,
	},
)(App);
