const translation = {
	translation: {
		"auth": {
			"email": "Email",
			"password": "Password",
			"enterNewPassword": "Enter New Password",
			"repeatNewPassword": "Repeat New Password",
			"updatePassword": "Update Password",
			"logIn": "Log In",
			"logOut": "Log Out",
			"continue": "Continue",
			"update": "Update",
			"forgotPassword": "Forgot password?",
			"backToLogIn": "Back to Log In",
			"resetYourPassword": "Reset your password",
			"enterEmailAddress": "Enter email address associated with your account and we'll send you a link to reset your password.",
			"instructionHeading": "Instructions for resetting have been sent to",
			"instructionTitle": "No reset link?",
			"instruction1": "1. It may be in the Spam folder.",
			"instruction2": "2. Check your spelling.",
			"instruction3": "3. Wait a moment and try again in 15 minutes.",
			"resetSuccess": "Password was updated successfully.",
			"resetError": "An error occurred while updating password. Please contact your system administrator.",
		},
		"validationErrors": {
			"required": "This field is required",
			"email": "This value is not a valid email address",
			"numbers": "The field must be a number",
			"passwordsNotMatch": "The passwords do not match",
			"endDate": "Invalid end date",
			"minLength2": "This field cannot be shorter than 2 characters",
			"minLength3": "This field cannot be shorter than 3 characters",
			"minLength10": "This field cannot be shorter than 10 characters",
			"maxLength7": "This field cannot be longer than 7 characters",
			"maxLength20": "This field cannot be longer than 20 characters",
			"maxLength45": "This field cannot be longer than 45 characters",
			"maxLength50": "This field cannot be longer than 50 characters",
			"maxLength100": "This field cannot be longer than 100 characters",
			"maxLength250": "This field cannot be longer than 250 characters",
			"maxLength255": "This field cannot be longer than 255 characters",
			"maxLength350": "This field cannot be longer than 350 characters",
			"maxLength1000": "This field cannot be longer than 1000 characters",
		},
		"filter": {
			"affectedUsers": "Affected Users",
			"apply": "Apply",
			"back": "Back",
			"clear": "Clear",
			"eventsLog": "Events Log",
			"filters": "Filters",
			"incident": "Incident",
			"incidentOwner": "Incident Owner",
			"infoText": "Changing Filters does not affect Push notification settings",
			"location": "Location",
			"nuuday": "Nuuday",
			"plannedWork": "Planned Work",
			"quickFilters": "Quick Filters",
			"rootCause": "Suspected incident cause",
			"serviceType": "Service Type",
			"severity": "Severity",
			"sortBy": "Sort by",
			"status": "Status",
			"tdcNet": "TDC Net",
			"type": "Type",
			"sortName": {
				"startDate": "Start Date",
				"numberOfAffectedUsers": "Number of affected users",
				"severity": "Severity"
			}
		},
		"incidentsList": {
			"title": "Title",
			"startDate": "Start Date",
			"resolvedDate": "Resolved Date",
			"closedDate": "Closed Date",
			"incidentOwner": "Incident Owner",
			"incidentVendor": "Incident Vendor",
			"ticketID": "Ticket ID",
			"serviceType": "Service Type",
			"severity": "Severity",
			"affectedUsers": "Affected Users",
			"status": "Status",
			"location": "Location",
			"type": "Type",
			"statement": "Business Impact",
			"rootCause": "Suspected incident cause",
			"customersAffectedByName": "Zero tolerance customers affected",
			"noRecordsFound": "No Records Found",
		},
		"incidentActions": {
			"viewDetails": "View Details",
			"addStatement": "Add Business Impact",
			"edit": "Edit",
			"addUpdate": "Add Update",
		},
		"filters": {
			"filter": "Filter",
			"filters": "Filters",
			"selectAll": "Select All",
			"clearAll": "Clear All",
			"all": "All",
			"year": "Year",
			"month": "Month",
			"week": "Week",
			"customRange": "Custom Range",
			"searchByTicketID": "Search by Ticket ID or Title",
			"search" : "Search Page"
		},
		"incident": {
			"incident": "Incident",
			"eventsLog": "Events Log",
			"statusUpdates": "Status Updates",
			"statement": "Business Impact",
			"externalStatementContract": "External Business Impact Contact",
			"backToAllIncidents": "Back to All Incidents",
			"createIncident": "Create Incident",
			"editIncident": "Edit Incident",
			"incidentOwner": "Incident Owner",
			"incidentVendor": "Incident Vendor",
			"title": "Title",
			"troubleTicketID": "Trouble Ticket ID",
			"mainServiceAffected": "Main Service Affected",
			"newMainService": "New Main Service",
			"subServiceAffected": "Sub Service Affected",
			"newSubService": "New Sub Service",
			"update": "Update",
			"servicesAffected": "Services Affected",
			"addService": "Add Service",
			"addSubService": "Add Sub Service",
			"severityPriority": "Severity/Priority",
			"rootCause": "Suspected incident cause",
			"numberOfCustomersAffected": "Number of Customers Affected",
			"customNumberOfCustomerAffected": "Custom Number of Customer Affected",
			"customersAffectedByName": "Zero tolerance customers affected",
			"status": "Status",
			"location": "Location",
			"startDate": "Start Date",
			"endDate": "End Date",
			"resolvedDate": "Resolved Date",
			"closedDate": "Closed Date",
			"plannedWork": "Planned Work",
			"externalError": "External Error",
			"nextNotificationWithStatusUpdate": "Next Notification with Status Update",
			"type": "Type",
			"viewIncident": "View Incident",
			"updateTitle": "Update Title*",
			"description": "Description",
			'customValue': 'Custom Value',
			'otherLanguage': 'Other Language',
			'customServices': 'Custom Services',
			"statementSuccessfullyChanged": "Business Impact has been successfully changed!",
			"duplicatedTicket": "The incident with selected External Ticket ID already exists",
			"confirmationText": "Please review the information below and confirm its correct to the best of your knowledge. Once confirmed, it will be added to the system and responsible staff will be notified.",
			"timeOccured": 'Time occured',
			"startOfTechnicalBridge": 'Start of Technical Bridge',
			"startOfCommunicationBridge": 'Start of Communication Bridge',
			"nameTeamDepartment": "Name of Team and Department",
			"investigation": "Investigation",
			"impactedStakeholders": "The impacted stakeholders have been informed?",
			"Yes": "Yes",
			"No": "No",
			"Partly": "Partly",
			"mitigate": "What has been done to mitigate ?",
			"rfo": "RFO - Reason for outage",
			"percentResolved": "% resolved",
			"percentResolved2": "Percentage resolved",
			"technical": "Technical",
			"process": "Process",
			"none": "None",
			"escalationType": "Escalation Type",
			"actionTaken": "Actions Taken",
			"initialUpdate": 'Initial Update',
			"latestUpdate": "Latest Update"
		},
		"user": {
			"backToAllUsers": "Back to All Users",
			"createUser": "Create User",
			"editUser": "Edit User",
			"userRole": "User Role",
			"email": "Email",
			"password": "Password",
			"name": "Name",
			"surname": "Surname",
			"companyDivision": "Company/Division",
			"phoneNumber": "Phone Number",
			"deleteUser": "Delete User",
			"deleteThisUser": "Delete this user?",
		},
		"service": {
			"backToAllServices": "Back to All Services",
			"createService": "Create Service",
			"editService": "Edit Service",
			"mainService": "Main Service",
			"subService": "Sub Service",
			"subServices": "Sub Services",
			"deleteService": "Delete Service",
			"confirmMessage": "All affected incidents will be updated according to the changes made. Are you sure?",
		},
		"customer": {
			"customerName": "Zero tolerance customers affected",
			"confirmMessage": "All affected incidents will be updated according to the changes made. Are you sure?",
		},
		"roles": {
			"readOnly": "Read Only",
			"tdcManagement": "TDC NET Management",
			"nsocAgent": "NSOC Agent",
			"statementAgent": "Business Impact Agent",
			"superAdministrator": "Super Administrator",
			"administrator": "Administrator",
			"viewIncidents": "View incidents",
			"viewStatements": "View statements",
			"createEditIncidents": "Create/Edit incidents",
			"createEditStatements": "Create/Edit statements",
			"createEditViewIncidents": "Create/Edit/View incidents",
			"createEditViewStatements": "Create/Edit/View statements",
			"createEditViewDeleteUsers": "Create/Edit/View/Delete users",
			"assignUserRole": "Assign a user to existing role",
			"ewiiUser" : "EWII"
		},
		"yes": "Yes",
		"no": "No",
		"ok": "Ok",
		"clear": "Clear",
		"incidents": "Incidents",
		"create": "Create",
		"edit": "Edit",
		"save": "Save",
		"cancel": "Cancel",
		"confirm": "Confirm",
		"details": "Details",
		"back": "Back",
		"main": "Main",
		"sub": "Sub",
		"more": "More",
		"less": "Less",
		"apply": "Apply",
		"delete": "Delete",
		"customizeView": "Customize View",
		"fields": "Fields",
		"open": "Open",
		"ongoing": "Ongoing",
		"critical": "Critical",
		"inProgress": "Ongoing",
		"noIncidents": "No Incidents",
		"areYouSure": "Are you sure you want to change status?",
		"viewMap": "View Map",
		"other": "Other",
		"update": "Update",
		"dashboard": "Dashboard",
		"users": "Users management",
		"services": "Services management",
		"customers": "Zero tolerance customers affected management",
		"statusUpdates": "Status Updates management",
		"totalIncident": "Total Incidents",
		"characters": "Characters"
	}
};

export default translation;