import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import Header from '../Header';
import Footer from '../Footer';
import ServicesList from './ServicesList/ServicesList';

import {DA, EN, getServicesData} from '../../utils/ducks';

const ServicesPage = (props) => {
	const { t } = useTranslation();

	const {
		servicesData,
		getServicesDataConnect,
		lang,
	} = props;

	useEffect(() => {
		const requestUrl = `/api/main_services${window.location.search}`;

		getServicesDataConnect(requestUrl);
	}, [lang]);

	return (
		<>
			<Header>
				<Button
					variant="contained"
					color="primary"
					size="large"
					startIcon={<AddIcon />}
					href="/create-service"
				>
					{t('service.createService')}
				</Button>
			</Header>
			<ServicesList
				servicesList={servicesData.get('list')}
				totalItems={servicesData.get('totalItems')}
				lang={lang}
				langList={{
					english: EN,
					danish: DA,
				}}
			/>
			<Footer />
		</>
	);
};

export default connect(
	(state) => ({
		servicesData: state.get('servicesData'),
		lang: state.get('lang'),
	}), {
		getServicesDataConnect: getServicesData,
	},
)(ServicesPage);