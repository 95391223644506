import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';

import Header from '../../Header';
import Footer from '../../Footer';
import AlertPopup from '../../Common/AlertPopup'

import {
	EN, DA, DELETED,
	deleteService,
	getService,
	setErrorDetails,
	submitCreateEditServiceForm,
} from '../../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(6),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: '70vh',
	},
	deleteItem: {
		position: 'absolute',
		right: -44,
		top: 4,
	},
	relative: {
		position: 'relative'
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	input: {
		backgroundColor: '#fff',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(5, 0, 2),
		paddingLeft: theme.spacing(8),
		paddingRight: theme.spacing(8),
	},
	error: {
		marginTop: theme.spacing(3),
		color: theme.palette.error.main,
		textAlign: 'center',
	},
}));

const deleteSubServiceHandler = (index, setSubServicesArray) => {
	setSubServicesArray((arr) => {
		const tempArr = [...arr];

		tempArr[index] = DELETED;

		return tempArr;
	});
};

const CreateEditService = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	let params = useParams();
	let navigate = useNavigate();

	const {
		service,
		getServiceConnect,
		errorMessage,
		lang,
		deleteServiceConnect,
		submitCreateEditServiceFormConnect,
		setErrorDetailsConnect,
	} = props;

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
		trigger,
	} = useForm({
		shouldUnregister: true,
	});

	const { serviceId } = params;
	const [firsTrigger, setFirsTrigger] = useState(true);
	const [subServiceCounter, setSubServiceCounter] = useState(1);
	const [subServicesArray, setSubServicesArray] = useState(Array.from({length: subServiceCounter}, (v, i) => ({
		key: i,
	})));
	const [submitConfirm, setSubmitConfirm] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const subServices = service.get('subServices');

	useEffect(() => {
		setSubmitConfirm(false);
	}, [errorMessage]);

	useEffect(() => {
		return () => {
			setErrorDetailsConnect('')
		};
	}, []);

	useEffect(() => {
		if (serviceId) {
			getServiceConnect({
				id: serviceId,
				redirectCallback: navigate,
			});
		}

		if (Object.keys(errors).length > 0) {
			trigger();
		}
	}, [lang]);

	useEffect(() => {
		if (serviceId) {
			setValue('mainService', service.get('name'));
			setValue('mainServiceTranslation', service.get('translations')?.get(0)?.get('value'));
			subServices?.forEach((value, index) => {
				setValue(`subServices[${index}]`, value.get('name'));
				setValue(`subServicesTranslation[${index}]`, value.get('translations')?.get(0)?.get('value'));
			})
		}
	}, [service]);

	const onSubmit = (data) => {
		if (serviceId && !submitConfirm) {
			setSubmitConfirm(true);
		} else {
			const {
				mainService,
				mainServiceTranslation,
				subServices,
				subServicesTranslation,
			} = data;

			const result = {
					'@id': serviceId ? service.get('@id') : '',
					'name': mainService,
					translations: [
						{
							locale: lang === EN ? DA : EN,
							value: mainServiceTranslation,
							field: 'name',
						}
					],
				subServices: Object.entries(subServices).map((item, index) => {
					return {
						'@id': item[0].indexOf('/api/sub_services/') ? '' : item[0],
						name: item[1],
						mainService: serviceId ? service.get('@id') : '',
						translations: [
							{
								locale: lang === EN ? DA : EN,
								value: Object.entries(subServicesTranslation)[index][1],
								field: 'name',
							}
						]
					}
				})
			};

			submitCreateEditServiceFormConnect({
				id: serviceId,
				formData: result,
				redirectCallback: navigate,
			})
		}
	};

	const addSubService = () => {
		setSubServiceCounter(subServiceCounter + 1);

		setSubServicesArray((arr) => {
			const tempArr = [...arr];
			tempArr.push({
				key: arr.length,
			});

			return tempArr;
		});
	};

	const showConfirmMessage = (e) => {
		e?.preventDefault();

		setShowAlert(true);
	};

	const deleteService = (id) => {
		deleteServiceConnect({
			id: id,
			redirectCallback: navigate,
		})
	};

	if (serviceId && !service.size) {
		return null;
	}

	if (serviceId && service.size && firsTrigger) {
		if (subServices.size) {
			setSubServiceCounter(subServices.size);
			const tempArr = Array.from({length: subServices.size}, (v, i) => ({
				key: i,
				isCustomServices: false,
			}));
			setSubServicesArray(tempArr);
		}
		setFirsTrigger(false);
	}

	return (
		<>
			<Header>
				<Button
					variant="outlined"
					color="primary"
					size="large"
					startIcon={<ArrowBackIcon />}
					href="/services"
				>
					{t('service.backToAllServices')}
				</Button>
			</Header>
			<Container maxWidth="sm">
				<div className={classes.paper}>
					<Typography className={classes.heading} component="h1" variant="h4" color="primary">
						<b>{serviceId ? t('service.editService') : t('service.createService')}</b>
					</Typography>
					<form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box mt={2}>
									<Controller
										name="mainService"
										control={control}
										defaultValue={service.get('name') || ''}
										render={(
											{
												field,
												fieldState: {
													error
												},
											}
										) => (
											<TextField
												{...field}
												variant="outlined"
												fullWidth
												error={!!error}
												label={`${t('service.mainService')} ${lang === EN ? '(English)' : '(Danish)'}`}
												helperText={error?.message}
												InputProps={{
													className: classes.input,
												}}
											/>
										)}
										rules={{
											required: {
												value: true,
												message: t('validationErrors.required'),
											},
											minLength: {
												message: t('validationErrors.minLength3'),
												value: 3,
											},
											maxLength: {
												message: t('validationErrors.maxLength45'),
												value: 45,
											},
										}}
									/>
								</Box>
								<Box mt={2}>
									<Controller
										name="mainServiceTranslation"
										control={control}
										defaultValue={service.get('translations')?.get(0)?.get('value') || ''}
										render={(
											{
												field,
												fieldState: {
													error
												},
											}
										) => (
											<TextField
												{...field}
												variant="outlined"
												fullWidth
												error={!!error}
												label={`${t('service.mainService')} ${lang === EN ? '(Danish)' : '(English)'}`}
												helperText={error?.message}
												InputProps={{
													className: classes.input,
												}}
											/>
										)}
										rules={{
											required: {
												value: true,
												message: t('validationErrors.required'),
											},
											minLength: {
												message: t('validationErrors.minLength3'),
												value: 3,
											},
											maxLength: {
												message: t('validationErrors.maxLength45'),
												value: 45,
											},
										}}
									/>
								</Box>
							</Grid>
							{subServicesArray.filter((value) => value !== DELETED).map((v, i) => {
								return (
									<Grid item xs={12} key={v.key}>
										<hr/>
										<br/>
										<Box className={classes.relative}>
											<Controller
												name={`subServices[${v.key}]`}
												control={control}
												defaultValue={subServices?.getIn([i, 'name']) || ''}
												render={(
													{
														field,
														fieldState: {
															error
														},
													}
												) => (
													<TextField
														{...field}
														variant="outlined"
														fullWidth
														error={!!error}
														label={`${t('service.subService')} ${lang === EN ? '(English)' : '(Danish)'}`}
														helperText={error?.message}
														InputProps={{
															className: classes.input
														}}
													/>
												)}
												rules={{
													required: {
														value: true,
														message: t('validationErrors.required'),
													},
													minLength: {
														message: t('validationErrors.minLength3'),
														value: 3,
													},
													maxLength: {
														message: t('validationErrors.maxLength45'),
														value: 45,
													},
												}}
											/>
											{subServicesArray.filter((i) => i !== DELETED).length > 1
												? <IconButton className={classes.deleteItem} onClick={() => deleteSubServiceHandler(v.key, setSubServicesArray)}><DeleteOutlineIcon color={'primary'} /></IconButton>
												: null}
										</Box>
										<Box mt={1}>
											<Controller
												name={`subServicesTranslation[${v.key}]`}
												control={control}
												defaultValue={subServices?.getIn([i, 'translations'])?.get(0)?.get('value') || ''}
												render={(
													{
														field,
														fieldState: {
															error
														},
													}
												) => (
													<TextField
														{...field}
														variant="outlined"
														fullWidth
														error={!!error}
														label={`${t('service.subService')} ${lang === EN ? '(Danish)' : '(English)'}`}
														helperText={error?.message}
														InputProps={{
															className: classes.input,
														}}
													/>
												)}
												rules={{
													required: {
														value: true,
														message: t('validationErrors.required'),
													},
													minLength: {
														message: t('validationErrors.minLength3'),
														value: 3,
													},
													maxLength: {
														message: t('validationErrors.maxLength45'),
														value: 45,
													},
												}}
											/>
										</Box>
									</Grid>
								)
							})}
						</Grid>
						<Grid item xs={12}>
							<br/>
							<Button
								style={{marginRight: 8}}
								variant="outlined"
								color="primary"
								size="large"
								startIcon={<AddIcon />}
								onClick={() => addSubService()}
							>
								{t('incident.addSubService')}
							</Button>
						</Grid>
						{errorMessage ? (
							<Grid item xs={12}>
								<Box className={classes.error}>
									<b>{errorMessage}</b>
								</Box>
							</Grid>
						) : null}
						<Box textAlign='center'>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								size="large"
								className={classes.submit}
							>
								{serviceId ? t('save') : t('create')}
							</Button>
							{serviceId ? (
								<div>
									<Link href="#" onClick={(e) => showConfirmMessage(e)}>{t('service.deleteService')}</Link>
								</div>
							) : null}
						</Box>
					</form>
				</div>
			</Container>
			<Footer />

			<AlertPopup
				showAlert={submitConfirm}
				setShowAlert={setSubmitConfirm}
				title={t('service.confirmMessage')}
				callbackSubmit={handleSubmit(onSubmit)}
				callbackSubmitText={t('save')}
			/>

			<AlertPopup
				showAlert={showAlert}
				setShowAlert={setShowAlert}
				title={t('service.confirmMessage')}
				callbackSubmit={() => deleteService(serviceId)}
				callbackSubmitText={t('delete')}
			/>
		</>
	);
};

export default connect(
	(state) => ({
		service: state.get('service'),
		lang: state.get('lang'),
		errorMessage: state.get('errorMessage'),
	}),
	{
		getServiceConnect: getService,
		submitCreateEditServiceFormConnect: submitCreateEditServiceForm,
		deleteServiceConnect: deleteService,
		setErrorDetailsConnect: setErrorDetails,
	},
)(CreateEditService);
