import React from 'react';
import { Controller } from 'react-hook-form';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText  from '@material-ui/core/FormHelperText';

const FormSelectField = ({
	children,
	control,
	defaultValue,
	disabled,
	errorMessage,
	label,
	labelClasses,
	multiple,
	name,
	onChangeHandler,
	required,
	selectClassName,
	...props
}) => {
	const labelId = `${name}-label`;
	return (
		<FormControl {...props}>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				rules={{
					required: children.length !== 0 ? required : false,
				}}
				render={(
					{
						field: {
							value,
							onChange,
						},
						fieldState: {
							error
						},
					}
				) => (
					<>
						<InputLabel
							disabled={disabled}
							error={!!error}
							id={labelId}
							classes={labelClasses}
						>
							{label}
						</InputLabel>
						<Select
							error={Boolean(error)}
							labelId={labelId}
							defaultValue={defaultValue}
							label={label}
							multiple={multiple}
							disabled={children.length === 0 || disabled}
							inputProps={{
								className: selectClassName
							}}
							value={(props.status === '5' || props.status === '7') ? "" : value}
							onChange={(e) => {
								if (onChangeHandler) {
									onChangeHandler(e.target.value);
								}
								onChange(e);
							}}
						>
							{children}
						</Select>
						{error && <FormHelperText error>{errorMessage}</FormHelperText>}
					</>
				)}
			/>
		</FormControl>
	);
};
export default FormSelectField;