import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';

import {
	LOCAL_STORAGE_CUSTOMIZE_VIEW,
	setCustomizeView,
} from '../../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	viewLi: {
		outline: 'none',
	},
	viewContent: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		minWidth: 300,

		'& > .MuiFormControlLabel-root': {
			marginRight: 0,
		},
		'& .MuiFormControlLabel-label': {
			fontSize: 14,
		}
	},
	viewTitle: {
		marginBottom: theme.spacing(2),
	},
	viewSubTitle: {
		fontSize: 16,
	},
	viewItem: {
		border: '1px solid #ddd',
		display: 'flex',
		margin: theme.spacing(1, 0),
		paddingLeft: theme.spacing(1),
		borderRadius: 4,
		justifyContent: 'space-between',
	},
}));

const CustomizeView = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		customizeView,
		setCustomizeViewConnect,
	} = props;

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		localStorage.setItem(LOCAL_STORAGE_CUSTOMIZE_VIEW, JSON.stringify(customizeView));
	};

	const sortedCustomizeView = customizeView.sort((a, b) => a.get('order') - b.get('order'));

	return (
		<>
			<Button
				size="large"
				color="primary"
				startIcon={<AppsOutlinedIcon />}
				onClick={(e) => handleClick(e)}
			>
				{t('customizeView')}
			</Button>
			<Menu
				id="customize-view-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<li className={classes.viewLi}>
					<form noValidate>
						<div className={classes.viewContent}>
							<Typography
								className={classes.viewTitle}
								variant="h6"
							>
								<b>{t('customizeView')}</b>
							</Typography>
							<Typography
								className={classes.viewSubTitle}
								variant="h6"
							>
								<b>{t('fields')}</b>
							</Typography>
							{sortedCustomizeView.toList().map((item) => (
								<FormControlLabel
									key={item.get('value')}
									control={
										<Checkbox
											value={item.get('checked')}
											name={item.get('value')}
											color="primary"
											defaultChecked={item.get('checked')}
										/>
									}
									label={t(item.get('label'))}
									labelPlacement={'start'}
									className={classes.viewItem}
									onChange={() => {
										setCustomizeViewConnect({
											name: item.get('value'),
										})
									}}
								/>
							))}
						</div>
					</form>
				</li>
			</Menu>
		</>
	);
};



export default connect(
	(state) => ({
		customizeView: state.get('customizeView'),
	}), {
		setCustomizeViewConnect: setCustomizeView,
	},
)(CustomizeView);