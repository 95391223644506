import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

import severityColors from '../../../utils/constants';

import CommonExpand from '../../Common/CommonExpand';

import { formatDate, getTypeText, getAffectedUsersNumberString } from '../../../utils/ducks';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
	block: {
		backgroundColor: '#fff',
		borderRadius: 10,
		padding: theme.spacing(4, 6),
		margin:  theme.spacing(4, 0),
	},
	previewBlock: {
		margin:  theme.spacing(4, 0, 1),
	},
	heading: {
		marginBottom:  theme.spacing(3),
	},
	previewHeading: {
		margin:  theme.spacing(0, 'auto', 3),
		textAlign: 'center',
		wordBreak: 'break-word',
	},
	innerBlock: {
		marginBottom:  theme.spacing(3),
	},
	title: {
		marginBottom:  theme.spacing(1),
	},
	capitalize: {
		textTransform: 'capitalize',
	},
	headingWrap: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	serviceRow: {
		'& > div': {
			marginTop:  theme.spacing(1),
			paddingTop:  theme.spacing(1),
			borderTop: '1px solid #ccc',
		},
		'& > div:first-child': {
			borderTop: 'none',
		}
	},
	wordBreak: {
		wordBreak: 'break-word',
	}
}));

const Details = (props) => {
	const classes = useStyles();
	

	const { t } = useTranslation();

	const {
		incident,
		userRole,
		isPreview,
	} = props;

	if (!incident) {
		return null;
	}

	const mainServices = incident.get('subServices')
		.map((item) => item.getIn(['mainService', 'name']))
		.toSet().toList();

	const getSubServicesByMainService = (mainService) => (
		incident.get('subServices')
			.filter((item) => item.getIn(['mainService', 'name']) === mainService)
			.map((item) => item.get('name'))
			.toSet()
			.join(' | ')
	);

	//const locations = incident.get('countries').concat(incident.get('provinces'), incident.get('municipalities'), incident.get('cities'));
	let locations = [];
	if(incident.get('countries').size > 0) {
		//alert('countries')
		//console.log(incident.get('countries').size);
		//alert(incident.get('countries').isArray())
		//setCurrentLocation(incident.get('countries'))
		//locations.push(incident.get('countries'));
		locations = incident.get('countries');
	}

	if(incident.get('provinces').size > 0) {
		//alert('provinces')
		//console.log(incident.get('provinces').size);
		locations = incident.get('provinces');
		//alert(incident.get('provinces').isArray())
		//setCurrentLocation(incident.get('provinces'))
		//locations.push(incident.get('provinces'));
	}

	if(incident.get('municipalities').size > 0) {
		//alert('municipalities')
		locations = incident.get('municipalities');
		//setCurrentLocation(incident.get('municipalities'))
		//locations.push(incident.get('municipalities'));
	}

	if(incident.get('cities').size > 0) {
		//alert('cities')
		locations = incident.get('cities');
		//setCurrentLocation(incident.get('cities'))
		//locations.push(incident.get('cities'));
	}

	return (
		<div className={isPreview ? classes.previewBlock : classes.block}>
			<Grid container spacing={1}>
				<Grid xs={12} item>
					<Box className={classes.headingWrap}>
						<Typography className={isPreview ? classes.previewHeading : classes.heading} component="h2" variant="h6" color="primary">
							{isPreview ? <b>{incident.get('titleDa')}<br />{incident.get('titleEn')}</b> : <b>{t('details')}</b>}
						</Typography>
						{userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_INCIDENT_MANAGER' ? <Box className={classes.heading}><Link href={`/edit-incident/${incident.get('id')}`}><CreateOutlinedIcon style={{fontSize: 18, verticalAlign: 'top'}}/> {t('incidentActions.edit')}</Link></Box> : null}
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid xs={12} item>
					<Box className={classes.innerBlock}>
						<Box className={classes.title}>{t('incident.numberOfCustomersAffected')}</Box>
						<Box className={classes.wordBreak}>
							<b>{getAffectedUsersNumberString(incident)}</b>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Box className={classes.innerBlock}>
				<Box className={classes.title}>{t('incident.servicesAffected')}</Box>
				<Box className={classes.serviceRow}>
					{mainServices.size ? (
						mainServices.map((item) => (
							<Box key={item}>
								<Grid container spacing={1}>
									<Grid xs={12} sm={6} item>
										<Box>{t('main')}: <b>{item}</b></Box>
									</Grid>
									<Grid xs={12} sm={6} item>
										<Box>{t('sub')}: <b>{getSubServicesByMainService(item)}</b></Box>
									</Grid>
								</Grid>
							</Box>
						))
					) : (
						<b>--</b>
					)}
				</Box>
			</Box>
			<Grid container spacing={1}>
				<Grid xs={12} sm={6} item>
					<Box className={classes.innerBlock}>
						<Box className={classes.title}>{t('incident.severityPriority')}</Box>
						<Box className={classes.wordBreak}>
							<b style={{color: severityColors[incident.getIn(['incidentSeverity', 'id'])]}}><FiberManualRecordIcon style={{ fontSize: 10 }} /> {incident.getIn(['incidentSeverity', 'name'])}</b>
						</Box>
					</Box>
				</Grid>
				<Grid xs={12} sm={6} item>
					<Box className={classes.innerBlock}>
						<Box className={classes.title}>{t('incident.troubleTicketID')}</Box>
						<Box className={classes.wordBreak}>
							<b>{incident.get('externalTicketId')}</b>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid xs={12} sm={6} item>
					<Box className={classes.innerBlock}>
						<Box className={classes.title}>{t('incident.incidentOwner')}</Box>
						<Box className={classes.wordBreak}>
							<b>{incident.getIn(['incidentOwner', 'name'])}</b>
						</Box>
					</Box>
				</Grid>
				<Grid xs={12} sm={6} item>
					<Box className={classes.innerBlock}>
						<Box className={classes.title}>{t('incident.rootCause')}</Box>
						<Box className={classes.wordBreak}>
							<b>{incident.getIn(['rootCause', 'name'])}</b>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid xs={12} sm={6} item>
					<Box className={classes.innerBlock}>
						<Box className={classes.title}>{t('incident.incidentVendor')}</Box>
						<Box className={classes.wordBreak}>
							{incident.get('incidentVendor') ? (
								<b>{incident.getIn(['incidentVendor', 'name'])}</b>
							) : (
								<b>--</b>
							)}
						</Box>
					</Box>
				</Grid>
				<Grid xs={12} sm={6} item>
					<Box className={classes.innerBlock}>
						{/*<Box className={classes.title}>{t('incident.customersAffectedByName')}</Box>
						<Box className={classes.wordBreak}>
							{incident.get('customers').map((customer, index) => (
								<b key={index}>{index ? ' | ' : ''}{customer.get('name')}</b>
							))}
						</Box>*/}
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid xs={12} sm={6} item>
					<Box className={classes.innerBlock}>
						<Box className={classes.title}>{t('incident.status')}</Box>
						<Box className={classes.wordBreak}>
							<b>{incident.getIn(['incidentStatus', 'name'])}</b>
						</Box>
					</Box>
				</Grid>
				<Grid xs={12} sm={6} item>
					<Box className={classes.innerBlock}>
						<Box className={classes.title}>{t('incident.nextNotificationWithStatusUpdate')}</Box>
						<Box className={classes.wordBreak}>
							<b>{formatDate(incident.get('scheduledNotificationTime'))}</b>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				{incident.get('timeStart') ? (
					<Grid xs={12} sm={6} item>
						<Box className={classes.innerBlock}>
							<Box className={classes.title}>{t('incident.timeOccured')}</Box>
							<Box className={classes.wordBreak}>
								<b className={classes.capitalize}>{formatDate(incident.get('timeStart'))}</b>
							</Box>
						</Box>
					</Grid>
				) : null}
				<Grid xs={12} sm={6} item>
					<Box>
						<Box className={classes.title}>{t('incident.resolvedDate')}</Box>
						<Box className={classes.wordBreak}>
							<b className={classes.capitalize}>{formatDate(incident.get('timeResolved'))}</b>
						</Box>
					</Box>
				</Grid>
				{
					(incident.getIn(['incidentStatus', 'id']) != 5) ?
						<Grid xs={12} sm={6} item>
							<Box className={classes.innerBlock}>
								<Box className={classes.title}>{t('incident.closedDate')}</Box>
								<Box className={classes.wordBreak}>
									<b className={classes.capitalize}>{formatDate(incident.get('timeEnd'))}</b>
								</Box>
							</Box>
						</Grid> :
						<></>
				}
				<Grid xs={12} sm={6} item>
					<Box className={classes.innerBlock}>
						<Box className={classes.title}>{t('incident.startOfTechnicalBridge')}</Box>
						<Box className={classes.wordBreak}>
							<b className={classes.capitalize}>{formatDate(incident.get('startOfTechnicalBridge'))}</b>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid xs={12} sm={6} item>
					<Box className={classes.innerBlock}>
						
						<Box className={classes.title}>{t('incident.location')}</Box>
						<Box className={classes.wordBreak}>
							<b>
								<CommonExpand
									item={locations}
									visibleItems={6}
								/>
							</b>
						</Box>
					</Box>
				</Grid>
				<Grid xs={12} sm={6} item>
					<Box>
						<Box className={classes.title}>{t('incident.startOfCommunicationBridge')}</Box>
						<Box className={classes.wordBreak}>
							<b className={classes.capitalize}>{formatDate(incident.get('startOfCommunicationBridge'))}</b>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid xs={12} sm={6} item>
					<Box className={classes.innerBlock}>
						<Box className={classes.title}>{t('incident.type')}</Box>
						<Box className={classes.wordBreak}>
							<b className={classes.capitalize}>
								{getTypeText(incident.get('type'), t)}
							</b>
						</Box>
					</Box>
				</Grid>
				<Grid xs={12} sm={6} item>
					<Box>
						
						<Box className={classes.title}>{t('incident.impactedStakeholders')}</Box>
						<Box className={classes.wordBreak}>

							<b className={classes.capitalize}>{incident.get('impactedStakeholdersInformed') === 'yes' ? t('incident.Yes') : incident.get('impactedStakeholdersInformed') === 'no' ? t('incident.No') : t('incident.Partly')}</b>
						</Box>
					</Box>
				</Grid>
			</Grid>
			{
				(incident.getIn(['incidentStatus', 'id']) != 5) ?
					<Grid container spacing={1}>
						<Grid xs={12} sm={12} item>
							<Box className={classes.innerBlock} mt={3}>
								<Box className={classes.title}>{t('incident.investigation')}</Box>
								<Box className={classes.wordBreak}>
									<b>{incident.get('investigation') ? incident.get('investigation') : "--" }</b>
								</Box>
							</Box>
						</Grid>
					</Grid> :
					<></>
			}
			<Grid container spacing={1}>
				<Grid xs={12} sm={12} item>
					<Box className={classes.innerBlock} mt={3}>
						<Box className={classes.title}>{t('incident.mitigate')}</Box>
						<Box className={classes.wordBreak}>
							<b>{incident.get('mitigationInitiatives') ? incident.get('mitigationInitiatives') : "--"}</b>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid xs={12} sm={12} item>
					<Box className={classes.innerBlock} mt={3}>
						<Box className={classes.title}>{t('incident.rfo')}</Box>
						<Box className={classes.wordBreak}>
							<b>{incident.get('reasonForOutage') ? incident.get('reasonForOutage') : "--"}</b>
						</Box>
					</Box>
				</Grid>
			</Grid>
			{incident.get('incidentEventCommentGroupDescription') ? (
				<Grid container spacing={1}>
					<Grid item>
						<Box mt={3}>
							<hr/>
							<br/>
							<Box className={classes.title}>{t('incident.update')}</Box>
							<Box className={classes.wordBreak}>
								<b className={classes.capitalize}>
									{incident.getIn(['incidentEventCommentGroupDescription', 'incidentEventCommentGroup', 'title'])}
								</b>
								<br/>
								<Box>{incident.getIn(['incidentEventCommentGroupDescription', 'description'])}</Box>
								<Box>{incident.getIn(['incidentEventCommentGroupDescription', 'translations'])?.get(0)?.get('value')}</Box>
							</Box>
						</Box>
					</Grid>
				</Grid>
			) : null}
		</div>
	);
};

export default Details;