import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';

import { setLang, EN, DA } from '../utils/ducks';

const useStyles = makeStyles((theme) => ({
	links: {
		fontSize: 16,
		marginLeft: theme.spacing(3),
	},
	link: {
		color: theme.palette.grey[500]
	},
	checkedLink: {
		color: theme.palette.primary.main,
		fontWeight: 700,
	}
}));

const Lang = (props) => {
	const classes = useStyles();

	const {
		lang,
		setLangConnect,
	} = props;

	const changeLang = (e, lng) => {
		e.preventDefault();

		i18n.changeLanguage(lng).then(() => {
			setLangConnect(lng);
			localStorage.setItem('lang', lng);
		});
	};

	return (
		<Box className={classes.links}>
			<Link onClick={(e) => changeLang(e, DA)} className={lang === DA ? classes.checkedLink : classes.link} href={'#'}>Da</Link> | <Link onClick={(e) => changeLang(e, EN)} className={lang === EN ? classes.checkedLink : classes.link} href={'#'}>En</Link>
		</Box>
	);
};

export default connect(
	(state) => ({
		lang: state.get('lang'),
	}),
	{
		setLangConnect: setLang,
	},
)(Lang);

