import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import Header from '../Header';
import Footer from '../Footer';
import UsersList from './UsersList/UsersList';

import { getUsersData } from '../../utils/ducks';

const UsersPage = (props) => {
	const { t } = useTranslation();

	const {
		usersData,
		getUsersDataConnect,
	} = props;

	useEffect(() => {
		const requestUrl = `/api/users${window.location.search}`;

		getUsersDataConnect(requestUrl);
	}, [getUsersDataConnect]);

	return (
		<>
			<Header>
				<Button
					variant="contained"
					color="primary"
					size="large"
					startIcon={<AddIcon />}
					href="/create-user"
				>
					{t('user.createUser')}
				</Button>
			</Header>
			<UsersList
				usersList={usersData.get('list')}
				totalItems={usersData.get('totalItems')}
			/>
			<Footer />
		</>
	);
};

export default connect(
	(state) => ({
		usersData: state.get('usersData'),
	}), {
		getUsersDataConnect: getUsersData,
	},
)(UsersPage);
