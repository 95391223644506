import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
	iconButton: {
		border: '1px solid',
	},
}));

const IncidentActions = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		item,
		userRole,
	} = props;

	const incidentId = item.get('id');

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton className={classes.iconButton} color="primary" size="small" aria-haspopup="true" onClick={(e) => handleClick(e)}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="incidents-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem component={Link} href={`/view-incident/${incidentId}`}>{t('incidentActions.viewDetails')}</MenuItem>
				{userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_INCIDENT_MANAGER' ? <MenuItem component={Link} href={`/edit-incident/${incidentId}`}>{t('incidentActions.edit')}</MenuItem> : null}
				{/*userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_INCIDENT_MANAGER' ? <MenuItem component={Link} href={`/edit-incident/update/${incidentId}`}>{t('incidentActions.addUpdate')}</MenuItem> : null*/}
				{userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_PR' ? <MenuItem component={Link} href={`/add-statement/${incidentId}`}>{t('incidentActions.addStatement')}</MenuItem> : null}
			</Menu>
		</>
	);
};

export default IncidentActions;