import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

import { applyFilter, setSearchQuery } from '../../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	input: {
		backgroundColor: '#fff',
	},
	wide: {
		width: 300,
	}
}));

const Search = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	let navigate = useNavigate();

	const {
		searchQuery,
		setSearchQueryConnect,
		applyFilterConnect,
	} = props;

	const {
		control,
		handleSubmit,
	} = useForm();

	useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		setSearchQueryConnect(query.get('customSearch') || '');
	}, []);

	const onSubmit = (data) => {
		applyFilterConnect({
			redirectCallback: navigate,
			formData: data,
		});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} onReset={handleSubmit(onSubmit)} noValidate>
			<Controller
				name="title"
				control={control}
				defaultValue={searchQuery || ''}
				render={({field}) => (
					<TextField
						size={'small'}
						margin={'none'}
						variant={'outlined'}
						name={'searchQuery'}
						label={t('filters.searchByTicketID')}
						value={searchQuery}
						className={classes.wide}
						onChange={(e) => {
							setSearchQueryConnect(e.target.value);
						}}
						InputProps={{
							className: classes.input,
							type: 'text',
							endAdornment: (
								<InputAdornment position={'end'}>
									<IconButton
										edge="end"
										onClick={() => setSearchQueryConnect('')}
										type={'reset'}
									>
										<CloseIcon />
									</IconButton>
									<IconButton
										edge="end"
										type={'submit'}
									>
										<SearchOutlinedIcon color={'primary'} />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				)}
			/>
		</form>
	);
};

export default connect(
	(state) => ({
		searchQuery: state.get('searchQuery'),
	}),
	{
		setSearchQueryConnect: setSearchQuery,
		applyFilterConnect: applyFilter,
	},
)(Search);