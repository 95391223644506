import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';

import ViewRoles from './ViewRoles';
import CommonPopup from '../../Common/CommonPopup';

const useStyles = makeStyles((theme) => ({
	iconInfoButton: {
		padding: 0,
		marginRight: -1,
	},
}));

const RolesInfo = () => {
	const classes = useStyles();

	const [showPopup, setShowPopup] = useState(false);

	return (
		<>
			<IconButton className={classes.iconInfoButton} color="primary" size="small" onClick={() => setShowPopup(true)}>
				<InfoOutlinedIcon fontSize="large" />
			</IconButton>
			<CommonPopup
				showPopup={showPopup}
				setShowPopup={setShowPopup}
				size={'lg'}
			>
				<ViewRoles />
			</CommonPopup>
		</>
	);
};

export default RolesInfo;