import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Header from '../../Header';
import Footer from '../../Footer';
import FormSelectField from '../../Common/FormSelectField';
import AlertPopup from '../../Common/AlertPopup'
import RolesInfo from '../RolesInfo/RolesInfo';

import {
	getUser,
	getUserRolesSelectsData,
	submitCreateEditUserForm,
	deleteUser,
	userRolesDecorator,
	setErrorDetails,
} from '../../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(6),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: '70vh',
	},
	iconPosition: {
		position: 'absolute',
		right: -44,
		top: 10,
	},
	relative: {
		position: 'relative'
	},
	heading: {
		marginBottom: theme.spacing(1),
	},
	input: {
		backgroundColor: '#fff',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(5, 0, 2),
		paddingLeft: theme.spacing(8),
		paddingRight: theme.spacing(8),
	},
	error: {
		marginTop: theme.spacing(1),
		color: theme.palette.error.main,
		textAlign: 'center',
	},
}));

const CreateEditUser = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	let params = useParams();
	let navigate = useNavigate();

	const {
		userRolesSelectsData,
		errorMessage,
		currentUserId,
		user,
		getUserConnect,
		getUserRolesSelectsDataConnect,
		submitCreateEditUserFormConnect,
		deleteUserConnect,
		lang,
		setErrorDetailsConnect,
	} = props;

	const {
		handleSubmit,
		control,
		formState: { errors },
		trigger,
	} = useForm({
		shouldUnregister: true,
	});

	const { userId } = params;

	const [showPassword, setShowPassword] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	useEffect(() => {
		getUserRolesSelectsDataConnect();

		if (userId) {
			getUserConnect({
				id: userId,
				redirectCallback: navigate,
			});
		}
		return () => {
			setErrorDetailsConnect('')
		};
	}, []);

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			trigger();
		}
	}, [lang]);

	const onSubmit = (data) => {
		const result = {
			...data,
			roleItems: [
				data.userRole
			]
		};

		delete result.userRole;

		if (userId && !result.plainPassword) {
			delete result.plainPassword;
		}

		submitCreateEditUserFormConnect({
			formData: result,
			id: userId,
			redirectCallback: navigate,
		});
	};

	const showConfirmMessage = (e) => {
		e.preventDefault();

		setShowAlert(true);
	};

	const deleteUser = (id) => {
		deleteUserConnect({
			id: id,
			redirectCallback: navigate,
		})
	};

	if (userId && !user.size) {
		return null;
	}

	return (
		<>
			<Header>
				<Button
					variant="outlined"
					color="primary"
					size="large"
					startIcon={<ArrowBackIcon />}
					href="/users"
				>
					{t('user.backToAllUsers')}
				</Button>
			</Header>
			<Container maxWidth="sm">
				<div className={classes.paper}>
					<Typography className={classes.heading} component="h1" variant="h4" color="primary">
						<b>{userId ? t('user.editUser') : t('user.createUser')}</b>
					</Typography>
					<form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box mt={2} className={classes.relative}>
									<FormSelectField
										name="userRole"
										label={`${t('user.userRole')} *`}
										control={control}
										variant="outlined"
										fullWidth
										defaultValue={user.getIn(['roleItems', 0, '@id']) || ''}
										required
										selectClassName={classes.input}
										errorMessage={t('validationErrors.required')}
									>
										{userRolesSelectsData.map((item) => {
											const name = t(userRolesDecorator(item.get('name')));
											return(<MenuItem key={name} value={item.get('@id')}>{name}</MenuItem>);
										})}
									</FormSelectField>
									<div className={classes.iconPosition}>
										<RolesInfo />
									</div>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box mt={1}>
									<Controller
										name="email"
										control={control}
										defaultValue={user.get('email') || ''}
										render={(
											{
												field,
												fieldState: {
													error
												},
											}
										) => (
											<TextField
												{...field}
												variant="outlined"
												fullWidth
												error={!!error}
												label={`${t('user.email')} *`}
												helperText={error?.message}
												InputProps={{
													className: classes.input
												}}
											/>
										)}
										rules={{
											required: {
												value: true,
												message: t('validationErrors.required'),
											},
											pattern: {
												value: /\S+@\S+\.\S+/,
												message: t('validationErrors.email'),
											}
										}}
									/>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box mt={1}>
									<Controller
										name="plainPassword"
										control={control}
										defaultValue={''}
										render={(
											{
												field,
												fieldState: {
													error
												},
											}
										) => (
											<TextField
												{...field}
												variant="outlined"
												fullWidth
												error={!!error}
												label={userId ? t('user.password') : `${t('user.password')} *`}
												helperText={error?.message}
												InputProps={{
													className: classes.input,
													type: (showPassword ? 'text' : 'password'),
													endAdornment: (
														<InputAdornment position={'start'}>
															<IconButton
																aria-label="toggle password visibility"
																onClick={() => setShowPassword(!showPassword)}
																edge="end"
															>
																{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
															</IconButton>
														</InputAdornment>
													)
												}}
											/>
										)}
										rules={{
											required: {
												value: !userId,
												message: t('validationErrors.required'),
											}
										}}
									/>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box mt={1}>
									<Controller
										name="name"
										control={control}
										defaultValue={user.get('name') || ''}
										render={(
											{
												field,
												fieldState: {
													error
												},
											}
										) => (
											<TextField
												{...field}
												variant="outlined"
												fullWidth
												error={!!error}
												label={t('user.name')}
												helperText={error?.message}
												InputProps={{
													className: classes.input
												}}
											/>
										)}
										rules={{
											maxLength: {
												message: t('validationErrors.maxLength50'),
												value: 50,
											},
										}}
									/>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box mt={1}>
									<Controller
										name="surname"
										control={control}
										defaultValue={user.get('surname') || ''}
										render={(
											{
												field,
												fieldState: {
													error
												},
											}
										) => (
											<TextField
												{...field}
												variant="outlined"
												fullWidth
												error={!!error}
												label={t('user.surname')}
												helperText={error?.message}
												InputProps={{
													className: classes.input
												}}
											/>
										)}
										rules={{
											maxLength: {
												message: t('validationErrors.maxLength100'),
												value: 100,
											},
										}}
									/>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box mt={1}>
									<Controller
										name="companyDivision"
										control={control}
										defaultValue={user.get('companyDivision') || ''}
										render={(
											{
												field,
												fieldState: {
													error
												},
											}
										) => (
											<TextField
												{...field}
												variant="outlined"
												fullWidth
												error={!!error}
												label={t('user.companyDivision')}
												helperText={error?.message}
												InputProps={{
													className: classes.input
												}}
											/>
										)}
										rules={{
											maxLength: {
												message: t('validationErrors.maxLength100'),
												value: 100,
											},
										}}
									/>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box mt={1}>
									<Controller
										name="phoneNumber"
										control={control}
										defaultValue={user.get('phoneNumber') || ''}
										render={(
											{
												field,
												fieldState: {
													error
												},
											}
										) => (
											<TextField
												{...field}
												variant="outlined"
												fullWidth
												error={!!error}
												label={t('user.phoneNumber')}
												helperText={error?.message}
												InputProps={{
													className: classes.input
												}}
											/>
										)}
										rules={{
											maxLength: {
												message: t('validationErrors.maxLength20'),
												value: 20,
											},
										}}
									/>
								</Box>
							</Grid>
						</Grid>
						{errorMessage ? (
							<Grid item xs={12}>
								<Box className={classes.error}>
									<b>{errorMessage}</b>
								</Box>
							</Grid>
						) : null}
						<Box textAlign='center'>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								size="large"
								className={classes.submit}
							>
								{userId ?t('save') : t('create')}
							</Button>
							{userId && userId !== currentUserId ? (
								<div>
									<Link href="#" onClick={(e) => showConfirmMessage(e)}>{t('user.deleteUser')}</Link>
								</div>
							) : null}
						</Box>
					</form>
				</div>
			</Container>
			<Footer />

			<AlertPopup
				showAlert={showAlert}
				setShowAlert={setShowAlert}
				title={t('user.deleteThisUser')}
				callbackSubmit={() => deleteUser(userId)}
				callbackSubmitText={t('delete')}
			/>
		</>
	);
};

export default connect(
	(state) => ({
		currentUserId: state.get('currentUserId'),
		user: state.get('user'),
		lang: state.get('lang'),
		userRolesSelectsData: state.get('userRolesSelectsData'),
		errorMessage: state.get('errorMessage'),
	}),
	{
		getUserConnect: getUser,
		getUserRolesSelectsDataConnect: getUserRolesSelectsData,
		submitCreateEditUserFormConnect: submitCreateEditUserForm,
		deleteUserConnect: deleteUser,
		setErrorDetailsConnect: setErrorDetails,
	},
)(CreateEditUser);
