import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';

import IncidentsList from './IncidentsList/IncidentsList';
import Header from '../Header';
import Footer from '../Footer';
import CommonPopup from '../Common/CommonPopup';
import IncidentsControls from './IncidentsControls/IncidentsControls';
import IncidentsMap from './Map/IncidentsMap';

import {
	getIncidentsData, getProvinceIncidentCount,
	getSelectsData, setShowIncidentsMap,
} from '../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	leftIndent: {
		marginLeft: theme.spacing(2),
	},
}));

const IncidentsPage = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	let navigate = useNavigate();

	const {
		lang,
		isLoading,
		incidentsData,
		selectsData,
		userRole,
		customizeView,
		getIncidentsDataConnect,
		getSelectsDataConnect,
		getProvinceIncidentCountConnect,
		showIncidentsMap,
		setShowIncidentsMapConnect,
		allIncidentCounts,
	} = props;

	useEffect(() => {
		const searchParams = window.location.search;
		const localStorageFilterParams = localStorage.getItem('filter') || '';
		const requestUrl = `/api/incidents${searchParams ? searchParams : localStorageFilterParams}`;

		getSelectsDataConnect(searchParams ? searchParams : localStorageFilterParams);
		getIncidentsDataConnect(requestUrl);

		if (searchParams) {
			localStorage.setItem('filter', searchParams);
		} else if (localStorageFilterParams) {
			navigate(localStorageFilterParams);
		}
	}, [lang]);

	return (
		<>
			<Header>
				<>
					<Button
						size="large"
						color="primary"
						onClick={() => {
							getProvinceIncidentCountConnect();
						}}
						startIcon={<RoomOutlinedIcon fontSize="medium" />}
					>
						{t('viewMap')}
					</Button>
					{userRole === 'ROLE_SUPER_ADMIN' || userRole === 'ROLE_INCIDENT_MANAGER' ? (
						<Button
							className={classes.leftIndent}
							variant="contained"
							color="primary"
							size="large"
							startIcon={<AddIcon />}
							href="/create-incident"
						>
							{t('incident.createIncident')}
						</Button>
					) : null}
				</>
			</Header>
			<IncidentsControls
				allIncidentCounts={allIncidentCounts}
			/>
			<IncidentsList
				incidentsList={incidentsData.get('list')}
				totalItems={incidentsData.get('totalItems')}
				status={selectsData.get('incidentStatus')}
				customizeView={customizeView}
				userRole={userRole}
				isLoading={isLoading}
			/>
			<Footer />

			<CommonPopup
				showPopup={showIncidentsMap}
				setShowPopup={() => setShowIncidentsMapConnect(false)}
				size={'md'}
			>
				<IncidentsMap />
			</CommonPopup>
		</>
	);
};

export default connect(
	(state) => ({
		lang: state.get('lang'),
		isLoading: state.get('isLoading'),
		incidentsData: state.get('incidentsData'),
		selectsData: state.get('selectsData'),
		userRole: state.get('userRole'),
		customizeView: state.get('customizeView'),
		showIncidentsMap: state.get('showIncidentsMap'),
		allIncidentCounts: state.get('allIncidentCounts'),
	}), {
		getIncidentsDataConnect: getIncidentsData,
		getSelectsDataConnect: getSelectsData,
		getProvinceIncidentCountConnect: getProvinceIncidentCount,
		setShowIncidentsMapConnect: setShowIncidentsMap,
	},
)(IncidentsPage);