export const COLOR_WHITE = '#FFF';
export const COLOR_BLUE = '#3755D7';

export const COLOR_GREEN = '#27D17F';
export const COLOR_YELLOW = '#FFC907';
export const COLOR_ORANGE = '#EA7100';
export const COLOR_RED = '#D90000';

export const COLOR_MAP_CRITICAL = '#BE5451';
export const COLOR_MAP_OPEN = '#3755D7';
export const COLOR_MAP_ONGOING = '#6889FC';
export const COLOR_MAP_CLOSED = '#A2B5FC';