import React, { useState } from 'react';
import { connect } from 'react-redux';
import  { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import DenmarkMap from './DenmarkMap';
import AlertPopup from '../../Common/AlertPopup';

import {
	COLOR_MAP_OPEN,
	COLOR_MAP_ONGOING,
	COLOR_MAP_CLOSED,
	COLOR_MAP_CRITICAL,
} from '../../../utils/colors';

const useStyles = makeStyles((theme) => ({
	mapInner: {
		'& > svg': {
			width: 750,
		},
		'& .cursor-pointer': {
			cursor: 'pointer'
		}
	},
	legend: {
		display: 'flex',
		justifyContent: 'flex-end',
		margin: theme.spacing(0, 4, 2, 0),
		'& > div': {
			marginLeft: theme.spacing(2),
		}
	}
}));

const IncidentsMap = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		provinceIncidentCounts,
	} = props;

	const regionsColors = {
		PR116Color: COLOR_MAP_CLOSED,
		PR117Color: COLOR_MAP_CLOSED,
		PR118Color: COLOR_MAP_CLOSED,
		PR119Color: COLOR_MAP_CLOSED,
		PR120Color: COLOR_MAP_CLOSED,
	};

	const [selectedRegion, setSelectedRegion] = useState('');
	const [showPopup, setShowPopup] = useState(false);

	provinceIncidentCounts.forEach((item) => {
		if (item.get('isCritical')) {
			regionsColors[`${item.get('id')}Color`] = COLOR_MAP_CRITICAL;
		} else if (item.get('open') > 0) {
			regionsColors[`${item.get('id')}Color`] = COLOR_MAP_OPEN;
		} else if (item.get('ongoing') > 0) {
			regionsColors[`${item.get('id')}Color`] = COLOR_MAP_ONGOING;
		}
	});

	return (
		<div className={classes.map}>
			<div className={classes.legend}>
				<div style={{color: COLOR_MAP_OPEN}}><FiberManualRecordIcon style={{ fontSize: 10 }} /> {t('open')}</div>
				<div style={{color: COLOR_MAP_ONGOING}}><FiberManualRecordIcon style={{ fontSize: 10 }} /> {t('inProgress')}</div>
				<div style={{color: COLOR_MAP_CLOSED}}><FiberManualRecordIcon style={{ fontSize: 10 }} /> {t('noIncidents')}</div>
				<div style={{color: COLOR_MAP_CRITICAL}}><FiberManualRecordIcon style={{ fontSize: 10 }} /> {t('critical')}</div>
			</div>
			<div className={classes.mapInner}>
				<DenmarkMap
					PR116Color={regionsColors.PR116Color}
					PR117Color={regionsColors.PR117Color}
					PR118Color={regionsColors.PR118Color}
					PR119Color={regionsColors.PR119Color}
					PR120Color={regionsColors.PR120Color}
					PR116onPressHandler={() => {
						setSelectedRegion('PR116');
						setShowPopup(true);
					}}
					PR117onPressHandler={() => {
						setSelectedRegion('PR117');
						setShowPopup(true);
					}}
					PR118onPressHandler={() => {
						setSelectedRegion('PR118');
						setShowPopup(true);
					}}
					PR119onPressHandler={() => {
						setSelectedRegion('PR119');
						setShowPopup(true);
					}}
					PR120onPressHandler={() => {
						setSelectedRegion('PR120');
						setShowPopup(true);
					}}

				/>
			</div>
			<AlertPopup
				showAlert={showPopup}
				setShowAlert={setShowPopup}
				title={`${provinceIncidentCounts?.get(selectedRegion)?.get('name')} ${t('incidents')}:`}
				hideControls={true}
				content={(
					<>
						<br/>
						<span><FiberManualRecordIcon style={{ fontSize: 10 }} /> <b>{provinceIncidentCounts?.get(selectedRegion)?.get('open')}</b> {t('open')}</span>
						&nbsp; &nbsp; &nbsp; &nbsp;
						<span><FiberManualRecordIcon style={{ fontSize: 10 }} /> <b>{provinceIncidentCounts?.get(selectedRegion)?.get('ongoing')}</b> {t('inProgress')}</span>
					</>
				)}
			/>
		</div>
	);
};

export default connect(
	(state) => ({
		provinceIncidentCounts: state.get('provinceIncidentCounts'),
	})
)(IncidentsMap);