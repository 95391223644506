import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';

import siteLogo from '../../utils/logo';

import {
	setErrorDetails,
	submitPasswordRecoveryForm,
	setSuccessPasswordRecoveryForm,
} from '../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '100vh',
		justifyContent: 'center',
	},
	input: {
		backgroundColor: '#fff',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(15),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
	},
	logo: {
		width: 240,
		marginBottom: theme.spacing(4),
	},
	error: {
		marginTop: theme.spacing(1),
		color: theme.palette.error.main,
		textAlign: 'center',
	},
}));

const PasswordRecovery = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		errorMessage,
		setErrorDetailsConnect,
		setSuccessPasswordRecoveryFormConnect,
		submitPasswordRecoveryFormConnect,
		successPasswordRecoveryForm,
	} = props;

	const {
		control,
		handleSubmit,
	} = useForm();

	const [sendEmail, setSendEmail] = useState('');

	const onSubmit = (data) => {
		setSendEmail(data.email);
		submitPasswordRecoveryFormConnect(data);
	};

	useEffect(() => {
		return () => {
			setErrorDetailsConnect('');
			setSuccessPasswordRecoveryFormConnect(false);
		};
	}, []);

	return (
		<Container maxWidth="xs">
			<div className={classes.paper}>
				<img className={classes.logo} src={siteLogo} alt="TDC Logo"/>
				<form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
					{successPasswordRecoveryForm ? (
						<>
							<Box textAlign={'center'} >
								<Typography variant={'h6'}>{t('auth.instructionHeading')} {sendEmail}</Typography>
								<Box textAlign={'left'}  mt={3} mb={4}>
									<Box mb={1}><b>{t('auth.instructionTitle')}</b></Box>
									<Box mb={1}>{t('auth.instruction1')}</Box>
									<Box mb={1}>{t('auth.instruction2')}</Box>
									<Box mb={1}>{t('auth.instruction3')}</Box>
								</Box>
							</Box>
						</>
					) : (
						<>
							<Box textAlign={'center'}>
								<Typography	variant={'h6'}>{t('auth.resetYourPassword')}</Typography>
								<Box mt={1} mb={1}>{t('auth.enterEmailAddress')}</Box>
							</Box>
							<Controller
								name="email"
								control={control}
								render={(
									{
										field,
										fieldState: {
											error
										},
									}
								) => (
									<TextField
										{...field}
										variant="outlined"
										fullWidth
										error={!!error}
										helperText={error?.message}
										label={`${t('auth.email')} *`}
										margin={'normal'}
										InputProps={{
											className: classes.input
										}}
									/>
								)}
								rules={{
									required: {
										value: true,
										message: t('validationErrors.required'),
									},
									pattern: {
										value: /\S+@\S+\.\S+/,
										message: t('validationErrors.email'),
									}
								}}
							/>
							{errorMessage ? (
								<Grid item xs={12}>
									<Box className={classes.error}>
										<b>{errorMessage}</b>
									</Box>
								</Grid>
							) : null}
							<Box textAlign={'center'}>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									className={classes.submit}
									size="large"
								>
									{t('auth.continue')}
								</Button>
							</Box>
						</>
					)}
					<Box textAlign={'center'}>
						<Link component={RouterLink} to="/login">
							{t('auth.backToLogIn')}
						</Link>
					</Box>
				</form>
			</div>
		</Container>
	);
};

export default connect(
	(state) => ({
		successPasswordRecoveryForm: state.get('successPasswordRecoveryForm'),
		errorMessage: state.get('errorMessage'),
	}),
	{
		setSuccessPasswordRecoveryFormConnect: setSuccessPasswordRecoveryForm,
		submitPasswordRecoveryFormConnect: submitPasswordRecoveryForm,
		setErrorDetailsConnect: setErrorDetails,
	},
)(PasswordRecovery);