import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	block: {
		backgroundColor: '#fff',
		borderRadius: 10,
		padding: theme.spacing(4, 6),
		margin:  theme.spacing(4, 0),
	},
	heading: {
		marginBottom:  theme.spacing(3),
	},
	innerBlock: {
		marginBottom:  theme.spacing(3),
	},
	title: {
		marginBottom:  theme.spacing(1),
	},
	capitalize: {
		textTransform: 'capitalize',
	},
	wordBreak: {
		wordBreak: 'break-word',
	}
}));

const PublicRelations = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		prStatement,
	} = props;

	if (!prStatement) {
		return null;
	}

	return (
		<div className={classes.block}>
			<Typography className={classes.heading} component="h2" variant="h6" color="primary">
				<b>{t('incident.statement')}</b>
			</Typography>
			<Box className={classes.innerBlock}>
				<Box className={classes.title}>{t('incident.statement')}</Box>
				<Box className={classes.wordBreak}>
					<b dangerouslySetInnerHTML={{__html: prStatement.get('text').replace(/(?:\r\n|\r|\n)/g, '<br />')}} />
				</Box>
			</Box>
			<Box>
				<Box className={classes.title}>{t('incident.externalStatementContract')}</Box>
				<Box>
					<b className={classes.capitalize}>{prStatement.get('externalPrContract')}</b>
				</Box>
			</Box>
		</div>
	);
};

export default PublicRelations;