import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import Typography from '@material-ui/core/Typography';

import {
	formatDate,
} from '../../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	block: {
		backgroundColor: '#fff',
		borderRadius: 10,
		padding: theme.spacing(4, 6),
		margin:  theme.spacing(4, 0),
	},
	heading: {
		marginBottom:  theme.spacing(3),
	},
	listItem: {
		fontSize: 15,
		marginBottom:  theme.spacing(3),
		lineHeight: 1.7,
		wordBreak: 'break-word',
	},
	icon: {
		verticalAlign: 'bottom',
		transform: 'scale(0.5)',
		color: 'rgb(0, 0, 120)'
	},
	date: {
		marginLeft: theme.spacing(1),
	},
	gray: {
		color: '#bbb',
	},
	innerBlock: {
		marginBottom:  theme.spacing(3),
	},
	contextBox: {
		border: '1px solid black',
		height: '400px',
		marginLeft: theme.spacing(1),
	}
}));

const EventsLog = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		eventsLog,
		scheduledNotificationTime,
		percentageResolved,
		escalationType
	} = props;

	/* The 'showEventsLog' variable is necessary for old incidents without 'incidentDataSnapshot' array */
	const [showEventsLog, setShowEventsLog] = useState(false);
	const [initialUpdate, setInitialUpdate] = useState({
		timeCreated : "",
		incidentEventCommentGroupDescription : {
			actionsTaken : "",
			description : ""
		}
	});

	const [latestUpdates, setLatestUpdates] = useState([]);

	if (!showEventsLog) {
		eventsLog?.forEach((item) => {
			if(item.getIn(['incidentEventCommentGroupDescription', 'description'])) {
				setShowEventsLog(true);
			}
		});
	}

	const getInitialUpate = (data) => {

		if(data.length != 0) {
			setInitialUpdate(data[0]);
		}

	}

	const getLatestUpdtates = (data) => {

		let list = [];
		if(data.length != 0) {

			for(let i = 0; i < data.length - 1; i++) {
				let item = data[i];
				list.push(item);
			}

			setLatestUpdates(list);
		}

	}

	useEffect(() => {
		//console.log(eventsLog.toJS());
		getInitialUpate(eventsLog.toJS());
		getLatestUpdtates(eventsLog.toJS().reverse());
	},[eventsLog]);


	if (!eventsLog || (eventsLog && !showEventsLog)) {
		return null;
	}

	return (
		<div className={classes.block}>
			<Grid container spacing={1}>
				<Typography className={classes.heading} component="h2" variant="h6" color="primary">
					<b>{t('incident.statusUpdates')}</b>
				</Typography>
			</Grid>
			<Grid container spacing={1}>
				<Grid xs={12} sm={6}>
					<Box className={classes.innerBlock}>
						<Box className={classes.title}><b>{t('incident.percentResolved2')}</b></Box>
						<Box className={classes.wordBreak}>
							{percentageResolved + " %"}
						</Box>
					</Box>
				</Grid>
				<Grid xs={12} sm={6}>
					<Box>
						<Box className={classes.title}><b>{t('incident.escalationType')}</b></Box>
						<Box className={classes.wordBreak}>
							{(percentageResolved == 100) ? "--" : escalationType}
						</Box>
					</Box>
				</Grid>
			</Grid>
			{
				/* new p2 changes start */
			}
			<Grid container spacing={1}>
				<Grid xs={12} sm={6}>
					<Box className={classes.innerBlock}>
						<Box className={classes.title}><b>{t('incident.initialUpdate')}</b></Box>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Box className={classes.innerBlock} style={{width: '100%'}}>
					<Grid xs={12} sm={12}>
						<Brightness1Icon  className={classes.icon} /> <span className={classes.date}>{ formatDate(initialUpdate['timeCreated']) }</span>
						<Grid container spacing={1} style={{ marginLeft: '11px', marginTop: '2px' }}>
							<Grid xs={1} sm={1} style={{ borderLeft: '1px solid rgb(89 89 243)', flexBasis: '4%' }}>
							</Grid>
							<Grid xs={11} sm={11} style={{borderTop: '1px solid #bbb'}}>
								<Grid container spacing={1}>
									<Grid xs={12} sm={12} item>
										<Box className={classes.innerBlock} mt={3}>
											<Box className={classes.title}><b>{t('incident.actionTaken')}</b></Box>
											<Box className={classes.wordBreak}>
												{(initialUpdate['incidentEventCommentGroupDescription']) ? initialUpdate['incidentEventCommentGroupDescription']['actionsTaken'] : ""}
											</Box>
										</Box>
									</Grid>
								</Grid>
								<Grid container spacing={1}>
									<Grid xs={12} sm={12} item>
										<Box className={classes.innerBlock} mt={3}>
											<Box className={classes.title}><b>{t('incident.description')}</b></Box>
											<Box className={classes.wordBreak}>
												{(initialUpdate['incidentEventCommentGroupDescription']) ? initialUpdate['incidentEventCommentGroupDescription']['description'] : ""}
											</Box>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<hr/>
			<Grid container spacing={1}>
				<Grid xs={12} sm={6}>
					<Box mt={3}>
						<Box className={classes.title}><b>{t('incident.latestUpdate')}</b></Box>
					</Box>
				</Grid>
				<Grid xs={12} sm={12}>
					<Box>
						<Box className={classes.title} style={{textAlign: 'center'}}>{t('incident.nextNotificationWithStatusUpdate')}</Box>
					</Box>
				</Grid>
				<Grid xs={12} sm={12}>
					<Box className={classes.innerBlock} mt={2}>
						<Box className={classes.title} style={{textAlign: 'center'}}><b>{ (percentageResolved == 100) ? "--" : formatDate(scheduledNotificationTime) }</b></Box>
					</Box>
				</Grid>
			</Grid>
			{
				latestUpdates.map((item, index) => {
					return (
						(item['incidentEventCommentGroupDescription']['actionsTaken'] != undefined && item['incidentEventCommentGroupDescription']['description'] != undefined) ?
						<Grid container spacing={1} key={item['timeCreated']}>
							<Box className={classes.innerBlock} style={{width: '100%'}}>
								<Grid xs={12} sm={12}>
									<Brightness1Icon  className={classes.icon} /> <span className={classes.date}>{ formatDate(item['timeCreated']) }</span>
									<Grid container spacing={1} style={{ marginLeft: '11px', marginTop: '2px' }}>
										<Grid xs={1} sm={1} style={{ borderLeft: '1px solid rgb(89 89 243)', flexBasis: '4%' }}>
										</Grid>
										<Grid xs={11} sm={11} style={{borderTop: '1px solid #bbb'}}>
											<Grid container spacing={1}>
												<Grid xs={12} sm={12}>
													<Box className={classes.innerBlock} mt={3}>
														<Box className={classes.title}><b>{t('incident.actionTaken')}</b></Box>
														<Box className={classes.wordBreak}>
															{(item['incidentEventCommentGroupDescription']) ? item['incidentEventCommentGroupDescription']['actionsTaken'] : "--"}
														</Box>
													</Box>
												</Grid>
											</Grid>
											<Grid container spacing={1}>
												<Grid xs={12} sm={12}>
													<Box className={classes.innerBlock} mt={3}>
														<Box className={classes.title}><b>{t('incident.description')}</b></Box>
														<Box className={classes.wordBreak}>
															{(item['incidentEventCommentGroupDescription']) ? item['incidentEventCommentGroupDescription']['description'] : "--"}
														</Box>
													</Box>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Box>
						</Grid> :
						<></>
					)
				})
			}
			{
				/* new p2 changes ends */
			}
		</div>
	);
};

export default EventsLog;