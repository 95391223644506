import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

const useStyles = makeStyles((theme) => ({
	pagination: {
		marginTop: theme.spacing(4),
	}
}));

const CommonPagination = (props) => {
	const classes = useStyles();

	const {
		totalItems,
		itemsOnPage,
		initialUrl,
	} = props;

	const query = new URLSearchParams(window.location.search);
	const page = parseInt(query.get('page') || '1', 10);

	if (query.get('page')) {
		query.delete('page');
	}

	const filterParams = query.toString();

	return (
		<Pagination
			className={classes.pagination}
			page={page}
			count={Math.ceil(totalItems/itemsOnPage)}
			variant="outlined" shape="rounded"
			showFirstButton
			showLastButton
			renderItem={(item) => (
				<PaginationItem
					component={Link}
					href={`${initialUrl}?${filterParams}${filterParams ? '&' : ''}page=${item.page}`}
					{...item}
				/>
			)}
		/>
	);
};

export default CommonPagination;