import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';
import { getTypeText } from '../../../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	filterTitle: {
		fontSize: 15,
		fontWeight: 700,
		paddingBottom: theme.spacing(1.5),
		paddingTop: theme.spacing(1.5),
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	filterList: {
		marginTop: theme.spacing(-0.5),
		marginBottom: theme.spacing(1.5),
	},
	filterItem: {
		borderBottom: '1px solid #ccc',
	},
	filterControl: {
		fontSize: 12,
		textAlign: 'right',
	},
	filterChild: {
		marginLeft: theme.spacing(3),
	},
	disabledItem: {
		pointerEvents: 'none',
		opacity: 0.5
	}
}));
// ... other imports ...

const FilterItem = (props) => {
	const classes = useStyles();
  
	const { t } = useTranslation();
  
	const {
	  filter,
	  i,
	  handleCheckAll,
	  handleChange,
	  userRole
	} = props;
  
	const [isOpenFilter, setIsOpenFilter] = useState(false);
  
	const toggleFilter = () => {
	  setIsOpenFilter(!isOpenFilter);
	};
  
	// Define constants for the fixed service type and Denmark location
	const FIXED_SERVICE_TYPE = "Fixed";
	const DENMARK_LOCATION = "Denmark";
	const isServiceType1 = i === "serviceType";
	const isLocation1 = i === "location";
	return (
	  <div className={classes.filterItem}>
		<Typography
		  className={classes.filterTitle}
		  variant="h6"
		  onClick={toggleFilter}
		>
		  {t(`filter.${i}`)} {isOpenFilter ? <ExpandLessIcon color={'primary'} /> : <ExpandMoreIcon color={'primary'} />}
		</Typography>
		{isOpenFilter ? (
		  <div className={classes.filterList}>
			{filter.get(i)?.map((item) => {
			  const isServiceType = i === "serviceType";
			  const isLocation = i === "location";
			  const isFixedServiceType = isServiceType && userRole === "ROLE_EWII_USER" && item.get('label') === FIXED_SERVICE_TYPE;
			  const isDenmarkLocation = isLocation && userRole === "ROLE_EWII_USER" && item.get('label') === DENMARK_LOCATION;
  
			  // Apply the condition only for ROLE_EWII_USER
			  if (userRole === "ROLE_EWII_USER" && ((isServiceType && !isFixedServiceType) || (isLocation && !isDenmarkLocation))) {
				return null;
			  }
  
			  return (
				<div key={item.get('value')} className={
				  [
					item.get('hasParent') ? classes.filterChild : '', 
					(isFixedServiceType || isDenmarkLocation) ? classes.disabledItem : ''
				  ].join(" ")
				}>
				  <FormControlLabel
					control={
					  <Checkbox
						value={item.get('value')}
						name={`${i}[]`}
						color="primary"
						checked={isFixedServiceType || isDenmarkLocation || item.get('checked')}
						disabled={isFixedServiceType || isDenmarkLocation}
					  />
					}
					label={item.get('label')}
					onChange={() => handleChange({i, item})}
				  />
				</div>
			  );
			})}
			{
			 (userRole === "ROLE_EWII_USER" && (isServiceType1 || isLocation1)) ? 
				 null : 
			  <div className={classes.filterControl}>
				<Link
				  href="#"
				  onClick={(e) => {
					handleCheckAll({
					  e: e,
					  type: i,
					  value: true,
					});
				  }}
				>
				  {t('filters.selectAll')}
				</Link>
				{' | '}
				<Link
				  href="#"
				  onClick={(e) => {
					handleCheckAll({
					  e: e,
					  type: i,
					  value: false,
					});
				  }}
				>
				  {t('filters.clearAll')}
				</Link>
			  </div> 
			}
		  </div>
		) : null}
	  </div>
	);
  };
  
  export default FilterItem;
   