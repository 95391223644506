import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import TableHead from '@material-ui/core/TableHead';
import EditIcon from '@material-ui/icons/Edit';

import CommonPagination from '../../Common/CommonPagination';

const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: '100%',
		marginTop: theme.spacing(8),

		[theme.breakpoints.up('lg')]: {
			maxWidth: '95%',
		},
	},
	scroll: {
		'-webkit-overflow-scrolling: touch': 'touch',
		'&::-webkit-scrollbar': {
			height: 12
		},
		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.1)',
			borderRadius: 2
		},
		'&::-webkit-scrollbar-thumb:window-inactive': {
			backgroundColor: 'rgba(0,0,0,.1)',
		},
	},
	iconButton: {
		border: '1px solid',
	},
	table: {
		minWidth: 650,
	},
	th: {
		color: '#aaa',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		textTransform: 'uppercase',
		fontSize: 12,
		lineHeight: 1.4,
	},
	tableBody: {
		'& td': {
			fontSize: 13,
		}
	},
}));

const StatusUpdatesList = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		customersList,
		totalItems,
		lang,
		langList,
	} = props;

	if (!customersList) {
		return null;
	}

	return (
		<Container className={classes.container} maxWidth={false}>
			<TableContainer className={classes.scroll} component={Paper}>
				<Table className={classes.table} size="small">
					<TableHead>
						<TableRow>
							<TableCell className={classes.th}>{t('incident.title')} {lang === langList.english ? '(English)' : '(Danish)'}</TableCell>
							<TableCell className={classes.th}>{t('incident.description')} {lang === langList.english ? '(English)' : '(Danish)'}</TableCell>
							<TableCell className={classes.th}>{t('incident.title')} {lang === langList.english ? '(Danish)' : '(English)'}</TableCell>
							<TableCell className={classes.th}>{t('incident.description')} {lang === langList.english ? '(Danish)' : '(English)'}</TableCell>
							<TableCell className={classes.th} />
						</TableRow>
					</TableHead>
					<TableBody className={classes.tableBody}>
						{customersList.map((item) => (
							<TableRow key={item.get('id')}>
								<TableCell>
									<Box>
										<Link href={`edit-status-update/${item.get('id')}`}>
											{item.get('title')}
										</Link>
									</Box>
								</TableCell>
								<TableCell>
									<Box>
										{item.get('incidentEventCommentGroupDescriptions').get(0)?.get('description')}
									</Box>
								</TableCell>
								<TableCell>
									<Box>
										{item.get('translations')?.get(0)?.get('value')}
									</Box>
								</TableCell>
								<TableCell>
									<Box>
										{item.get('incidentEventCommentGroupDescriptions').get(0)?.get('translations')?.get(0)?.get('value')}
									</Box>
								</TableCell>
								<TableCell align="right">
									<IconButton className={classes.iconButton} color="primary" size="small" href={`edit-status-update/${item.get('id')}`}>
										<EditIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<CommonPagination
				initialUrl={'/customers'}
				totalItems={totalItems}
				itemsOnPage={30}
			/>
		</Container>
	);
};

export default StatusUpdatesList;
