import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css'
import './LocationSelect.css';

const LocationSelect = (props) => {
	const { t } = useTranslation();

	const {
		continents,
		setLocationStr,
		defaultCountries,
		defaultProvinces,
		defaultMunicipalities,
		defaultCities,
	} = props;

	const [data, setData] = useState([]);
	useEffect(() => {
		if (continents.size > 0) {
			const initialData = continents.toJS().map((continent) => ({
				label: continent.name,
				value: continent['@id'],
				expanded: true,
				children: continent.countries.map((country) => ({
					label: country.name,
					value: country['@id'],
					className: country.isEmulatedAsBool ? 'isEmulatedLocation' : null,
					children: country.provinces.map((province) => ({
						label: province.name,
						value: province['@id'],
						className: province.isHiddenAsBool ? 'isEmulatedLocation' : null,
						children: province.municipalities.map((municipality) => ({
							label: municipality.name,
							value: municipality['@id'],
							children: municipality.cities.map((city) => ({
								label: city.name,
								value: city['@id'],
							}))
						}))
					}))
				}))
			}));

			setData(initialData);
		}
	}, [continents.size]);

	useEffect(() => {
		if ((defaultCountries || defaultProvinces || defaultMunicipalities || defaultCities) && data.length) {
			const defaultCountriesLocal = defaultCountries.split('|');
			const defaultProvincesLocal = defaultProvinces.split('|');
			const defaultMunicipalitiesLocal = defaultMunicipalities.split('|');
			const defaultCitiesLocal = defaultCities.split('|');

			setLocationStr(defaultCountries.concat('|', defaultProvinces).concat('|', defaultMunicipalities).concat('|', defaultCities));

			const isCheckedItem = (defaultArr, currentObject) => defaultArr.some((item) => item === currentObject.value);

			setData((dataLocal) => (
				dataLocal.map((continent) => ({
					...continent,
					children: continent && continent.children.map((country) => {
						const isCountryChecked = isCheckedItem(defaultCountriesLocal, country);
						return ({
							...country,
							checked: isCountryChecked,
							children: country && country.children.map((province) => {
								const isProvinceChecked = isCheckedItem(defaultProvincesLocal, province);
								return ({
									...province,
									checked: isCountryChecked || isProvinceChecked,
									children: province && province.children.map((municipality) => {
										const isMunicipalityChecked = isCheckedItem(defaultMunicipalitiesLocal, municipality);
										return ({
											...municipality,
											checked: isCountryChecked || isProvinceChecked || isMunicipalityChecked,
											children: municipality && municipality.children.map((city) => {
												const isCityChecked = isCheckedItem(defaultCitiesLocal, city);
												return ({
													...city,
													checked: isCountryChecked || isProvinceChecked || isMunicipalityChecked || isCityChecked || defaultCitiesLocal.some((defaultCityProp) => defaultCityProp === city.value),
												})
											})
										})
									})
								})
							})
						})
					})
				}))));
		}
	}, [defaultCities, data.length]);

	const onChange = (currentNode, selectedNodes) => {
		const locationStrLocal = selectedNodes.map((i) => i.value).join('|');

		setLocationStr(locationStrLocal);
	};

	if (!data.length) {
		return null;
	}

	return (
		<DropdownTreeSelect
			data={data}
			keepTreeOnSearch
			showPartiallySelected
			texts={{
				placeholder: t('incident.location') + ' *'
			}}
			onChange={onChange}
			className="mdl-demo"
		/>
	);
};

export default memo(LocationSelect);
