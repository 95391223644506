import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';

import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
	iconButton: {
		border: '1px solid',
		borderRadius: 4,
		marginLeft: theme.spacing(2),
		padding: theme.spacing(1),
	},
}));

const Hamburger = () => {
	const classes = useStyles();

	const { t } = useTranslation();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton className={classes.iconButton} color="primary" size="medium" aria-haspopup="true" onClick={(e) => handleClick(e)}>
				<MenuIcon />
			</IconButton>
			<Menu
				id="hamburger-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem component={Link} href={'/incidents'}>{t('dashboard')}</MenuItem>
				<MenuItem component={Link} href={'/users'}>{t('users')}</MenuItem>
				<MenuItem component={Link} href={'/services'}>{t('services')}</MenuItem>
				{/*<MenuItem component={Link} href={'/customers'}>{t('customers')}</MenuItem>*/}
				<MenuItem component={Link} href={'/status-updates'}>{t('statusUpdates')}</MenuItem>
			</Menu>
		</>
	);
};

export default Hamburger;