import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
	expand: {
		fontSize: 16,
		verticalAlign: 'text-top',
	},
}));

const CommonExpand = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		item,
		value = 'name',
		visibleItems = 4,
	} = props;

	const [expanded, setExpanded] = useState(false);

	const handleShowMore = (event) => {
		event.preventDefault();
		setExpanded(!expanded);
	};

	return (
		<>
			<Box>
				{item.map((i, index) => expanded ? (
					<span key={index}>{index ? ' | ' : null}{i.get(value)}</span>
				) : (
					index < visibleItems ? <span key={index}>{index ? ' | ' : null}{i.get(value)}</span> : null
				))}
				{' '}
				{item.size > visibleItems ? <Link href="#" onClick={handleShowMore}>{expanded ? t('less') : t('more')} {expanded ? <ExpandLessIcon className={classes.expand} /> : <ExpandMoreIcon className={classes.expand} />}</Link> : null}
			</Box>
		</>
	);
};

export default CommonExpand;
