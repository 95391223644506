import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';

import { submitPrStatementForm, setErrorDetails } from '../../../utils/ducks';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	input: {
		backgroundColor: '#fff',
		'& > textarea': {
			resize: 'vertical',
		}
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(0, 0, 2),
		paddingLeft: theme.spacing(8),
		paddingRight: theme.spacing(8),
	},
	error: {
		marginBottom: theme.spacing(3),
		color: '#27D17F',
		textAlign: 'center',
	},
}));

const PublicRelationsForm = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		prStatement,
		itemId,
		errorMessage,
		setErrorDetailsConnect,
		lang,
	} = props;

	const {
		handleSubmit,
		formState: { errors },
		control,
		trigger,
	} = useForm();

	useEffect(() => {
		return () => {
			setErrorDetailsConnect('')
		};
	}, []);

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			trigger();
		}
	}, [lang]);

	const onSubmit = (data) => {
		const {	submitPrStatementFormConnect } = props;

		submitPrStatementFormConnect({
			formData: {
				...data,
			},
			id: itemId,
			edit: !!prStatement,
			t: t,
		});
	};

	return (
		<div className={classes.paper}>
			<form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
				<Controller
					name="text"
					control={control}
					defaultValue={prStatement?.get('text')|| ''}
					render={(
						{
							field,
							fieldState: {
								error
							},
						}
					) => (
						<TextField
							{...field}
							autoFocus
							variant="outlined"
							fullWidth
							error={!!error}
							label={`${t('incident.statement')} *`}
							margin="normal"
							rows={6}
							multiline
							helperText={error?.message}
							InputProps={{
								className: classes.input
							}}
						/>
					)}
					rules={{
						required: {
							value: true,
							message: t('validationErrors.required'),
						},
					}}
				/>
				<FormControl  component="fieldset" margin={'normal'}>
					<Box mb={1}>
						<FormLabel component="legend">{t('incident.externalStatementContract')}</FormLabel>
					</Box>
					<Controller
						rules={{ required: true }}
						control={control}
						name="externalPrContract"
						defaultValue={prStatement?.get('externalPrContract') || 'no'}
						render={({ field }) => (
							<RadioGroup
								{...field}
								row
								aria-label="pr"
							>
								<FormControlLabel
									value="yes"
									control={<Radio color="primary" />}
									label={t('yes')}
								/>
								<FormControlLabel
									value="no"
									control={<Radio color="primary" />}
									label={t('no')}
								/>
							</RadioGroup>
						)}
					/>
				</FormControl>
				{errorMessage ? (
					<Grid item xs={12}>
						<Box className={classes.error}>
							<b>{errorMessage}</b>
						</Box>
					</Grid>
				) : null}
				<Box textAlign='center'>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						size="large"
						className={classes.submit}
					>
						{prStatement?.get('text') ? t('save') : t('create')}
					</Button>
				</Box>
			</form>
		</div>
	);
};

export default connect(
	(state) => ({
		lang: state.get('lang'),
		errorMessage: state.get('errorMessage'),
	}),
	{
		submitPrStatementFormConnect: submitPrStatementForm,
		setErrorDetailsConnect: setErrorDetails,
	},
)(PublicRelationsForm);