import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import Header from '../Header';
import Footer from '../Footer';
import CustomersList from './CustomersList/CustomersList';

import {
	getCustomersData,
	EN, DA,
} from '../../utils/ducks';

const CustomersPage = (props) => {
	const { t } = useTranslation();

	const {
		lang,
		customersData,
		getCustomersDataConnect,
	} = props;

	useEffect(() => {
		const requestUrl = `/api/customers${window.location.search}`;

		getCustomersDataConnect(requestUrl);
	}, [lang]);

	return (
		<>
			<Header>
				<Button
					variant="contained"
					color="primary"
					size="large"
					startIcon={<AddIcon />}
					href="/create-customer"
				>
					{t('create')}
				</Button>
			</Header>
			<CustomersList
				customersList={customersData.get('list')}
				totalItems={customersData.get('totalItems')}
				lang={lang}
				langList={{
					english: EN,
					danish: DA,
				}}
			/>
			<Footer />
		</>
	);
};

export default connect(
	(state) => ({
		customersData: state.get('customersData'),
		lang: state.get('lang'),
	}), {
		getCustomersDataConnect: getCustomersData,
	},
)(CustomersPage);