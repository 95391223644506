import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getIncidentsCount } from '../../../utils/ducks';
import  { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	countWrap: {
		marginLeft: theme.spacing(2),

		[theme.breakpoints.down('md')]: {
			marginBottom: theme.spacing(1),
		},
	},
    countSpacing : {
        marginRight: theme.spacing(2)
    }
}));

const IncidentCount = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const {
		countsData,
		getIncidentsCountConnect,
	} = props;

    useEffect(() => {
		const requestUrl = `/api/incidents/count`;

		getIncidentsCountConnect(requestUrl);
	}, [getIncidentsCountConnect]);

    // useEffect(() => {
    //     console.log("countsData Open----> ", countsData.get('open'));
    //     console.log("countsData Ongoing----> ", countsData.get('ongoing'));
    // },[countsData]);

    return (
        <div className={classes.countWrap}>
            <div><b>{t("totalIncident")}</b></div>
            <div>
                <span className={classes.countSpacing}>{t('open')} <b>{ countsData.get('open') }</b></span>
                <span>{t('ongoing')} <b>{ countsData.get('ongoing') }</b></span>
            </div>
        </div>
    );
}

export default connect(
	(state) => ({
		countsData: state.get('countsData'),
	}), {
		getIncidentsCountConnect: getIncidentsCount,
	},
)(IncidentCount);