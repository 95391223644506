import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import siteLogo from '../utils/logo';

const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: '100%',

		[theme.breakpoints.up('lg')]: {
			maxWidth: '95%',
		},
	},
	footer: {
		padding: theme.spacing(8, 0, 3),
	},
	inner: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	logo: {
		width: 140
	}
}));

const Footer = () => {
	const classes = useStyles();

	return (
		<footer className={classes.footer}>
			<Container className={classes.container} maxWidth={false}>
				<Box className={classes.inner}>
					<Link href="/" styles={{display: 'flex'}}>
						<img className={classes.logo} src={siteLogo} alt="TDC Logo"/>
					</Link>
					<Box>Teglholmsgade 1, 0900 København C</Box>
				</Box>
			</Container>
		</footer>
	);
};

export default Footer;
