import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import siteLogo from '../../utils/logo';
import { setErrorDetails, submitPasswordResetForm } from '../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '100vh',
		justifyContent: 'center',
	},
	input: {
		backgroundColor: '#fff',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(15),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
	},
	logo: {
		width: 240,
		marginBottom: theme.spacing(4),
	},
	error: {
		marginTop: theme.spacing(1),
		color: theme.palette.error.main,
		textAlign: 'center',
	},
}));

const PasswordReset = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		statusPasswordResetForm,
		errorMessage,
		submitPasswordResetFormConnect,
		setErrorDetailsConnect,
	} = props;

	const {
		control,
		handleSubmit,
		getValues,
	} = useForm();

	const [showPassword, setShowPassword] = useState(false);
	const [showPassword2, setShowPassword2] = useState(false);

	const query = new URLSearchParams(window.location.search);

	const onSubmit = (data) => {
		submitPasswordResetFormConnect({
			plainPassword: data.plainPassword,
			token: query.get('token'),
		});
	};

	useEffect(() => {
		return () => {
			setErrorDetailsConnect('')
		};
	}, []);

	return (
		<Container maxWidth="xs">
			<div className={classes.paper}>
				<img className={classes.logo} src={siteLogo} alt="TDC Logo"/>
				<form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
					{statusPasswordResetForm ? (
						<>
							{statusPasswordResetForm === 'success' ? (
								<>
									<Box textAlign={'center'} mb={4}>
										<Typography	variant={'h6'}>{t('auth.resetSuccess')}</Typography>
									</Box>
								</>
							) : (
								<>
									<Box textAlign={'center'} mb={4}>
										<Typography	variant={'h6'}>{t('auth.resetError')}</Typography>
									</Box>
								</>
							)}
							<Box textAlign={'center'}>
								<Link component={RouterLink} to="/login">
									{t('auth.backToLogIn')}
								</Link>
							</Box>
						</>
					) : (
						<>
							<Box textAlign={'center'} mb={2}>
								<Typography	variant={'h6'}>{t('auth.updatePassword')}</Typography>
							</Box>
							<Controller
								name="plainPassword"
								control={control}
								render={(
									{
										field,
										fieldState: {
											error
										},
									}
								) => (
									<TextField
										{...field}
										variant="outlined"
										fullWidth
										error={!!error}
										helperText={error?.message}
										label={`${t('auth.enterNewPassword')} *`}
										margin={'normal'}
										InputProps={{
											className: classes.input,
											type: (showPassword ? 'text' : 'password'),
											endAdornment: (
												<InputAdornment position={'start'}>
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword(!showPassword)}
														edge="end"
													>
														{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								)}
								rules={{
									required: {
										value: true,
										message: t('validationErrors.required'),
									}
								}}
							/>
							<Controller
								name="plainPasswordConfirm"
								control={control}
								render={(
									{
										field,
										fieldState: {
											error
										},
									}
								) => (
									<TextField
										{...field}
										variant="outlined"
										fullWidth
										error={!!error}
										helperText={error?.message}
										label={`${t('auth.repeatNewPassword')} *`}
										margin={'normal'}
										InputProps={{
											className: classes.input,
											type: (showPassword2 ? 'text' : 'password'),
											endAdornment: (
												<InputAdornment position={'start'}>
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword2(!showPassword2)}
														edge="end"
													>
														{showPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								)}
								rules={{
									required: {
										value: true,
										message: t('validationErrors.required'),
									},
									validate: value => (value === getValues().plainPassword) || t('validationErrors.passwordsNotMatch'),
								}}
							/>
							{errorMessage ? (
								<Grid item xs={12}>
									<Box className={classes.error}>
										<b>{errorMessage}</b>
									</Box>
								</Grid>
							) : null}
							<Box textAlign={'center'}>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									className={classes.submit}
									size="large"
								>
									{t('auth.update')}
								</Button>
							</Box>
						</>
					)}
				</form>
			</div>
		</Container>
	);
};

export default connect(
	(state) => ({
		statusPasswordResetForm: state.get('statusPasswordResetForm'),
		errorMessage: state.get('errorMessage'),
	}),
	{
		submitPasswordResetFormConnect: submitPasswordResetForm,
		setErrorDetailsConnect: setErrorDetails,
	},
)(PasswordReset);