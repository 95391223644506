import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import store from './store/store'
import App from './App';

import {
	COLOR_BLUE,
} from './utils/colors';

import { ThemeProvider, createTheme  } from '@material-ui/core/styles';

const theme = createTheme ({
	overrides: {
		MuiButton: {
			root: {
				textTransform: 'none'
			},
		},
		MuiCheckbox: {
			colorSecondary: {
				'&$checked': {
					color: COLOR_BLUE,
				},
			}
		}
	},
	palette: {
		primary: {
			main: COLOR_BLUE,
		},
	},
});

render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ThemeProvider>
	</Provider>,
	document.getElementById('root')
);