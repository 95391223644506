import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';

const useStyles = makeStyles((theme) => ({
	heading: {
		marginBottom:  theme.spacing(1),
	},
}));

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 300,
		padding: 10,
		fontSize: theme.typography.pxToRem(14),
		border: '1px solid #ddd',
		top: -10
	},
	arrow: {
		'&::before': {
			backgroundColor: '#f5f5f9',
			border: '1px solid #ddd',
		}
	}
}))(Tooltip);

const PrTooltip = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		prStatement,
	} = props;

	const [open, setOpen] = useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<HtmlTooltip
				open={open}
				placement={'bottom-end'}
				interactive={true}
				arrow={true}
				classes={{
					arrow: classes.arrow
				}}
				title={
					<React.Fragment>
						<Typography className={classes.heading}><b>{t('incident.statement')}</b></Typography>
						<div dangerouslySetInnerHTML={{__html: prStatement.get('text').replace(/(?:\r\n|\r|\n)/g, '<br />')}} />
					</React.Fragment>
				}
			>
				<IconButton color="primary" onClick={handleTooltipOpen}>
					<AssignmentOutlinedIcon />
				</IconButton>
			</HtmlTooltip>
		</ClickAwayListener>
	);
};

export default PrTooltip;
