import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import DateRangeIcon from '@material-ui/icons/DateRange';

import {
	DatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import daLocale from 'date-fns/locale/da';
import enLocale from 'date-fns/locale/en-US';

import {
	applyFilter,
	setDateFilterAfter,
	setDateFilterBefore,
	setDateFilterType,
	EN,
} from '../../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	dateFilterWrap: {
		marginRight: theme.spacing(2),

		[theme.breakpoints.down('md')]: {
			marginBottom: theme.spacing(1),
		},
	},
	filterLi: {
		outline: 'none',
	},
	filterContent: {
		padding: theme.spacing(2, 2, 1),
		minWidth: 200,
	},
	button: {
		marginRight: theme.spacing(0.25),
		marginBottom: theme.spacing(0.25),
	},
}));

const DateFilter = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	let navigate = useNavigate();

	const {
		lang,
		timeType,
		setDateFilterBeforeConnect,
		setDateFilterAfterConnect,
		setDateFilterTypeConnect,
		applyFilterConnect,
	} = props;

	const {
		getValues,
		formState: { errors },
		control,
		handleSubmit,
		trigger,
	} = useForm();

	const query = new URLSearchParams(window.location.search);

	const queryDt = query.get('dt') || 'all';
	const queryBefore = query.get('timeStart[before]');
	const queryAfter = query.get('timeStart[after]');

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setDateFilterBeforeConnect(queryBefore);
		setDateFilterAfterConnect(queryAfter);
		setDateFilterTypeConnect(queryDt);
	}, [query]);

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			trigger();
		}
	}, [lang]);

	const todayDate = new Date();
	const currentYear = todayDate.getFullYear();
	const currentMonth = todayDate.getMonth() + 1;
	const currentWeek = new Date(todayDate.setDate(todayDate.getDate() - todayDate.getDay() + 1)).toISOString().slice(0, 10);

	const filterByYear = `${currentYear}-01-01`;
	const filterByMonth = `${currentYear}-${currentMonth < 10 ? `0${currentMonth}` : currentMonth}-01`;
	const filterByWeek = `${currentWeek}`;

	const dateDecorator = (value) => value && typeof(value) === 'object' ? value.toISOString().slice(0, 10) : value ? value.slice(0, 10) : null;

	const onSubmit = (data) => {
		const startDate = dateDecorator(data.startDate);
		const endDate = dateDecorator(data.endDate);

		if (startDate || endDate) {
			setDateFilterTypeConnect('range');

			checkDateFilter({
				timeAfter: startDate || '',
				timeBefore: endDate || '',
				timeType: 'range',
			});
		} else {
			setDateFilterTypeConnect('all');
			checkDateFilter({
				timeBefore: '',
				timeAfter: '',
				redirectCallback: navigate,
				timeType: 'all',
			})
		}

		handleClose();
	};

	const checkDateFilter = (payload) => {
		const {
			timeAfter,
			timeBefore,
			timeType,
		} = payload;

		applyFilterConnect({
			redirectCallback: navigate,
			formData: {
				timeAfter: timeAfter,
				timeBefore: timeBefore,
				timeType: timeType,
			},
		});
	};

	return (
		<div className={classes.dateFilterWrap}>
			<Button
				className={classes.button}
				size="large"
				color="primary"
				variant={timeType === 'all' ? 'contained' : 'outlined'}
				onClick={() => {
					setDateFilterTypeConnect('all');
					checkDateFilter({
						timeBefore: '',
						timeAfter: '',
						redirectCallback: navigate,
						timeType: 'all',
					})
				}}
			>
				{t('filters.all')}
			</Button>
			<Button
				className={classes.button}
				size="large"
				color="primary"
				variant={timeType === 'year' ? 'contained' : 'outlined'}
				onClick={() => {
					setDateFilterTypeConnect('year');
					checkDateFilter({
						timeBefore: '',
						timeAfter: filterByYear,
						redirectCallback: navigate,
						timeType: 'year',
					});
				}}
			>
				{t('filters.year')}
			</Button>
			<Button
				className={classes.button}
				size="large"
				color="primary"
				variant={timeType === 'month' ? 'contained' : 'outlined'}
				onClick={() => {
					setDateFilterTypeConnect('month');
					checkDateFilter({
						timeBefore: '',
						timeAfter: filterByMonth,
						redirectCallback: navigate,
						timeType: 'month',
					});
				}}
			>
				{t('filters.month')}
			</Button>
			<Button
				className={classes.button}
				size="large"
				color="primary"
				variant={timeType === 'week' ? 'contained' : 'outlined'}
				onClick={() => {
					setDateFilterTypeConnect('week');
					checkDateFilter({
						timeBefore: '',
						timeAfter: filterByWeek,
						redirectCallback: navigate,
						timeType: 'week',
					});
				}}
			>
				{t('filters.week')}
			</Button>
			<Button
				className={classes.button}
				size="large"
				color="primary"
				variant={timeType === 'range' ? 'contained' : 'outlined'}
				onClick={(e) => handleClick(e)}
				endIcon={<ExpandMoreIcon />}
			>
				{t('filters.customRange')}
			</Button>
			<Menu
				id="date-filter-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<li className={classes.filterLi}>
					<div className={classes.filterContent}>
						<form onSubmit={handleSubmit(onSubmit)} noValidate>
							<Box mb={2}>
								<Controller
									name="startDate"
									render={(
										{
											field: {
												value,
												onChange,
											},
											fieldState: {
												error
											},
										}
									) => (
										<MuiPickersUtilsProvider utils={DateFnsUtils} locale={lang === EN ? enLocale : daLocale}>
											<DatePicker
												okLabel={t('ok')}
												cancelLabel={t('cancel')}
												clearLabel={t('clear')}
												clearable
												fullWidth
												inputVariant="outlined"
												autoOk
												value={typeof(value) === 'object' ? value : value.slice(0, 10)}
												onChange={onChange}
												label={t('incident.startDate')}
												format="dd.MM.yyyy"
												error={!!error}
												helperText={error?.message}
												InputProps={{
													className: classes.input,
													endAdornment: <DateRangeIcon style={{color: '#555'}} />
												}}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										</MuiPickersUtilsProvider>
									)}
									control={control}
									defaultValue={queryDt === 'range' ? queryAfter : null}
								/>
							</Box>
							<Box mb={2}>
								<Controller
									name="endDate"
									render={(
										{
											field: {
												value,
												onChange,
											},
											fieldState: {
												error
											},
										}
									) => (
										<MuiPickersUtilsProvider utils={DateFnsUtils} locale={lang === EN ? enLocale : daLocale}>
											<DatePicker
												okLabel={t('ok')}
												cancelLabel={t('cancel')}
												clearLabel={t('clear')}
												clearable
												fullWidth
												inputVariant="outlined"
												autoOk
												value={typeof(value) === 'object' ? value : value.slice(0, 10)}
												onChange={onChange}
												label={t('incident.endDate')}
												format="dd.MM.yyyy"
												error={!!error}
												helperText={error?.message}
												InputProps={{
													className: classes.input,
													endAdornment: <DateRangeIcon style={{color: '#555'}} />
												}}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										</MuiPickersUtilsProvider>
									)}
									control={control}
									defaultValue={queryDt === 'range' ? queryBefore : null}
									rules={{
										validate: value => {
											const startDate = Date.parse(dateDecorator(getValues().startDate));
											const endDate = Date.parse(dateDecorator(value));

											if (!startDate || !endDate) {
												return true;
											}

											if (startDate <= endDate) {
												return true;
											}
											return t('validationErrors.endDate');
										},
									}}
								/>
							</Box>
							<Box textAlign='center'>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									size="medium"
									fullWidth
									className={classes.submit}
								>
									{t('apply')}
								</Button>
							</Box>
						</form>
					</div>
				</li>
			</Menu>
		</div>
	);
};

export default connect(
	(state) => ({
		lang: state.get('lang'),
		timeType: state.get('timeType'),
	}),
	{
		setDateFilterBeforeConnect: setDateFilterBefore,
		setDateFilterAfterConnect: setDateFilterAfter,
		setDateFilterTypeConnect: setDateFilterType,
		applyFilterConnect: applyFilter,
	},
)(DateFilter);