import React from 'react';
import  { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
	heading: {
		textAlign: 'center',
		color: '#222',
		fontWeight: 700,
		marginBottom: theme.spacing(2),
		padding: theme.spacing(0, 3),
	},
	content: {
		textAlign: 'center',
		maxWidth: 480,
		margin: '0 auto 20px',
		fontSize: 16
	},
	inner: {
		padding: theme.spacing(6, 4, 4),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

const AlertPopup = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		showAlert,
		setShowAlert,
		callbackSubmit,
		callbackCancel= () => {},
		title,
		content,
		size='xs',
		hideControls,
		callbackSubmitText=t('save'),
		callbackCancelText=t('cancel'),
	} = props;

	const handleClose = () => {
		setShowAlert(!showAlert);
		callbackCancel();
	};

	return (
		<Dialog maxWidth={size} onClose={handleClose} aria-labelledby="simple-dialog-title" open={showAlert}>
			<div className={classes.inner}>
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
					<CloseIcon />
				</IconButton>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography className={classes.heading} component="h4" variant="h5" color="primary">{title}</Typography>
						{content ? <Box className={classes.content}>{content}</Box> : null}
					</Grid>
					{!hideControls ? (
						<>
							<Grid item xs={12} sm={6}>
								<Button
									onClick={handleClose}
									variant="outlined"
									color="primary"
									size="large"
									fullWidth
								>
									{callbackCancelText}
								</Button>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Button
									onClick={callbackSubmit}
									variant="contained"
									color="primary"
									size="large"
									fullWidth
								>
									{callbackSubmitText}
								</Button>
							</Grid>
						</>
					) : null}
				</Grid>
			</div>
		</Dialog>
	);
};

export default AlertPopup;