import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	inner: {
		padding: theme.spacing(4),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

const CommonPopup = (props) => {
	const classes = useStyles();

	const {
		children,
		showPopup,
		setShowPopup,
		size='xs',
	} = props;

	const handleClose = () => {
		setShowPopup(!showPopup);
	};

	return (
		<Dialog maxWidth={size} onClose={handleClose} aria-labelledby="simple-dialog-title" open={showPopup}>
			<div className={classes.inner}>
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
					<CloseIcon />
				</IconButton>
				{children}
			</div>
		</Dialog>
	);
};

export default CommonPopup;