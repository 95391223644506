import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
	expand: {
		fontSize: 16,
		verticalAlign: 'text-top',
	},
}));

const SubServices = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		incident,
	} = props;

	const [expanded, setExpanded] = useState(false);

	const handleShowMore = (event) => {
		event.preventDefault();
		setExpanded(!expanded);
	};

	const mainServices = incident.get('subServices')
		.map((item) => item.getIn(['mainService', 'name']))
		.toSet().toList();

	const getSubServicesByMainService = (mainService) => (
		incident.get('subServices')
			.filter((item) => item.getIn(['mainService', 'name']) === mainService)
			.map((item) => item.get('name'))
			.toSet()
			.join(' | ')
	);

	return (
		<>
			{mainServices.map((item, index) => expanded ? (
				<Box key={index}><b>{item}:</b> {getSubServicesByMainService(item)}</Box>
			) : (
				index < 2 ? <Box key={index}><b>{item}:</b> {getSubServicesByMainService(item)}</Box> : null
			))}
			{mainServices.size > 2 ? <Link href="#" onClick={handleShowMore}>{expanded ? t('less') : t('more')} {expanded ? <ExpandLessIcon className={classes.expand} /> : <ExpandMoreIcon className={classes.expand} />}</Link> : null}
		</>
	);
};

export default SubServices;
