import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	permissionWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(0, -1),

		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	permissionBlock: {
		margin: theme.spacing(0, 1),
		width: 'calc(33.33% - 16px)',

		[theme.breakpoints.down('sm')]: {
			width: 'auto',
		},
	},
	permissionList: {
		fontSize: 16,
		'& > li': {
			marginBottom: theme.spacing(2),
		}
	},
	green: {
		color: theme.palette.success.main
	},
	red: {
		color: theme.palette.error.main
	},
}));

const ViewRoles = () => {
	const classes = useStyles();

	const { t } = useTranslation();

	return (
		<div className={classes.permissionWrap}>
			<Box className={classes.permissionBlock}>
				<h2>{t('roles.tdcManagement')}</h2>
				<ul className={classes.permissionList}>
					<li>{t('roles.viewIncidents')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.viewStatements')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.createEditIncidents')}: <b className={classes.red}>{t('no')}</b></li>
					<li>{t('roles.createEditStatements')}: <b className={classes.red}>{t('no')}</b></li>
				</ul>
			</Box>
			<Box className={classes.permissionBlock}>
				<h2>{t('roles.nsocAgent')}</h2>
				<ul className={classes.permissionList}>
					<li>{t('roles.viewIncidents')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.viewStatements')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.createEditIncidents')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.createEditStatements')}: <b className={classes.red}>{t('no')}</b></li>
				</ul>
			</Box>
			<Box className={classes.permissionBlock}>
				<h2>{t('roles.statementAgent')}</h2>
				<ul className={classes.permissionList}>
					<li>{t('roles.viewIncidents')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.viewStatements')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.createEditIncidents')}: <b className={classes.red}>{t('no')}</b></li>
					<li>{t('roles.createEditStatements')}: <b className={classes.green}>{t('yes')}</b></li>
				</ul>
			</Box>
			<Box className={classes.permissionBlock}>
				<h2>{t('roles.readOnly')}</h2>
				<ul className={classes.permissionList}>
					<li>{t('roles.viewIncidents')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.viewStatements')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.createEditIncidents')}: <b className={classes.red}>{t('no')}</b></li>
					<li>{t('roles.createEditStatements')}: <b className={classes.red}>{t('no')}</b></li>
				</ul>
			</Box>
			<Box className={classes.permissionBlock}>
				<h2>{t('roles.administrator')}</h2>
				<ul className={classes.permissionList}>
					<li>{t('roles.createEditViewDeleteUsers')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.assignUserRole')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.createEditViewIncidents')}: <b className={classes.red}>{t('no')}</b></li>
					<li>{t('roles.createEditViewStatements')}: <b className={classes.red}>{t('no')}</b></li>
				</ul>
			</Box>
			<Box className={classes.permissionBlock}>
				<h2>{t('roles.superAdministrator')}</h2>
				<ul className={classes.permissionList}>
					<li>{t('roles.createEditViewDeleteUsers')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.assignUserRole')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.createEditViewIncidents')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.createEditViewStatements')}: <b className={classes.green}>{t('yes')}</b></li>
				</ul>
			</Box>
			<Box className={classes.permissionBlock}>
				<h2>{t('roles.ewiiUser')}</h2>
				<ul className={classes.permissionList}>
					<li>{t('roles.viewIncidents')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.viewStatements')}: <b className={classes.green}>{t('yes')}</b></li>
					<li>{t('roles.createEditIncidents')}: <b className={classes.red}>{t('no')}</b></li>
					<li>{t('roles.createEditStatements')}: <b className={classes.red}>{t('no')}</b></li>
				</ul>
			</Box>
		</div>
	);
};

export default ViewRoles;