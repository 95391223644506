import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';

import FilterListIcon from '@material-ui/icons/FilterList';

import FilterItem from './FilterItem';

import {
	applyFilter,
	setFilterChecked,
	setFilterCheckedAll,
} from '../../../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	filterLi: {
		outline: 'none',
	},
	filterContent: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		minWidth: 300,

		'& > .MuiFormControlLabel-root': {
			marginRight: 0,
		},
		'& .MuiFormControlLabel-label': {
			fontSize: 14,
		}
	},
	filterContentInner: {
		borderTop: '1px solid #ccc',
	},
	filterTitle: {
		marginBottom: theme.spacing(2),
	},
	submit: {
		margin: theme.spacing(4, 0, 2),
	},
}));

const Filter = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	let navigate = useNavigate();

	const {
		handleSubmit,
	} = useForm();

	const {
		filterCounter,
		filter,
		applyFilterConnect,
		setFilterCheckedConnect,
		setFilterCheckedAllConnect,
		userRole
	} = props;

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onSubmit = (data) => {
		handleClose();

		applyFilterConnect({
			redirectCallback: navigate,
			formData: data,
		});
	};

	const handleCheckAll = (payload) => {
		const {
			e,
			type,
			value,
		} = payload;

		e.preventDefault();
		setFilterCheckedAllConnect({ type, value })
	};

	const handleChange = (payload) => {
		const {
			item,
			i,
		} = payload;

		setFilterCheckedConnect({
			type: i,
			item: item,
		});
	};

	return (
		<>
			<Button
				size="large"
				color="primary"
				onClick={(e) => handleClick(e)}
				startIcon={
					<Badge
						badgeContent={filterCounter}
						color="primary"
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
					>
						<FilterListIcon />
					</Badge>
				}
			>
				{t('filters.filter')}
			</Button>
			<Menu
				id="filter-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<li className={classes.filterLi}>
					<form onSubmit={handleSubmit(onSubmit)} noValidate>
						<div className={classes.filterContent}>
							<Typography
								className={classes.filterTitle}
								variant="h6"
							>
								<b>{t('filters.filters')}</b>
							</Typography>
							<div className={classes.filterContentInner}>
								
								{filter.keySeq().map((i, key) => (
									<FilterItem
										key={key}
										filter={filter}
										userRole={userRole}
										i={i}
										handleCheckAll={handleCheckAll}
										handleChange={handleChange}
									/>
								))}
							
							</div>
							<Box textAlign='center'>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									size="medium"
									fullWidth
									className={classes.submit}
								>
									{t('apply')}
								</Button>
							</Box>
						</div>
					</form>
				</li>
			</Menu>
		</>
	);
};

export default connect(
	(state) => ({
		filter: state.get('filter'),
		filterCounter: state.get('filterCounter'),
		userRole: state.get('userRole')
	}), {
		applyFilterConnect: applyFilter,
		setFilterCheckedConnect: setFilterChecked,
		setFilterCheckedAllConnect: setFilterCheckedAll,
	},
)(Filter);