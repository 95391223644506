import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import Lang from './Lang'
import Hamburger from './Hamburger';

import siteLogo from '../utils/logo';
import { submitLogOut } from '../utils/ducks';

const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: '100%',

		[theme.breakpoints.up('lg')]: {
			maxWidth: '95%',
		},
	},
	header: {
		padding: theme.spacing(3, 0),
		backgroundColor: '#fff',
	},
	inner: {
		display: 'flex',
		justifyContent: 'space-between',

		[theme.breakpoints.down('xs')]: {
			display: 'block',
		},
	},
	leftIndent: {
		marginLeft: theme.spacing(2),
	},
	logo: {
		width: 140,
	},
	leftBlock: {
		alignItems: 'center',
		display: 'flex',
	},
	rightBlock: {
		alignItems: 'center',
		display: 'flex',

		[theme.breakpoints.down('xs')]: {
			marginTop:  theme.spacing(1),
			justifyContent: 'flex-end',
		},
	}
}));

// ... (import statements and useStyles)

const Header = (props) => {
	const classes = useStyles();
  
	const { t } = useTranslation();
  
	const {
	  userRole,
	  children,
	  submitLogOutConnect,
	} = props;
  
	return (
	  <header className={classes.header}>
		<Container className={classes.container} maxWidth={false}>
		  <Box className={classes.inner}>
			<Box className={classes.leftBlock}>
			<Link href="/" style={{ display: 'flex' }}>
              <img className={classes.logo} src={siteLogo} alt="TDC Logo" />
            </Link>
            {userRole === 'ROLE_EWII_USER' && (
              <div>
                <h3>&nbsp; &nbsp; Service Provider Version</h3>
              </div>
            )}
			  <Lang />
			</Box>
			<Box className={classes.rightBlock}>
			  {children}
			  <Button
				onClick={submitLogOutConnect}
				variant="outlined"
				color="primary"
				size="large"
				className={classes.leftIndent}
			  >
				{t('auth.logOut')}
			  </Button>
			  {userRole === 'ROLE_SUPER_ADMIN' ? <Hamburger /> : null}
			</Box>
		  </Box>
		</Container>
	  </header>
	);
  };
  
  export default connect(
	(state) => ({
	  userRole: state.get('userRole'),
	}),
	{
	  submitLogOutConnect: submitLogOut,
	},
  )(Header);
  

