import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import Details from '../IncidentDetails/Details'

const useStyles = makeStyles((theme) => ({
	heading: {
		textAlign: 'center',
		color: '#222',
		fontWeight: 700,
		marginBottom: theme.spacing(2),
		padding: theme.spacing(0, 3),
	},
	text: {
		textAlign: 'center',
		maxWidth: 480,
		margin: '0 auto',
		fontSize: 16
	},
	inner: {
		padding: theme.spacing(6, 4),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

const ConfirmationPopup = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		confirmPreview,
		setConfirmPreview,
		callbackSubmit,
		incident,
	} = props;

	const handleClose = () => {
		setConfirmPreview(!confirmPreview);
	};

	return (
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={confirmPreview}>
			<div className={classes.inner}>
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
					<CloseIcon />
				</IconButton>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography className={classes.heading} component="h4" variant="h5" color="primary">{t('confirm')}</Typography>
						<Box className={classes.text}>{t('incident.confirmationText')}</Box>
						<Details
							incident={incident}
							userRole={''}
							isPreview
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							onClick={handleClose}
							variant="outlined"
							color="primary"
							size="large"
							fullWidth
						>
							{t('cancel')}
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							onClick={callbackSubmit}
							variant="contained"
							color="primary"
							size="large"
							fullWidth
						>
							{t('confirm')}
						</Button>
					</Grid>
				</Grid>
			</div>
		</Dialog>
	);
};

export default ConfirmationPopup;