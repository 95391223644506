import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import TableHead from '@material-ui/core/TableHead';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PersonIcon from '@material-ui/icons/Person';

import CommonExpand from '../../Common/CommonExpand';
import CommonPagination from '../../Common/CommonPagination';
import SubServices from './SubServices';
import PrTooltip from './PrTooltip';
import IncidentActions from './IncidentActions';
import { format } from 'date-fns'

import severityColors from '../../../utils/constants';
import Typography from '@material-ui/core/Typography';

import Sorting from './Sorting';
import { getTypeText, getAffectedUsersNumberString } from '../../../utils/ducks';

const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: '100%',

		[theme.breakpoints.up('lg')]: {
			maxWidth: '95%',
		},
	},
	scroll: {
		'maxHeight': '65vh',
		'-webkit-overflow-scrolling: touch': 'touch',
		'&::-webkit-scrollbar': {
			height: 12
		},
		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.1)',
			borderRadius: 2
		},
		'&::-webkit-scrollbar-thumb:window-inactive': {
			backgroundColor: 'rgba(0,0,0,.1)',
		},
	},
	table: {
		minWidth: 650,
	},
	th: {
		color: '#aaa',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		textTransform: 'uppercase',
		fontSize: 12,
		lineHeight: 1.4,
	},
	tableBody: {
		'& td': {
			fontSize: 13,
			minWidth: 110,
		},
		'& .MuiOutlinedInput-root': {
			fontSize: 13,
		}
	},
	small: {
		minWidth: 'auto !important',
	},
	icon: {
		verticalAlign: 'sub',
	},
	gray: {
		color: '#bbb',
	},
	nowrap: {
		whiteSpace: 'nowrap',
	},
	wordBreak: {
		wordBreak: 'break-word',
		width: 110,
	},
}));

const IncidentsList = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const {
		incidentsList,
		totalItems,
		status,
		customizeView,
		userRole,
	} = props;

	let defaultSorting = 'desc';

	const query = new URLSearchParams(window.location.search);

	const queryOrderTimeStart = query.get('order[timeStart]');
	const queryOrderTimeResolved = query.get('order[timeResolved]');
	const queryOrderTimeEnd = query.get('order[timeEnd]');
	const queryOrderIncidentSeverity = query.get('order[incidentSeverity.orderBy]');
	const queryOrderAffectedUsersNumber = query.get('order[affectedUsersNumber.to]');

	if (queryOrderTimeStart || queryOrderTimeResolved || queryOrderTimeEnd || queryOrderIncidentSeverity || queryOrderAffectedUsersNumber) {
		defaultSorting = '';
	}

	let formatDate = dateTime => !dateTime ? '' : format(new Date(Date.parse(dateTime)), 'dd.MM.yyyy');
	let formatTime = dateTime => !dateTime ? '' : format(new Date(Date.parse(dateTime)), 'hh:mm');

	return (
		<Container className={classes.container} maxWidth={false}>
			<TableContainer className={classes.scroll} component={Paper}>
				<Table className={classes.table} size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							{customizeView.getIn(['title', 'checked']) ? <TableCell className={classes.th}>{t('incidentsList.title')}</TableCell> : null}
							{customizeView.getIn(['incidentOwner', 'checked']) ? <TableCell className={classes.th}>{t('incidentsList.incidentOwner')}</TableCell> : null}
							{customizeView.getIn(['incidentVendor', 'checked']) ? <TableCell className={classes.th}>{t('incidentsList.incidentVendor')}</TableCell> : null}
							{customizeView.getIn(['ticketID', 'checked']) ? <TableCell className={classes.th}>{t('incidentsList.ticketID')}</TableCell> : null}
							{customizeView.getIn(['startDate', 'checked']) ? <TableCell className={classes.th} style={{whiteSpace: 'nowrap'}}>{t('incident.timeOccured')}<Sorting sortType={'timeStart'} active={defaultSorting || queryOrderTimeStart} /></TableCell> : null}
							{customizeView.getIn(['resolvedDate', 'checked']) ? <TableCell className={classes.th} style={{whiteSpace: 'nowrap'}}>{t('incidentsList.resolvedDate')}<Sorting sortType={'timeResolved'} active={queryOrderTimeResolved} /></TableCell> : null}
							{customizeView.getIn(['closedDate', 'checked']) ? <TableCell className={classes.th} style={{whiteSpace: 'nowrap'}}>{t('incidentsList.closedDate')}<Sorting sortType={'timeEnd'} active={queryOrderTimeEnd} /></TableCell> : null}
							{customizeView.getIn(['severity', 'checked']) ? <TableCell className={classes.th} style={{whiteSpace: 'nowrap'}}>{t('incidentsList.severity')}<Sorting sortType={'incidentSeverity.orderBy'} active={queryOrderIncidentSeverity} /></TableCell> : null}
							{customizeView.getIn(['affectedUsers', 'checked']) ? <TableCell className={classes.th} style={{whiteSpace: 'nowrap'}}>{t('incidentsList.affectedUsers')}<Sorting sortType={'affectedUsersNumber.to'} active={queryOrderAffectedUsersNumber} /></TableCell> : null}
							{customizeView.getIn(['serviceType', 'checked']) ? <TableCell className={classes.th}>{t('incidentsList.serviceType')}</TableCell> : null}
							{customizeView.getIn(['customersAffectedByName', 'checked']) ? <TableCell className={classes.th}>{t('incidentsList.customersAffectedByName')}</TableCell> : null}
							{customizeView.getIn(['rootCause', 'checked']) ? <TableCell className={classes.th}>{t('incidentsList.rootCause')}</TableCell> : null}
							{customizeView.getIn(['location', 'checked']) ? <TableCell className={classes.th}>{t('incidentsList.location')}</TableCell> : null}
							{customizeView.getIn(['type', 'checked']) ? <TableCell className={classes.th}>{t('incidentsList.type')}</TableCell> : null}
							{customizeView.getIn(['status', 'checked']) ? <TableCell className={classes.th}>{t('incidentsList.status')}</TableCell> : null}
							{customizeView.getIn(['pr', 'checked']) ? <TableCell className={classes.th}>{t('incidentsList.statement')}</TableCell> : null}
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody className={classes.tableBody}>
						{incidentsList.map((item) => (
							<TableRow key={item.get('id')}>
								{customizeView.getIn(['title', 'checked']) ? (
									<TableCell>
										<div className={classes.wordBreak}>{item.get('title')}</div>
									</TableCell>
								): null}
								{customizeView.getIn(['incidentOwner', 'checked']) ? (
									<TableCell>
										<Tooltip title={item.getIn(['incidentOwner', 'name'])} placement={'top'} arrow={true}>
											{item.getIn(['incidentOwner', 'name']) === 'Nuuday'
												? <img src="/assets/Nuuday.svg" alt="Nuuday" />
												: <img src="/assets/TDC.svg" alt="TDC Net" />
											}
										</Tooltip>
									</TableCell>
								): null}
								{customizeView.getIn(['incidentVendor', 'checked']) ? (
									<TableCell>
										<div className={classes.wordBreak}>{item.getIn(['incidentVendor', 'name'])}</div>
									</TableCell>
								): null}
								{customizeView.getIn(['ticketID', 'checked']) ? (
									<TableCell>
										<div className={classes.wordBreak}>
											<Link href={`/view-incident/${item.get('id')}`}>{item.get('externalTicketId')}</Link>
										</div>
									</TableCell>
								): null}
								{customizeView.getIn(['startDate', 'checked']) ? (
									<TableCell>
										<Box>
											{formatDate(item.get('timeStart'))}
										</Box>
										<Box className={classes.gray}>
											{formatTime(item.get('timeStart'))}
										</Box>
									</TableCell>
								): null}
								{customizeView.getIn(['resolvedDate', 'checked']) ? (
									<TableCell>
										<Box>
											{formatDate(item.get('timeResolved'))}
										</Box>
										<Box className={classes.gray}>
											{formatTime(item.get('timeResolved'))}
										</Box>
									</TableCell>
								): null}
								{customizeView.getIn(['closedDate', 'checked']) ? (
									<TableCell>
										<Box>
											{formatDate(item.get('timeEnd'))}
										</Box>
										<Box className={classes.gray}>
											{formatTime(item.get('timeEnd'))}
										</Box>
									</TableCell>
								): null}
								{customizeView.getIn(['severity', 'checked']) ? (
									<TableCell className={classes.nowrap} style={{color: severityColors[item.getIn(['incidentSeverity', 'id'])]}}>
										<FiberManualRecordIcon style={{ fontSize: 10 }} /> {item.getIn(['incidentSeverity', 'name'])}
									</TableCell>
								): null}
								{customizeView.getIn(['affectedUsers', 'checked']) ? (
									<TableCell className={classes.nowrap}>
										<PersonIcon className={classes.gray} style={{ fontSize: 15, verticalAlign: 'top' }} />
										{' '}
										{getAffectedUsersNumberString(item)}
									</TableCell>
								): null}
								{customizeView.getIn(['serviceType', 'checked']) ? (
									<TableCell>
										<SubServices incident={item} />
									</TableCell>
								): null}
								{customizeView.getIn(['customersAffectedByName', 'checked']) ? (
									<TableCell>
										<CommonExpand
											item={item.get('customers')}
											visibleItems={4}
										/>
									</TableCell>
								): null}
								{customizeView.getIn(['rootCause', 'checked']) ? (
									<TableCell>
										{item.getIn(['rootCause', 'name'])}
									</TableCell>
								): null}
								{customizeView.getIn(['location', 'checked']) ? (
									<TableCell>
										<CommonExpand
											item={item.get('countries').concat(item.get('provinces'), item.get('municipalities'), item.get('cities'))}
											visibleItems={4}
										/>
									</TableCell>
								): null}
								{customizeView.getIn(['type', 'checked']) ? (
									<TableCell className={classes.small} style={{textTransform: 'capitalize'}}>
										{getTypeText(item.get('type'), t)}
									</TableCell>
								): null}
								{customizeView.getIn(['status', 'checked']) ? (
									<TableCell>
										
										{item.getIn(['incidentStatus', 'name'])}
									</TableCell>
								): null}
								{customizeView.getIn(['pr', 'checked']) ? (
									<TableCell className={classes.small} align="center">
										{item.get('prStatement') ? <PrTooltip prStatement={item.get('prStatement')} /> : null}
									</TableCell>
								): null}
								<TableCell className={classes.small} align="right">
									<IncidentActions
										item={item}
										userRole={userRole}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{!totalItems ? (
				<Box textAlign={'center'} mt={5}>
					<Typography variant={'h4'}>{t('incidentsList.noRecordsFound')}</Typography>
				</Box>
			) : null}

			<CommonPagination
				initialUrl={'/incidents'}
				totalItems={totalItems}
				itemsOnPage={10}
			/>
		</Container>
	);
};

export default IncidentsList;
