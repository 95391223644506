import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import IconButton from '@material-ui/core/IconButton';

import { applyFilter } from '../../../utils/ducks';

const Sorting = (props) => {
	let navigate = useNavigate();

	const {
		active,
		sortType,
		applyFilterConnect,
	} = props;

	const handleSort = (sortType) => {
		applyFilterConnect({
			formData: {
				sortType: sortType,
			},
			redirectCallback: navigate,
		});
	};

	return (
		<IconButton
			size={'small'}
			onClick={() => handleSort(sortType)}
		>
			{active === 'desc' ? <ArrowDownwardIcon color={active ? 'primary' : 'disabled'} /> : <ArrowUpwardIcon color={active ? 'primary' : 'disabled'} /> }
		</IconButton>
	);
};

export default connect(
	null,
	{
		applyFilterConnect: applyFilter,
	}
)(Sorting);
