import {
	COLOR_GREEN,
	COLOR_YELLOW,
	COLOR_ORANGE,
	COLOR_RED,
} from './colors';

const severityColors = {
	1: COLOR_GREEN,
	2: COLOR_YELLOW,
	3: COLOR_ORANGE,
	4: COLOR_RED,
};

export const allowedProvince = [
	'PR116', // 'Region Hovedstaden',
	'PR117', // 'Region Sjælland',
	'PR118', // 'Region Syddanmark',
	'PR119', // 'Region Midtjylland',
	'PR120', // 'Region Nordjylland',
];

export default severityColors;