import { fromJS } from 'immutable';
import {
	all,
	call,
	put,
	takeEvery,
	select,
} from 'redux-saga/effects';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

import { allowedProvince } from './constants';
import {format} from "date-fns";

// ================= CONSTANTS
 let BASE_URL = 'https://opinfo.tdc.dk/app';
// let BASE_URL = 'https://opinfo-test.tdc.dk/app';
//let BASE_URL = 'http://localhost:8080/app';

export const EN = 'en_US';
export const DA = 'da_DK';
export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refreshToken';
export const LOCAL_STORAGE_TOKEN_KEY = 'token';
export const LOCAL_STORAGE_FILTER = 'filter';
export const LOCAL_STORAGE_CUSTOMIZE_VIEW_OLD = 'customizeView_v2';
export const LOCAL_STORAGE_CUSTOMIZE_VIEW = 'customizeView_v3';

let tokenCashed = '';
let language = EN;
let counterRefreshToken = 0;

const MAX_COUNTER_REFRESH_TOKEN = 20;

export const APPLY = 'APPLY';
export const CHANGE = 'CHANGE';
export const CREATE = 'CREATE';
export const DELETE = 'DELETE';
export const RESET = 'RESET';
export const EDIT = 'EDIT';
export const GET = 'GET';
export const INIT = 'INIT';
export const PATCH = 'PATCH';
export const POST = 'POST';
export const PREVENT = 'PREVENT';
export const SET = 'SET';
export const SUBMIT = 'SUBMIT';
export const UPDATE = 'UPDATE';
export const RECOVERY = 'RECOVERY';
export const SUCCESS = 'SUCCESS';
export const SORT = 'SORT';
export const ADD = 'ADD';

export const ALL = 'ALL';
export const AUTH = 'AUTH';
export const CHECKED = 'CHECKED';
export const DATA = 'DATA';
export const DETAILS = 'DETAILS';
export const ERROR = 'ERROR';
export const EVENTS = 'EVENTS';
export const FILTER = 'FILTER';
export const DATE = 'DATE';
export const FORM = 'FORM';
export const INCIDENT = 'INCIDENT';
export const INCIDENTS = 'INCIDENTS';
export const LOADING = 'LOADING';
export const LOG = 'LOG';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const PARAMS = 'PARAMS';
export const PR = 'PR';
export const REQUEST = 'REQUEST';
export const ID = 'ID';
export const CURRENT = 'CURRENT';
export const ROLE = 'ROLE';
export const ROLES = 'ROLES';
export const SELECTS = 'SELECTS';
export const STATEMENT = 'STATEMENT';
export const STATUS = 'STATUS';
export const TOKEN = 'TOKEN';
export const USER = 'USER';
export const USERS = 'USERS';
export const SERVICE = 'SERVICE';
export const SERVICES = 'SERVICES';
export const CUSTOMER = 'CUSTOMER';
export const CUSTOMERS = 'CUSTOMERS';
export const VIEW = 'VIEW';
export const PROVINCE = 'PROVINCE';
export const COUNT = 'COUNT';
export const SHOW = 'SHOW';
export const MAP = 'MAP';
export const LANG = 'LANG';
export const CUSTOMIZE = 'CUSTOMIZE';
export const DELETED = 'DELETED';
export const COUNTER = 'COUNTER';
export const SEARCH = 'SEARCH';
export const QUERY = 'QUERY';
export const BEFORE = 'BEFORE';
export const AFTER = 'AFTER';
export const TYPE = 'TYPE';
export const PASSWORD = 'PASSWORD';
export const DIRECTION = 'DIRECTION';
export const PREVIEW = 'PREVIEW';
export const SUB = 'SUB';
export const UPDATES = 'UPDATES';

// ================= INITIAL STATE
export const initialState = fromJS({
	lang: EN,
	init: false,
	isLoading: true,
	token: '',
	refreshToken: '',
	currentUserId: '',
	userRole: '',
	incident: {},
	user: {},
	statusUpdate: {},
	service: {},
	customer: {},
	eventsLog: [],
	eventComments: [],
	isReceivedDataForSelects: false,
	showIncidentsMap: false,
	filterCounter: 0,
	timeAfter: null,
	timeBefore: null,
	timeType: 'all',
	searchQuery: '',
	sortType: 'timeStart',
	sortDirection: 'desc',
	filter: {
		type: [
			{ value: 'Incident', label: 'Incident', checked: false },
			{ value: 'External Error', label: 'External Error', checked: false },
			{ value: 'Planned Work', label: 'Planned Work', checked: false },
		],
		incidentOwner: [],
		severity: [],
		status: [],
		rootCause: [],
		serviceType: [],
		affectedUsers: [],
		location: [],
	},
	servicesData: [],
	customersData: [],
	statusUpdatesData: [],
	usersData: {
		list: [],
		totalItems: -1,
		firstPage: '',
		lastPage: '',
		nextPage: '',
		previousPage: '',
	},
	incidentsData: {
		list: [],
		totalItems: -1,
		firstPage: '',
		lastPage: '',
		nextPage: '',
		previousPage: '',
	},
	selectsData: {
		incidentOwner: [],
		incidentVendor: [],
		incidentSeverity: [],
		incidentStatus: [],
		customerAffectedByName: [],
		mainServiceAffected: [],
		affectedUsersNumbers: [],
		rootCause: [],
		subServiceAffected: [],
		province: [],
		continents: [],
		municipalities: [],
		city: [],
	},
	userRolesSelectsData: [],
	allIncidentCounts: {
		open: 0,
		ongoing: 0,
		closed: 0,
	},
	provinceIncidentCounts: {},
	customizeView: {
		title: {
			label: 'incidentsList.title',
			checked: true,
			value: 'title',
			order: 1,
		},
		incidentOwner: {
			label: 'incidentsList.incidentOwner',
			checked: true,
			value: 'incidentOwner',
			order: 2,
		},
		incidentVendor: {
			label: 'incidentsList.incidentVendor',
			checked: true,
			value: 'incidentVendor',
			order: 3,
		},
		ticketID: {
			label: 'incidentsList.ticketID',
			checked: true,
			value: 'ticketID',
			order: 4,
		},
		startDate: {
			label: 'incidentsList.startDate',
			checked: true,
			value: 'startDate',
			order: 5,
		},
		resolvedDate: {
			label: 'incidentsList.resolvedDate',
			checked: true,
			value: 'resolvedDate',
			order: 6,
		},
		closedDate: {
			label: 'incidentsList.closedDate',
			checked: true,
			value: 'closedDate',
			order: 7,
		},
		severity: {
			label: 'incidentsList.severity',
			checked: true,
			value: 'severity',
			order: 8,
		},
		affectedUsers: {
			label: 'incidentsList.affectedUsers',
			checked: true,
			value: 'affectedUsers',
			order: 9,
		},
		serviceType: {
			label: 'incidentsList.serviceType',
			checked: true,
			value: 'serviceType',
			order: 10,
		},
		customersAffectedByName: {
			label: 'incidentsList.customersAffectedByName',
			checked: true,
			value: 'customersAffectedByName',
			order: 11,
		},
		rootCause: {
			label: 'incidentsList.rootCause',
			checked: true,
			value: 'rootCause',
			order: 12,
		},
		location: {
			label: 'incidentsList.location',
			checked: true,
			value: 'location',
			order: 13,
		},
		type: {
			label: 'incidentsList.type',
			checked: true,
			value: 'type',
			order: 14,
		},
		status: {
			label: 'incidentsList.status',
			checked: true,
			value: 'status',
			order: 15,
		},
		pr: {
			label: 'incidentsList.statement',
			checked: true,
			value: 'pr',
			order: 16,
		}
	},
	countsData : {
		open: 0,
		ongoing: 0
	}
});

// ================= UTILS
export const getActionName = (...parts) => `${parts.join('_')}`;

const setItemLocalStorage = async (key, value) => {
	try {
		localStorage.setItem(key,	value);
	} catch (error) {
		// empty error
	}
};

const getItemFromLocalStorage = async (key) => {
	try {
		return localStorage.getItem(key);
	} catch (error) {
		// empty error
	}

	return null;
};

const removeItemFromLocalStorage = async (key) => {
	try {
		return localStorage.removeItem(key);
	} catch (error) {
		// empty error
	}

	return null;
};

export const axiosInstance = (type, lng) => {
	const headers = {
		'Content-Type': type === PATCH ? 'application/merge-patch+json' : 'application/ld+json',
	};

	if (tokenCashed) {
		headers.Authorization = `Bearer ${tokenCashed}`;
	}

	headers['X-LOCALE'] = lng ? lng : language;

	return axios.create({
		baseURL: BASE_URL,
		headers,
	});
};

export const formatDate = (dateTime) => {
	return  !dateTime ? '--' : format(new Date(Date.parse(dateTime)), 'dd.MM.yyyy hh:mm');
};

const prepareDataForFilter = (payload, objKey, isAffectedUsersNumbers) => (
	fromJS(payload).get(objKey).map((item) => (
		fromJS({
			value: item.get('id'),
			label: isAffectedUsersNumbers ? `${getAffectedUsersNumberString(item)}` : item.get('name'),
			checked: false,
		})
	))
);

export const userRolesDecorator = (value) => {
	switch (value) {
		case 'ROLE_READ_ONLY':
			return 'roles.readOnly';
		case 'ROLE_TDC_MANAGEMENT':
			return 'roles.tdcManagement';
		case 'ROLE_INCIDENT_MANAGER':
			return 'roles.nsocAgent';
		case 'ROLE_ADMINISTRATOR':
			return 'roles.administrator';
		case 'ROLE_SUPER_ADMIN':
			return 'roles.superAdministrator';
		case 'ROLE_PR':
			return 'roles.statementAgent';
		case 'ROLE_EWII_USER':
			return 'roles.ewiiUser';
		default:
			return 'roles.tdcManagement';
	}
};

export const getTypeText = (type, t) => {
	if (type === 'Planned Work') {
		return t('incident.plannedWork');
	}

	if (type === 'External Error') {
		return t('incident.externalError');
	}

	if (type === 'Incident') {
		return t('incident.incident');
	}

	return '';
};

export const getAffectedUsersNumberString = (item) => {
	try {
		const from = (item.getIn(['affectedUsersNumber', 'from']) || item.getIn(['from'])) ?? '';
		const to = (item.getIn(['affectedUsersNumber', 'to']) || item.getIn(['to'])) ?? '';
		const isMaxValue = item.getIn(['affectedUsersNumber', 'isMaxValue']) || item.getIn(['isMaxValue']);

		if (!from && !to) return '';

		if (isMaxValue) return `${from.toLocaleString('dn')}+`;

		if (from === to) return `${from.toLocaleString('dn')}`;

		return `${(from || 0).toLocaleString('dn')} - ${(to || 0).toLocaleString('dn')}`
	} catch (e) {
		return '';
	}
};

const setSelectedValues = (item, payload) => (
	item.map((i) => {
		if (i.get('value') === payload.item.get('value')) { // equals value
			return i.update('checked', (val) => !val);
		}

		if (payload.item.get('hasChild') && i.get('parentId') === payload.item.get('value')) { // set children value by parent value
			return i.set('checked', !payload.item.get('checked'));
		}

		if (payload.item.get('hasParent') && payload.item.get('checked') && i.get('value') === payload.item.get('parentId')) { // uncheck parent by one child
			return i.set('checked', false);
		}

		if (payload.item.get('hasParent') && !payload.item.get('checked') && i.get('value') === payload.item.get('parentId')) { // check parent by every child
			const isEveryChildCheckedInParent = item
				.filter((a) => a.get('parentId') === payload.item.get('parentId'))
				.every((a) => payload.item.get('value') === a.get('value') || a.get('checked'));

			if (isEveryChildCheckedInParent) {
				return i.set('checked', true);
			}
		}

		return i;
	})
);

// ================= MISC
export const getMainServiceIri = (name, incident) => (
	name && incident.get('subServices')
		.find((item) => item.getIn(['mainService', 'name']) === name).getIn(['mainService', '@id'])
);

export const getSubServicesByMainService = (mainService, incident) => {
	return (
		mainService ? incident.get('subServices')
			.filter((item) => item.getIn(['mainService', 'name']) === mainService)
			.map((item) => item.get('@id')).toJS() : []
	)
};

export const setMainServiceValueHandler = (value, index, setValue, setMainServiceValue, firsTrigger) => {
	if (!firsTrigger) {
		setValue(`subServiceAffected[${index}]`, []);
	}

	setMainServiceValue((arr) => {
		const tempArray = [...arr];
		tempArray[index] = value;

		return tempArray;
	});
};

export const getFilteredMainServiceAffected = (index, mainServiceAffected, mainServiceValue, servicesArray) => (
	mainServiceAffected.filter((item) => {
		if (item.get('@id') === mainServiceValue[index]) {
			return true;
		}

		return !mainServiceValue.some((v, i) => (
			item.get('@id') === v && servicesArray[i] !== DELETED
		));
	})
);

export const getSubServiceAffected = (mainServiceName, allSubService) => (
	allSubService.filter((item) => item.get('mainService') === mainServiceName)
);

export const getItemByIri = (iri, objectKey, selectsData) => (
	iri && selectsData.get(objectKey)
		.find((item) => item.get('@id') === iri)
);

export const getItemByIriArrayCities = (iriArr, continents) => {
	const locations = [];

	iriArr.forEach((iri) => {
		const isContinents = iri.includes('/continents/');
		const isCountries = iri.includes('/countries/');
		const isProvinces = iri.includes('/provinces/');
		const isMunicipalities = iri.includes('/municipalities/');

		if (isContinents) {
			continents.forEach((continent) => {
				if (continent.get('@id') ===  iri) {
					continent.get('countries').forEach((country) => {
						locations.push(country);
					});
				}
			});
		} else if (isCountries) {
			continents.forEach((continent) => {
				continent.get('countries').forEach((country) => {
					if (country.get('@id') ===  iri) {
						locations.push(country);
					}
				});
			});
		} else if (isProvinces) {
			continents.forEach((continent) => {
				continent.get('countries').forEach((country) => {
					country.get('provinces').forEach((province) => {
						if (province.get('@id') ===  iri) {
							locations.push(province);
						}
					})
				});
			});
		} else if (isMunicipalities) {
			continents.forEach((continent) => {
				continent.get('countries').forEach((country) => {
					country.get('provinces').forEach((province) => {
						province.get('municipalities').forEach((municipality) => {
							if (municipality.get('@id') === iri) {
								locations.push(municipality);
							}
						})
					})
				});
			});
		} else {
			continents.forEach((continent) => {
				continent.get('countries').forEach((country) => {
					country.get('provinces').forEach((provinces) => {
						provinces.get('municipalities').forEach((municipality) => {
							municipality.get('cities').forEach((city) => {
								if (iri === city.get('@id')) {
									locations.push(city);
								}
							})
						});
					});
				});
			});
		}
	});

	return locations;
};

export const requestDateDecorator = (date) => {
	/*const localDate = date.toLocaleString();

	if (localDate.indexOf('PM') === -1 && localDate.indexOf('AM') === -1) {
		return localDate.replaceAll('/', '.');
	}*/

	return date.getTime();
};

// ================= ACTION CREATORS
export const setIsLoading = (isLoading) => (
	{ type: getActionName(SET, LOADING), payload: isLoading }
);

export const setInit = (payload) => (
	{ type: getActionName(SET, INIT), payload }
);

export const initAuth = (payload) => (
	{ type: getActionName(INIT, AUTH), payload }
);

export const setToken = (payload) => (
	{ type: getActionName(SET, TOKEN), payload }
);

export const submitPasswordRecoveryForm = (payload) => (
	{ type: getActionName(SUBMIT, PASSWORD, RECOVERY, FORM), payload }
);

export const setSuccessPasswordRecoveryForm = (payload) => (
	{ type: getActionName(SET, SUCCESS, PASSWORD, RECOVERY, FORM), payload }
);

export const setStatusPasswordReset = (payload) => (
	{ type: getActionName(SET, STATUS, PASSWORD, RESET, FORM), payload }
);

export const submitPasswordResetForm = (payload) => (
	{ type: getActionName(SUBMIT, PASSWORD, RESET, FORM), payload }
);

export const setCurrentUserId = (payload) => (
	{ type: getActionName(SET, CURRENT, USER, ID), payload }
);

export const setUserRole = (payload) => (
	{ type: getActionName(SET, USER, ROLE), payload }
);

export const getUsersData = (payload) => (
	{ type: getActionName(GET, USERS, DATA), payload }
);

export const setUsersData = (payload) => (
	{ type: getActionName(SET, USERS, DATA), payload }
);

export const setServicesData = (payload) => (
	{ type: getActionName(SET, SERVICES, DATA), payload }
);

export const getIncidentsData = (payload) => (
	{ type: getActionName(GET, INCIDENTS, DATA), payload }
);

export const setIncidentsData = (payload) => (
	{ type: getActionName(SET, INCIDENTS, DATA), payload }
);

export const setErrorDetails = (payload) => (
	{ type: getActionName(SET, ERROR, DETAILS), payload }
);

export const getUser = (payload) => (
	{ type: getActionName(GET, USER), payload }
);

export const setUser = (payload) => (
	{ type: getActionName(SET, USER), payload }
);

export const getIncident = (payload) => (
	{ type: getActionName(GET, INCIDENT), payload }
);

export const setIncident = (payload) => (
	{ type: getActionName(SET, INCIDENT), payload }
);

export const getService = (payload) => (
	{ type: getActionName(GET, SERVICE), payload }
);

export const setService = (payload) => (
	{ type: getActionName(SET, SERVICE), payload }
);

export const getCustomer = (payload) => (
	{ type: getActionName(GET, CUSTOMER), payload }
);

export const setCustomer = (payload) => (
	{ type: getActionName(SET, CUSTOMER), payload }
);

export const getCustomersData = (payload) => (
	{ type: getActionName(GET, CUSTOMERS, DATA), payload }
);

export const setCustomersData = (payload) => (
	{ type: getActionName(SET, CUSTOMERS, DATA), payload }
);

export const getStatusUpdate = (payload) => (
	{ type: getActionName(GET, STATUS, UPDATE), payload }
);

export const setStatusUpdate = (payload) => (
	{ type: getActionName(SET, STATUS, UPDATE), payload }
);

export const getStatusUpdatesData = (payload) => (
	{ type: getActionName(GET, STATUS, UPDATES, DATA), payload }
);

export const setStatusUpdatesData = (payload) => (
	{ type: getActionName(SET, STATUS, UPDATES, DATA), payload }
);

export const getEventsLog = (payload) => (
	{ type: getActionName(GET, EVENTS, LOG), payload }
);

export const setEventsLog = (payload) => (
	{ type: getActionName(SET, EVENTS, LOG), payload }
);

export const submitLogInForm = (payload) => (
	{ type: getActionName(SUBMIT, LOGIN, FORM), payload }
);

export const submitLogOut = () => (
	{ type: getActionName(SUBMIT, LOGOUT) }
);

export const submitCreateEditUserForm = (payload) => (
	{ type: getActionName(SUBMIT, CREATE, EDIT, USER, FORM), payload }
);

export const deleteUser = (payload) => (
	{ type: getActionName(DELETE, USER), payload }
);

export const submitCreateEditServiceForm = (payload) => (
	{ type: getActionName(SUBMIT, CREATE, EDIT, SERVICE, FORM), payload }
);

export const deleteService = (payload) => (
	{ type: getActionName(DELETE, SERVICE), payload }
);

export const submitCreateEditCustomerForm = (payload) => (
	{ type: getActionName(SUBMIT, CREATE, EDIT, CUSTOMER, FORM), payload }
);

export const deleteCustomer = (payload) => (
	{ type: getActionName(DELETE, CUSTOMER), payload }
);

export const submitCreateEditStatusUpdateForm = (payload) => (
	{ type: getActionName(SUBMIT, CREATE, EDIT, STATUS, UPDATE, FORM), payload }
);

export const deleteStatusUpdate = (payload) => (
	{ type: getActionName(DELETE, STATUS, UPDATE), payload }
);

export const getIncidentPreview = (payload) => (
	{ type: getActionName(GET, INCIDENT, PREVIEW), payload }
);

export const submitCreateEditIncidentForm = (payload) => (
	{ type: getActionName(SUBMIT, CREATE, EDIT, INCIDENT, FORM), payload }
);

export const submitPrStatementForm = (payload) => (
	{ type: getActionName(SUBMIT, PR, STATEMENT, FORM), payload }
);

export const getUserRolesSelectsData = () => (
	{ type: getActionName(GET, USER, ROLES, SELECTS, DATA) }
);

export const setUserRolesSelectsData = (payload) => (
	{ type: getActionName(SET, USER, ROLES, SELECTS, DATA), payload }
);

export const getSelectsData = (payload) => (
	{ type: getActionName(GET, SELECTS, DATA), payload }
);

export const setSelectsData = (payload) => (
	{ type: getActionName(SET, SELECTS, DATA), payload }
);

export const changeStatus = (payload) => (
	{ type: getActionName(CHANGE, STATUS), payload }
);

export const setSearchQuery = (payload) => (
	{ type: getActionName(SET, SEARCH, QUERY), payload }
);

export const setSortType = (payload) => (
	{ type: getActionName(SET, SORT, TYPE), payload }
);

export const setSortDirection = (payload) => (
	{ type: getActionName(SET, SORT, DIRECTION), payload }
);

export const setFilterQueryParams = (payload) => (
	{ type: getActionName(SET, FILTER, QUERY, PARAMS), payload }
);

export const setDateFilterBefore = (payload) => (
	{ type: getActionName(SET, DATE, FILTER, BEFORE), payload }
);

export const setDateFilterAfter = (payload) => (
	{ type: getActionName(SET, DATE, FILTER, AFTER), payload }
);

export const setDateFilterType = (payload) => (
	{ type: getActionName(SET, DATE, FILTER, TYPE), payload }
);

export const applyFilter = (payload) => (
	{ type: getActionName(APPLY, FILTER), payload }
);

export const updateFilterParams = (payload) => (
	{ type: getActionName(UPDATE, FILTER, PARAMS), payload }
);

export const setFilterChecked = (payload) => (
	{ type: getActionName(SET, FILTER, CHECKED), payload }
);

export const setFilterCheckedAll = (payload) => (
	{ type: getActionName(SET, FILTER, CHECKED, ALL), payload }
);

export const setCustomizeView = (payload) => (
	{ type: getActionName(SET, CUSTOMIZE, VIEW), payload }
);

export const setAllCustomizeView = (payload) => (
	{ type: getActionName(SET, ALL, CUSTOMIZE, VIEW), payload }
);

export const setAllIncidentCount = (payload) => (
	{ type: getActionName(SET, ALL, INCIDENT, COUNT), payload }
);

export const getProvinceIncidentCount = () => (
	{ type: getActionName(GET, PROVINCE, INCIDENT, COUNT) }
);

export const setProvinceIncidentCount = (payload) => (
	{ type: getActionName(SET, PROVINCE, INCIDENT, COUNT), payload }
);

export const setShowIncidentsMap = (payload) => (
	{ type: getActionName(SET, SHOW, INCIDENTS, MAP), payload }
);

export const setLang = (payload) => (
	{ type: getActionName(SET, LANG), payload }
);

export const setFilterCounter = (payload) => (
	{ type: getActionName(SET, FILTER, COUNTER), payload }
);

export const getServicesData = (payload) => (
	{ type: getActionName(GET, SERVICES, DATA), payload }
);

export const getIncidentsCount = (payload) => (
	{ type: getActionName(GET, INCIDENTS, COUNT), payload }
);

export const setIncidentsCount = (payload) => (
	{ type: getActionName(SET, INCIDENTS, COUNT), payload }
);

// ================= SAGAS
function* apiRequest(payload) {
	try {
		const {
			url,
			type,
			requestObj,
			lng,
		} = payload;

		console.log(url);

		const data = yield call(() => axiosInstance(type, lng)[type.toLowerCase()](url, requestObj));

		counterRefreshToken = 0;

		return data;
	} catch (err) {
		if (counterRefreshToken === MAX_COUNTER_REFRESH_TOKEN) {
			yield call(updateToken);

			return null;
		}

		if (err.response.status === 401) {
			counterRefreshToken += 1;

			return yield call(() => refreshTokenSaga(payload));
		} else if (err.response.status === 400 || err.response.status === 404) {
			return err.response;
		}
	}

	return null;
}

function* submitPasswordRecoveryFormSaga(action) {
	try {
		const { payload } = action;
		const { email } = payload;

		yield put(setIsLoading(true));
		yield put(setErrorDetails(''));

		try {
			const url = '/api/user_reset_password_tokens';

			yield call(() => axiosInstance().post(url, { email }));

			yield put(setSuccessPasswordRecoveryForm(true));

			yield put(setIsLoading(false));
		} catch (err) {
			if (err.response.status === 400) {
				yield put(setErrorDetails(err.response.data.violations[0].message));
				yield put(setIsLoading(false));
			}
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* submitPasswordResetFormSaga(action) {
	try {
		const { payload } = action;
		const { plainPassword, token } = payload;

		yield put(setIsLoading(true));
		yield put(setErrorDetails(''));

		try {
			const url = '/api/user_reset_password_tokens/reset_password';

			const { data } = yield call(() => axiosInstance().post(url, { plainPassword, token }));

			if (data['hydra:description']) {
				yield put(setStatusPasswordReset('error'));
			} else {
				yield put(setStatusPasswordReset('success'));
			}

			yield put(setIsLoading(false));
		} catch (err) {
			if (err.response.status === 400) {
				yield put(setErrorDetails(err.response.data.violations[0].message));
				yield put(setIsLoading(false));
			}
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* updateToken(token = '', refreshToken = '') {
	try {
		yield put(setToken({ token }));
		yield setItemLocalStorage(LOCAL_STORAGE_TOKEN_KEY, token);
		yield setItemLocalStorage(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken);
	} catch (e) {
		// empty error
	}
}

function* refreshTokenSaga(payload) {
	try {
		const refreshTokenForRequest = yield getItemFromLocalStorage(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
		const url = '/token/refresh';
		const requestData = {
			refresh_token: refreshTokenForRequest,
		};

		const { data } = yield call(() => axiosInstance().post(url, requestData));
		const { token, refresh_token: refreshToken } = data;

		yield call(() => updateToken(token, refreshToken));

		return yield call(() => apiRequest(payload));
	} catch (e) {
		yield call(() => updateToken());

		return null;
	}
}

function* initAuthSaga() {
	try {
		const token = yield getItemFromLocalStorage(LOCAL_STORAGE_TOKEN_KEY);
		const lang = yield getItemFromLocalStorage('lang');
		const customizeView = yield getItemFromLocalStorage(LOCAL_STORAGE_CUSTOMIZE_VIEW);

		if (!customizeView) {
			const customizeViewOld = yield getItemFromLocalStorage(LOCAL_STORAGE_CUSTOMIZE_VIEW_OLD);

			if (customizeViewOld) {
				yield removeItemFromLocalStorage(LOCAL_STORAGE_CUSTOMIZE_VIEW_OLD);
			}
		}

		if (token) {
			yield put(setToken({ token }));

			const currentUserId = jwt_decode(token).user_id;
			const userRole = jwt_decode(token).roles[0];

			if (currentUserId) {
				yield put(setCurrentUserId({ id: currentUserId }));
			}

			if (userRole) {
				yield put(setUserRole({ role: userRole }));
			}
		}

		if (customizeView) {
			yield put(setAllCustomizeView(fromJS(JSON.parse(customizeView))));
		}

		if (lang) {
			yield put(setLang(lang));
		}

		yield put(setInit(true));
		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* submitLogInFormSaga(action) {
	try {

		const payload = {
		        auth : {
		                username: action.payload.email,
		                password: action.payload.password
		        }
		};
		//const { payload } = action;
		//const { email, password, callback } = payload;

		yield put(setIsLoading(true));
		yield put(setErrorDetails(''));

		try {
			const url = '/authentication_token';

			const { data } = yield call(() => axiosInstance().post(
				url, 
				{ },
				payload
			));
			const { token, refresh_token: refreshToken } = data;

			yield call(() => updateToken(token, refreshToken));

			const currentUserId = jwt_decode(token).user_id;
			const userRole = jwt_decode(token).roles[0];

			if (currentUserId) {
				yield put(setCurrentUserId({ id: currentUserId }));
			}

			if (userRole) {
				yield put(setUserRole({ role: userRole }));
			}
		} catch (err) {
			if (err.response.status === 401) {
				yield put(setErrorDetails("Incorrect Username or Password"));
				yield put(setIsLoading(false));
			}
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* submitLogOutSaga() {
	try {
		yield call(() => updateToken());
		yield put(setUserRole({ role: '' }));
	} catch (error) {
		// empty error
	}
}

function* getUsersDataSaga(action) {
	try {
		const { payload } = action;

		yield put(setIsLoading(true));

		const url = payload;
		const type = GET;
		const { data } = yield call(() => apiRequest({ url, type }));
		const hydraView = data['hydra:view'] || {};

		yield put(setUsersData(fromJS({
			list: data['hydra:member'],
			totalItems: data['hydra:totalItems'],
			firstPage: hydraView['hydra:first'],
			lastPage: hydraView['hydra:last'],
			nextPage: hydraView['hydra:next'] || '',
			previousPage: hydraView['hydra:previous'] || '',
		})));

		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* getIncidentsDataSaga(action) {
	try {
		const { payload } = action;
		const state = yield select();
		yield put(setIsLoading(true));

		let url = payload;
		const type = GET;
		const role = state.get('userRole');

		if(role === "ROLE_EWII_USER") {
			if(url === "/api/incidents") {
				url = `${url}?subServices.mainService.id[]=1`;
			} else {
				url = `${url}&subServices.mainService.id[]=1`;
			}
		} 
		const { data } = yield call(() => apiRequest({ url, type }));
		const hydraView = data['hydra:view'] || {};

		yield put(setIncidentsData(fromJS({
			list: data['hydra:member'],
			totalItems: data['hydra:totalItems'],
			firstPage: hydraView['hydra:first'],
			lastPage: hydraView['hydra:last'],
			nextPage: hydraView['hydra:next'] || '',
			previousPage: hydraView['hydra:previous'] || '',
		})));

		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

//new P2 requirement
function* getIncidentsCountSaga(action) {
	try {
		const { payload } = action;

		yield put(setIsLoading(true));

		const url = payload;
		const type = GET;
		const { data } = yield call(() => apiRequest({ url, type }));

		yield put(setIncidentsCount(fromJS(data)));

		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* getUserSaga(action) {
	try {
		yield put(setIsLoading(true));

		const { payload } = action;
		const { id, redirectCallback } = payload;

		const url = `/api/users/${id}`;
		const type = GET;
		const { data, status } = yield call(() => apiRequest({ url, type }));

		if (status === 404) {
			yield redirectCallback('/incidents');
		} else {
			yield put(setUser(fromJS(data)));
			yield put(setIsLoading(false));
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* getIncidentSaga(action) {
	try {
		yield put(setIsLoading(true));

		const { payload } = action;
		const { id, redirectCallback } = payload;

		const url = `/api/incidents/${id}`;
		const type = GET;
		const { data, status } = yield call(() => apiRequest({ url, type }));

		if (status === 404) {
			yield redirectCallback('/incidents');
		} else {
			yield put(setIncident(fromJS(data)));
			yield put(setIsLoading(false));
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* getServiceSaga(action) {
	try {
		yield put(setIsLoading(true));

		const { payload } = action;
		const { id, redirectCallback } = payload;

		const url = `/api/main_services/${id}`;
		const type = GET;
		const { data, status } = yield call(() => apiRequest({ url, type }));

		if (status === 404) {
			yield redirectCallback('/services');
		} else {
			yield put(setService(fromJS(data)));
			yield put(setIsLoading(false));
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* getCustomerSaga(action) {
	try {
		yield put(setIsLoading(true));

		const { payload } = action;
		const { id, redirectCallback } = payload;

		const url = `/api/customers/${id}`;
		const type = GET;
		const { data, status } = yield call(() => apiRequest({ url, type }));

		if (status === 404) {
			yield redirectCallback('/customers');
		} else {
			yield put(setCustomer(fromJS(data)));
			yield put(setIsLoading(false));
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* getStatusUpdateSaga(action) {
	try {
		yield put(setIsLoading(true));

		const { payload } = action;
		const { id, redirectCallback } = payload;

		const url = `/api/incident_event_comment_groups/${id}`;
		const type = GET;
		const { data, status } = yield call(() => apiRequest({ url, type }));

		if (status === 404) {
			yield redirectCallback('/status-updates');
		} else {
			yield put(setStatusUpdate(fromJS(data)));
			yield put(setIsLoading(false));
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* getEventsLogSaga(action) {
	try {
		yield put(setIsLoading(true));

		const { payload } = action;
		const { id } = payload;

		const url = `/api/incident_events?incident.id=${id}&eventName=incident.comment.created`;
		const eventsLog = yield call(() => getDataFromAllPageSaga(url, true));

		yield put(setEventsLog(fromJS(eventsLog)));
		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* getDataFromAllPageSaga(url, hasParams, lng) {
	try {
		const type = GET;
		const { data } = yield call(() => apiRequest({ url, type, lng }));

		let member = data['hydra:member'];
		const totalItems = data['hydra:totalItems'];
		const counterPage = Math.ceil(totalItems/member.length);

		for (let i = 2; i <= counterPage; i++) {
			const { data: dataLocal } = yield call(() => apiRequest({ url: `${url}${hasParams ? '&' : '?'}page=${i}`, type, lng }));

			member = [
				...member,
				...dataLocal['hydra:member'],
			];
		}

		return member;
	} catch (e) {
		return [];
	}
}

function* getServicesDataSaga(action) {
	try {
		const { payload } = action;

		yield put(setIsLoading(true));

		const url = payload.indexOf('?page') !== -1 ? payload + '&withSubServices=true' : payload + '?withSubServices=true';
		const type = GET;
		const { data } = yield call(() => apiRequest({ url, type }));
		const hydraView = data['hydra:view'] || {};

		yield put(setServicesData(fromJS({
			list: data['hydra:member'],
			totalItems: data['hydra:totalItems'],
			firstPage: hydraView['hydra:first'],
			lastPage: hydraView['hydra:last'],
			nextPage: hydraView['hydra:next'] || '',
			previousPage: hydraView['hydra:previous'] || '',
		})));

		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* getStatusUpdatesDataSaga(action) {
	try {
		const { payload } = action;

		yield put(setIsLoading(true));

		const url = payload;
		const type = GET;
		const { data } = yield call(() => apiRequest({ url, type }));
		const hydraView = data['hydra:view'] || {};

		yield put(setStatusUpdatesData(fromJS({
			list: data['hydra:member'],
			totalItems: data['hydra:totalItems'],
			firstPage: hydraView['hydra:first'],
			lastPage: hydraView['hydra:last'],
			nextPage: hydraView['hydra:next'] || '',
			previousPage: hydraView['hydra:previous'] || '',
		})));

		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* getCustomersDataSaga(action) {
	try {
		const { payload } = action;

		yield put(setIsLoading(true));

		const url = payload;
		const type = GET;
		const { data } = yield call(() => apiRequest({ url, type }));
		const hydraView = data['hydra:view'] || {};

		yield put(setCustomersData(fromJS({
			list: data['hydra:member'],
			totalItems: data['hydra:totalItems'],
			firstPage: hydraView['hydra:first'],
			lastPage: hydraView['hydra:last'],
			nextPage: hydraView['hydra:next'] || '',
			previousPage: hydraView['hydra:previous'] || '',
		})));

		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* getSelectsDataSaga(action) {
	try {
		yield put(setIsLoading(true));

		const {
			payload
		} = action;

		let checkedFilters = '';

		if (payload) {
			const query = new URLSearchParams(payload);

			checkedFilters = {
				incidentOwner: query.getAll('incidentOwner.id[]'),
				type: query.getAll('type[]'),
				status: query.getAll('incidentStatus.id[]'),
				severity: query.getAll('incidentSeverity.id[]'),
				rootCause: query.getAll('rootCause.id[]'),
				serviceType: query.getAll('subServices.mainService.id[]'),
				affectedUsers: query.getAll('affectedUsersNumber.id[]'),
				location: query.getAll('incidentProvinceId[]'),
			};
		}

		let state = yield select();

		const incidentStatus = yield call(() => getDataFromAllPageSaga('/api/incident_statuses'));

		const [
			incidentOwner,
			incidentVendor,
			rootCauseEn,
			rootCauseDa,
			customerAffectedByName,
			affectedUsersNumbers,
			rootCause,
			mainServiceAffected,
			continents,
			subServiceAffected,
			incidentSeverity,
			eventComments,
		] = yield all([
			getDataFromAllPageSaga('/api/incident_owners'),
			getDataFromAllPageSaga('/api/incident_vendors'),
			getDataFromAllPageSaga('/api/root_causes?isOther=no', false, EN),
			getDataFromAllPageSaga('/api/root_causes?isOther=no', false, DA),
			getDataFromAllPageSaga('/api/customers'),
			getDataFromAllPageSaga('/api/affected_users_numbers?isOther=no'),
			getDataFromAllPageSaga('/api/root_causes?isOther=no'),
			getDataFromAllPageSaga('/api/main_services'),
			getDataFromAllPageSaga('/api/continents'),
			getDataFromAllPageSaga('/api/sub_services'),
			getDataFromAllPageSaga('/api/incident_severities'),
			getDataFromAllPageSaga('/api/incident_event_comment_groups'),
		]);

		const province = [];

		continents.forEach((continent) => {
			continent.countries.forEach((country) => {
				const {
					isEmulatedAsBool,
					name,
					provinces,
				} = country;

				if (isEmulatedAsBool) {
					province.push({
						value: provinces[0].id,
						label: name,
						checked: false,
						hasChild: false,
					});
				} else {
					province.push({
						value: country['@id'],
						label: name,
						checked: false,
						hasChild: true,
					});

					provinces.forEach((provinceLocal) => {
						province.push({
							value: provinceLocal.id,
							label: provinceLocal.name,
							parentId: country['@id'],
							hasParent: true,
							checked: false,
							hasChild: false,
						});
					});
				}
			});
		});

		const selectsData = {
			incidentOwner,
			incidentVendor,
			rootCauseEn,
			rootCauseDa,
			customerAffectedByName,
			affectedUsersNumbers,
			rootCause,
			mainServiceAffected,
			province,
			continents,
			subServiceAffected,
			incidentSeverity,
			incidentStatus,
			eventComments,
		};

		yield put(setSelectsData(fromJS(selectsData)));

		if (checkedFilters) {
			yield put(setFilterQueryParams(checkedFilters));

			state = yield select();

			const filter = state.get('filter');

			const severityChecked = filter.get('severity').filter((i) => i.get('checked'));
			const statusChecked = filter.get('status').filter((i) => i.get('checked'));
			const serviceTypeChecked = filter.get('serviceType').filter((i) => i.get('checked'));
			const affectedUsersChecked = filter.get('affectedUsers').filter((i) => i.get('checked'));
			const locationChecked = filter.get('location').filter((i) => i.get('checked'));
			const incidentOwnerChecked = filter.get('incidentOwner').filter((i) => i.get('checked'));
			const rootCauseChecked = filter.get('rootCause').filter((i) => i.get('checked'));
			const typeChecked = filter.get('type').filter((i) => i.get('checked'));

			const counterFilter = severityChecked.size
				+ statusChecked.size
				+ affectedUsersChecked.size
				+ serviceTypeChecked.size
				+ typeChecked.size
				+ rootCauseChecked.size
				+ incidentOwnerChecked.size
				+ locationChecked.size;

			yield put(setFilterCounter(counterFilter));
		}

		yield put(setIsLoading(false));
	} catch (error) {
		// empty error
	}
}

function* deleteUserSaga(action) {
	try {
		const { payload } = action;
		const {
			id,
			redirectCallback,
		} = payload;

		yield put(setIsLoading(true));

		const url = `/api/users/${id}`;
		const type = DELETE;

		const res = yield call(() => apiRequest({ url, type }));

		if (res.status === 400) {
			const errDetails = res.data.violations[0].message;

			yield put(setErrorDetails(errDetails));
		} else {
			yield redirectCallback('/users');
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* deleteServiceSaga(action) {
	try {
		const { payload } = action;
		const {
			id,
			redirectCallback,
		} = payload;

		yield put(setIsLoading(true));

		const url = `/api/main_services/disable/${id}`;
		const type = PATCH;

		const res = yield call(() => apiRequest({ url, type, requestObj: {} }));

		if (res.status === 400) {
			const errDetails = res.data.violations[0].message;

			yield put(setErrorDetails(errDetails));
		} else {
			yield redirectCallback('/services');
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* deleteCustomerSaga(action) {
	try {
		const { payload } = action;
		const {
			id,
			redirectCallback,
		} = payload;

		yield put(setIsLoading(true));

		const url = `/api/customers/soft_delete/${id}`;
		const type = PATCH;

		const res = yield call(() => apiRequest({ url, type, requestObj: {} }));

		if (res.status === 400) {
			const errDetails = res.data.violations[0].message;

			yield put(setErrorDetails(errDetails));
		} else {
			yield redirectCallback('/customers');
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* deleteStatusUpdateSaga(action) {
	try {
		const { payload } = action;
		const {
			id,
			redirectCallback,
		} = payload;

		yield put(setIsLoading(true));

		const url = `/api/incident_event_comment_groups/delete/${id}`;
		const type = DELETE;

		const res = yield call(() => apiRequest({ url, type, requestObj: {} }));

		if (res.status === 400) {
			const errDetails = res.data.violations[0].message;

			yield put(setErrorDetails(errDetails));
		} else {
			yield redirectCallback('/status-updates');
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* submitCreateEditUserFormSaga(action) {
	try {
		const { payload } = action;
		const {
			formData,
			id,
			redirectCallback,
		} = payload;

		yield put(setIsLoading(true));
		yield put(setErrorDetails(''));

		const url = id ? `/api/users/${id}` : '/api/users';
		const type = id ? PATCH : POST;
		const res = yield call(() => apiRequest({ url, type, requestObj: formData }));

		if (res.status === 400) {
			const errDetails = res.data.violations[0].message;

			yield put(setErrorDetails(errDetails));
		} else {
			yield redirectCallback('/users')
		}

		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* submitCreateEditServiceFormSaga(action) {
	try {
		const { payload } = action;
		const {
			id,
			formData,
			redirectCallback,
		} = payload;

		yield put(setIsLoading(true));
		yield put(setErrorDetails(''));

		const url = id ? `/api/sub_services/process_custom_item/${id}` : '/api/sub_services/process_custom_item';
		const type = id ? PATCH : POST;

		const res = yield call(() => apiRequest({ url, type, requestObj: formData }));

		if (res.status === 400) {
			const errDetails = res.data.error;

			yield put(setErrorDetails(errDetails));
		} else {
			yield redirectCallback('/services')
		}

		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* submitCreateEditCustomerFormSaga(action) {
	try {
		const { payload } = action;
		const {
			id,
			formData,
			redirectCallback,
		} = payload;

		yield put(setIsLoading(true));
		yield put(setErrorDetails(''));

		const url = id ? `/api/customers/${id}` : '/api/customers/admin_create';
		const type = id ? PATCH : POST;
		const res = yield call(() => apiRequest({ url, type, requestObj: formData }));

		if (res.status === 400) {
			const errDetails = res.data.violations[0].message;

			yield put(setErrorDetails(errDetails));
		} else {
			yield redirectCallback('/customers')
		}

		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* submitCreateEditStatusUpdateFormSaga(action) {
	try {
		const { payload } = action;
		const {
			id,
			formData,
			redirectCallback,
		} = payload;

		yield put(setIsLoading(true));
		yield put(setErrorDetails(''));

		const url = id ? `/api/incident_event_comment_groups/update/${id}` : '/api/incident_event_comment_groups/admin_create';
		const type = id ? PATCH : POST;
		const res = yield call(() => apiRequest({ url, type, requestObj: formData }));

		if (res.status === 400) {
			const errDetails = res.data.violations[0].message;

			yield put(setErrorDetails(errDetails));
		} else {
			yield redirectCallback('/status-updates')
		}

		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* getIncidentPreviewSaga(action) {
	try {
		const { payload } = action;
		const {
			formData,
			setIncidentPreview,
			setConfirmPreview,
		} = payload;

		yield put(setIsLoading(true));
		yield put(setErrorDetails(''));

		const url = '/api/incidents/preview';
		const type = POST;
		const { data, status } = yield call(() => apiRequest({ url, type, requestObj: formData }));

		if (status === 400) {
			const errDetails = data.violations[0].message;

			yield put(setErrorDetails(errDetails));
			yield put(setIsLoading(false));
		} else {
			yield setIncidentPreview(fromJS(data));
			yield setConfirmPreview(true);
			yield put(setIsLoading(false));
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* submitCreateEditIncidentFormSaga(action) {
	try {
		const { payload } = action;
		const {
			formData,
			id,
			redirectCallback,
		} = payload;

		yield put(setIsLoading(true));
		yield put(setErrorDetails(''));

		const url = id ? `/api/incidents/${id}` : '/api/incidents';
		const type = id ? PATCH : POST;
		const res = yield call(() => apiRequest({ url, type, requestObj: formData }));

		if (res.status === 400) {
			const errDetails = res.data.violations[0].message;

			yield put(setErrorDetails(errDetails));
			yield put(setIsLoading(false));
		} else {
			yield redirectCallback('/incidents');
		}
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* submitPrStatementFormSaga(action) {
	try {
		const { payload } = action;
		const {
			edit,
			formData,
			id,
			t,
		} = payload;

		yield put(setIsLoading(true));
		yield put(setErrorDetails(''));

		const url = edit ? `/api/incident_pr_statements/${id}` : '/api/incident_pr_statements';
		const type = edit ? PATCH : POST;

		let requestObj = {};

		if (edit) {
			requestObj = {...formData};
		} else {
			requestObj = {
				...formData,
				incident: '/api/incidents/' + id,
			};
		}

		const res = yield call(() => apiRequest({ url, type, requestObj: requestObj }));

		if (res.status === 200 || res.status === 201) {
			yield put(setErrorDetails(t('incident.statementSuccessfullyChanged')));
			yield call(() => getIncidentSaga({ payload: { id } }));
			yield call(() => getEventsLogSaga({ payload: { id } }));
		}

		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* changeStatusSaga(action) {
	try {
		const { payload } = action;
		const { id, status, requestUrl } = payload;

		yield put(setIsLoading(true));

		const url = `/api/incidents/${id}`;
		const type = PATCH;
		const res  = yield call(() => apiRequest({ url, type, requestObj: { incidentStatus: status} }));

		if (res.status === 200) {
			yield call(() => getIncidentsDataSaga({ payload: requestUrl }));
		}

		yield put(setIsLoading(false));
	} catch (error) {
		yield put(setIsLoading(false));
	}
}

function* applyFilterSaga(action) {
	try {
		const { payload } = action;
		const { redirectCallback, formData } = payload;

		yield put(updateFilterParams(formData));

		const state = yield select();

		const filter = state.get('filter');
		let searchQuery = state.get('searchQuery');
		let timeAfter = state.get('timeAfter');
		let timeBefore = state.get('timeBefore');
		let timeType = state.get('timeType');
		let sortType = state.get('sortType');
		let sortDirection = state.get('sortDirection');

		if (formData.searchQuery === undefined) {
			yield put(setSearchQuery(searchQuery));
		} else {
			yield put(setSearchQuery(formData.searchQuery));
			searchQuery = formData.searchQuery;
		}

		if (formData.sortType) {
			if (formData.sortType === sortType) {
				if (sortDirection === 'desc') {
					yield put(setSortDirection('asc'));
					sortDirection = 'asc';
				} else {
					yield put(setSortDirection('desc'));
					sortDirection = 'desc';
				}
			} else {
				yield put(setSortDirection('desc'));
				sortDirection = 'desc';
			}
			yield put(setSortType(formData.sortType));
			sortType = formData.sortType;
		} else {
			yield put(setSortType(sortType));
		}

		if (formData.timeAfter) {
			yield put(setDateFilterAfter(formData.timeAfter));
			timeAfter = formData.timeAfter;
		} else {
			if (Object.keys(formData).length > 0 && !formData.sortType) {
				timeAfter = '';
			}
			yield put(setDateFilterAfter(timeAfter));
		}

		if (formData.timeBefore) {
			yield put(setDateFilterBefore(formData.timeBefore));
			timeBefore = formData.timeBefore;
		} else {
			if (Object.keys(formData).length > 0 && !formData.sortType) {
				timeBefore = '';
			}
			yield put(setDateFilterBefore(timeBefore));
		}

		if (formData.timeType) {
			yield put(setDateFilterType(formData.timeType));
			timeType = formData.timeType;
		} else {
			yield put(setDateFilterType(timeType));
		}

		const severityChecked = filter.get('severity').filter((i) => i.get('checked'));
		const statusChecked = filter.get('status').filter((i) => i.get('checked'));
		const serviceTypeChecked = filter.get('serviceType').filter((i) => i.get('checked'));
		const affectedUsersChecked = filter.get('affectedUsers').filter((i) => i.get('checked'));
		const locationChecked = filter.get('location').filter((i) => i.get('checked'));
		const incidentOwnerChecked = filter.get('incidentOwner').filter((i) => i.get('checked'));
		const rootCauseChecked = filter.get('rootCause').filter((i) => i.get('checked'));
		const typeChecked = filter.get('type').filter((i) => i.get('checked'));

		const counterFilter = severityChecked.size
			+ statusChecked.size
			+ affectedUsersChecked.size
			+ serviceTypeChecked.size
			+ typeChecked.size
			+ rootCauseChecked.size
			+ incidentOwnerChecked.size
			+ locationChecked.size;

		let requestUrl = '/api/incidents?';

		if (searchQuery) {
			requestUrl += `customSearch=${searchQuery}&`;
		}

		if (timeAfter) {
			requestUrl += `timeStart[after]=${timeAfter.slice(0, 10)}%2000%3A00%3A00&`;
		}

		if (timeBefore) {
			requestUrl += `timeStart[before]=${timeBefore.slice(0, 10)}%2023%3A59%3A59&`;
		}

		if (timeType) {
			requestUrl += `dt=${timeType}&`;
		}

		if (sortType) {
			requestUrl += `order[${sortType}]=${sortDirection}&`;
		}

		if (severityChecked.size) {
			severityChecked.forEach((i) => {
				requestUrl += `incidentSeverity.id[]=${i.get('value')}&`;
			});
		}

		if (statusChecked.size) {
			statusChecked.forEach((i) => {
				requestUrl += `incidentStatus.id[]=${i.get('value')}&`;
			});
		}

		if (rootCauseChecked.size) {
			rootCauseChecked.forEach((i) => {
				requestUrl += `rootCause.id[]=${i.get('value')}&`;
			});
		}

		if (incidentOwnerChecked.size) {
			incidentOwnerChecked.forEach((i) => {
				requestUrl += `incidentOwner.id[]=${i.get('value')}&`;
			});
		}

		if (affectedUsersChecked.size) {
			affectedUsersChecked.forEach((i) => {
				requestUrl += `affectedUsersNumber.id[]=${i.get('value')}&`;
			});
		}

		if (serviceTypeChecked.size) {
			serviceTypeChecked.forEach((i) => {
				requestUrl += `subServices.mainService.id[]=${i.get('value')}&`;
			});
		}

		if (locationChecked.size) {
			locationChecked.forEach((i) => {
				if (!i.get('hasChild')) {
					requestUrl += `incidentProvinceId[]=${i.get('value')}&`;
				}
			});
		}

		if (typeChecked.size) {
			typeChecked.forEach((i) => {
				requestUrl += `type[]=${i.get('value')}&`;
			});
		}

		// remove last ampersand
		requestUrl = requestUrl.slice(0, -1);

		yield put(setFilterCounter(counterFilter));

		if (timeType === 'all') {
			const query = new URLSearchParams(requestUrl.replace('/api/incidents?', ''));
			query.delete('timeStart[after]');
			query.delete('timeStart[before]');
			requestUrl = '/api/incidents?' + query.toString();
		}

		//console.log("Seach Filters ---> " , requestUrl);
		yield call(() => getIncidentsDataSaga({ payload: requestUrl }));
		yield redirectCallback(requestUrl.replace('/api/incidents', ''));
		yield setItemLocalStorage(LOCAL_STORAGE_FILTER, requestUrl.replace('/api/incidents', ''));
	} catch (error) {
		// empty error
	}
}

function* getUserRolesSelectsDataSaga() {
	try {
		yield put(setIsLoading(true));

		const res = yield call(() => getDataFromAllPageSaga('/api/roles'));

		yield put(setUserRolesSelectsData(fromJS(res)));
		yield put(setIsLoading(false));
	} catch (error) {
		// empty error
	}
}

export function* getProvinceIncidentCountSaga() {
	try {
		yield put(setIsLoading(true));

		const countResponse = yield call(() => apiRequest({
			url: '/api/province_incident_to_status_counts',
			type: GET,
		}));

		const severityResponse = yield call(() => apiRequest({
			url: '/api/province_to_critical_severities',
			type: GET,
		}));

		const listCounts = countResponse.data['hydra:member'];
		const listSeverity = severityResponse.data['hydra:member'];

		const result = {};

		listCounts.forEach((item) => {
			if (allowedProvince.includes(item.province.id)) {
				result[item.province.id] = result[item.province.id] || {};

				result[item.province.id].id = item.province.id;
				result[item.province.id].name = item.province.name;

				if (item.incidentStatus.id === 2) {
					result[item.province.id].open = item.count;
				} else if (item.incidentStatus.id <= 6) {
					const prevResult = result[item.province.id].ongoing || 0;

					result[item.province.id].ongoing = prevResult + item.count;
				}
			}
		});


		listSeverity.forEach((item) => {
			if (allowedProvince.includes(item.id.id) && item.isCritical) {
				result[item.id.id].isCritical = true;
			}
		});

		yield put(setProvinceIncidentCount(fromJS(result)));

		yield put(setShowIncidentsMap(true));

		yield put(setIsLoading(false));
	} catch (e) {
		yield put(setIsLoading(false));
	}
}

export function* sagas() {
	yield all([
		yield takeEvery(getActionName(INIT, AUTH), initAuthSaga),
		yield takeEvery(getActionName(SUBMIT, PASSWORD, RECOVERY, FORM), submitPasswordRecoveryFormSaga),
		yield takeEvery(getActionName(SUBMIT, PASSWORD, RESET, FORM), submitPasswordResetFormSaga),
		yield takeEvery(getActionName(GET, USER, ROLES, SELECTS, DATA), getUserRolesSelectsDataSaga),
		yield takeEvery(getActionName(GET, SELECTS, DATA), getSelectsDataSaga),
		yield takeEvery(getActionName(GET, USERS, DATA), getUsersDataSaga),
		yield takeEvery(getActionName(GET, SERVICES, DATA), getServicesDataSaga),
		yield takeEvery(getActionName(GET, CUSTOMERS, DATA), getCustomersDataSaga),
		yield takeEvery(getActionName(GET, STATUS, UPDATES, DATA), getStatusUpdatesDataSaga),
		yield takeEvery(getActionName(GET, INCIDENTS, DATA), getIncidentsDataSaga),
		yield takeEvery(getActionName(GET, INCIDENT), getIncidentSaga),
		yield takeEvery(getActionName(GET, USER), getUserSaga),
		yield takeEvery(getActionName(GET, SERVICE), getServiceSaga),
		yield takeEvery(getActionName(GET, CUSTOMER), getCustomerSaga),
		yield takeEvery(getActionName(GET, STATUS, UPDATE), getStatusUpdateSaga),
		yield takeEvery(getActionName(GET, EVENTS, LOG), getEventsLogSaga),
		yield takeEvery(getActionName(SUBMIT, CREATE, EDIT, USER, FORM), submitCreateEditUserFormSaga),
		yield takeEvery(getActionName(DELETE, USER), deleteUserSaga),
		yield takeEvery(getActionName(SUBMIT, CREATE, EDIT, SERVICE, FORM), submitCreateEditServiceFormSaga),
		yield takeEvery(getActionName(DELETE, SERVICE), deleteServiceSaga),
		yield takeEvery(getActionName(SUBMIT, CREATE, EDIT, CUSTOMER, FORM), submitCreateEditCustomerFormSaga),
		yield takeEvery(getActionName(DELETE, CUSTOMER), deleteCustomerSaga),
		yield takeEvery(getActionName(SUBMIT, CREATE, EDIT, STATUS, UPDATE, FORM), submitCreateEditStatusUpdateFormSaga),
		yield takeEvery(getActionName(DELETE, STATUS, UPDATE), deleteStatusUpdateSaga),
		yield takeEvery(getActionName(GET, INCIDENT, PREVIEW), getIncidentPreviewSaga),
		yield takeEvery(getActionName(SUBMIT, CREATE, EDIT, INCIDENT, FORM), submitCreateEditIncidentFormSaga),
		yield takeEvery(getActionName(SUBMIT, PR, STATEMENT, FORM), submitPrStatementFormSaga),
		yield takeEvery(getActionName(SUBMIT, LOGIN, FORM), submitLogInFormSaga),
		yield takeEvery(getActionName(SUBMIT, LOGOUT), submitLogOutSaga),
		yield takeEvery(getActionName(CHANGE, STATUS), changeStatusSaga),
		yield takeEvery(getActionName(APPLY, FILTER), applyFilterSaga),
		yield takeEvery(getActionName(GET, PROVINCE, INCIDENT, COUNT), getProvinceIncidentCountSaga),
		yield takeEvery(getActionName(GET, INCIDENTS, COUNT), getIncidentsCountSaga)
	]);
}

// ================= REDUCERS
export default function reducer(state = initialState, action = {}) {
	const { type, payload } = action;

	switch (type) {
		case getActionName(SET, INIT):
			return state.set('init', payload);
		case getActionName(SET, LOADING):
			return state.set('isLoading', payload);
		case getActionName(SET, TOKEN):
			tokenCashed = payload.token;
			return state.set('token', payload.token);
		case getActionName(SET, CURRENT, USER, ID):
			return state.set('currentUserId', payload.id.toString());
		case getActionName(SET, USER, ROLE):
			return state.set('userRole', payload.role);
		case getActionName(SET, USER, ROLES, SELECTS, DATA):
			return state.set('userRolesSelectsData', payload);
		case getActionName(SET, USER):
			return state.set('user', payload);
		case getActionName(SET, SERVICE):
			return state.set('service', payload);
		case getActionName(SET, CUSTOMER):
			return state.set('customer', payload);
		case getActionName(SET, STATUS, UPDATE):
			return state.set('statusUpdate', payload);
		case getActionName(SET, LANG):
			language = payload;
			return state.set('lang', payload);
		case getActionName(SET, INCIDENT):
			return state.set('incident', payload);
		case getActionName(SET, EVENTS, LOG):
			return state.set('eventsLog', payload);
		case getActionName(SET, INCIDENTS, DATA):
			return state.update('incidentsData', (data) => data.merge(payload));
		case getActionName(SET, USERS, DATA):
			return state.update('usersData', (data) => data.merge(payload));
		case getActionName(SET, SERVICES, DATA):
			return state.set('servicesData', payload);
		case getActionName(SET, CUSTOMERS, DATA):
			return state.set('customersData', payload);
		case getActionName(SET, STATUS, UPDATES, DATA):
			return state.set('statusUpdatesData', payload);
		case getActionName(SET, ERROR, DETAILS):
			return state.set('errorMessage', payload);
		case getActionName(SET, CUSTOMIZE, VIEW):
			return state.updateIn(['customizeView', payload.name, 'checked'], (item) => !item);
		case getActionName(SET, ALL, CUSTOMIZE, VIEW):
			return state.set('customizeView', payload);
		case getActionName(SET, PROVINCE, INCIDENT, COUNT):
			return state.set('provinceIncidentCounts', payload);
		case getActionName(SET, ALL, INCIDENT, COUNT):
			return state.set('allIncidentCounts', payload);
		case getActionName(SET, SHOW, INCIDENTS, MAP):
			return state.set('showIncidentsMap', payload);
		case getActionName(SET, FILTER, QUERY, PARAMS):
			return state
				.updateIn(['filter', 'incidentOwner'], (item) => (
					item.map((i) => {
						if (payload.incidentOwner.includes(i.get('value').toString())) {
							return i.set('checked', true);
						}

						return i;
					})
				))
				.updateIn(['filter', 'type'], (item) => (
					item.map((i) => {
						if (payload.type.includes(i.get('value').toString())) {
							return i.set('checked', true);
						}

						return i;
					})
				))
				.updateIn(['filter', 'status'], (item) => (
					item.map((i) => {
						if (payload.status.includes(i.get('value').toString())) {
							return i.set('checked', true);
						}

						return i;
					})
				))
				.updateIn(['filter', 'severity'], (item) => (
					item.map((i) => {
						if (payload.severity.includes(i.get('value').toString())) {
							return i.set('checked', true);
						}

						return i;
					})
				))
				.updateIn(['filter', 'rootCause'], (item) => (
					item.map((i) => {
						if (payload.rootCause.includes(i.get('value').toString())) {
							return i.set('checked', true);
						}

						return i;
					})
				))
				.updateIn(['filter', 'serviceType'], (item) => (
					item.map((i) => {
						if (payload.serviceType.includes(i.get('value').toString())) {
							return i.set('checked', true);
						}

						return i;
					})
				))
				.updateIn(['filter', 'affectedUsers'], (item) => (
					item.map((i) => {
						if (payload.affectedUsers.includes(i.get('value').toString())) {
							return i.set('checked', true);
						}

						return i;
					})
				))
				.updateIn(['filter', 'location'], (item) => (
					item.map((i) => {
						if (payload.location.includes(i.get('value').toString())) {
							return i.set('checked', true);
						}

						return i;
					})
				));
		case getActionName(SET, SELECTS, DATA):
			return state
				.update('selectsData', (data) => data.merge(payload))
				.setIn(['filter', 'serviceType'], prepareDataForFilter(payload, 'mainServiceAffected'))
				.setIn(['filter', 'location'], payload.get('province'))
				.setIn(['filter', 'incidentOwner'], prepareDataForFilter(payload, 'incidentOwner'))
				.setIn(['filter', 'affectedUsers'], prepareDataForFilter(payload, 'affectedUsersNumbers', true))
				.setIn(['filter', 'status'], prepareDataForFilter(payload, 'incidentStatus'))
				.setIn(['filter', 'severity'], prepareDataForFilter(payload, 'incidentSeverity'))
				.setIn(['filter', 'rootCause'], prepareDataForFilter(payload, 'rootCause'));
		case getActionName(SET, FILTER, COUNTER):
			return state.set('filterCounter', payload);
		case getActionName(SET, FILTER, CHECKED):
			return state.updateIn(['filter', payload.type], (item) => (
				setSelectedValues(item, payload)
			));
		case getActionName(SET, FILTER, CHECKED, ALL):
			return state.updateIn(['filter', payload.type], (item) => (
				item.map((i) => {
					return i.set('checked', payload.value);
				})
			));
		case getActionName(UPDATE, FILTER, PARAMS):
            return state.update('filter', (filter) => (
                filter.map((item, key) => {
                    const payloadKeys = Object.keys(payload);
                    if (payloadKeys.includes(key)) {
                        return item.map((i) => {
                            const value = i.get('value').toString();

                            if (payload[key].includes(value)) {
                                return i.set('checked', true);
                            }

                            return i.set('checked', false);
                        });
                    }

                    return item;
                })));
		case getActionName(SET, SEARCH, QUERY):
			return state.set('searchQuery', payload);
		case getActionName(SET, DATE, FILTER, BEFORE):
			return state.set('timeBefore', payload);
		case getActionName(SET, DATE, FILTER, AFTER):
			return state.set('timeAfter', payload);
		case getActionName(SET, DATE, FILTER, TYPE):
			return state.set('timeType', payload);
		case getActionName(SET, SUCCESS, PASSWORD, RECOVERY, FORM):
			return state.set('successPasswordRecoveryForm', payload);
		case getActionName(SET, STATUS, PASSWORD, RESET, FORM):
			return state.set('statusPasswordResetForm', payload);
		case getActionName(SET, SORT, TYPE):
			return state.set('sortType', payload);
		case getActionName(SET, SORT, DIRECTION):
			return state.set('sortDirection', payload);
		case getActionName(SET, INCIDENTS, COUNT):
			return state.set('countsData', payload);
		default:
			return state;
	}
}