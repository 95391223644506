import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import Header from '../Header';
import Footer from '../Footer';
import StatusUpdatesList from './StatusUpdatesList/StatusUpdatesList';

import {
	getStatusUpdatesData,
	EN, DA,
} from '../../utils/ducks';

const StatusUpdatesPage = (props) => {
	const { t } = useTranslation();

	const {
		lang,
		statusUpdatesData,
		getStatusUpdatesDataConnect,
	} = props;

	useEffect(() => {
		const requestUrl = `/api/incident_event_comment_groups${window.location.search}`;

		getStatusUpdatesDataConnect(requestUrl);
	}, [lang]);

	return (
		<>
			<Header>
				<Button
					variant="contained"
					color="primary"
					size="large"
					startIcon={<AddIcon />}
					href="/create-status-update"
				>
					{t('create')}
				</Button>
			</Header>
			<StatusUpdatesList
				customersList={statusUpdatesData.get('list')}
				totalItems={statusUpdatesData.get('totalItems')}
				lang={lang}
				langList={{
					english: EN,
					danish: DA,
				}}
			/>
			<Footer />
		</>
	);
};

export default connect(
	(state) => ({
		statusUpdatesData: state.get('statusUpdatesData'),
		lang: state.get('lang'),
	}), {
		getStatusUpdatesDataConnect: getStatusUpdatesData,
	},
)(StatusUpdatesPage);